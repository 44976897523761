import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Row, Col } from 'react-bootstrap'
import { sanitizeImageURL } from '../../api/api'
import Rating from '../../components/shared/Rating'
import Currency from '../../components/shared/Currency'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import ProductImageGallery from '../../components/shared/ProductImageGallery'

class AnnouncementFullCard extends Component {

    render() {
        const { announcement, layout, onBackPress } = this.props

        let pathname = `/shop/product/${announcement.slug ? announcement.slug: announcement.id}`
        let priceComponent= null
        let currentPrice = announcement.price
        let oldPrice = null
        if (announcement.price_min > 0) {
            currentPrice = announcement.price_min
            if (announcement.price_market && announcement.price_market > currentPrice) {
                oldPrice = announcement.price_market
            }
        }

        if (announcement.price_discount > -1) {
            currentPrice = announcement.price_discount
            if (announcement.price > currentPrice) {
                oldPrice = announcement.price
            }
        }

        if (currentPrice && oldPrice) {
            priceComponent = (
                <div className="product-card__prices">
                    <span className="product-card__new-price"><Currency value={currentPrice} /></span>
                    {' '}
                    <span className="product-card__old-price"><Currency value={oldPrice} /></span>
                </div>
            );
        } else {
            if (currentPrice > 0) {
                priceComponent = (
                    <div className="product-card__prices">
                        <span className="product-card__new-price"><Currency value={currentPrice} /></span>
                    </div>
                );
            } else {
                priceComponent = (
                    <div className="product-card__prices">
                        Consultar
                    </div>
                );
            }
        }

        if (layout === 'list'){
            return (
                <Row className="justify-content-md-center"
                     style={{border: "1px solid rgba(0, 0, 0, 0.125)", marginBottom:'20px'}}>
                    <Col md={3} style={{padding:0, display: 'flex', justifyContent:'center', alignItems:'center'}}>
                        <Card style={{border:'none'}}>
                            <Link to={{
                                pathname,                    
                                state: { product: announcement }
                            }}>
                                <Card.Img src={sanitizeImageURL(announcement.thumbnail_get)} />
                            </Link>
                        </Card>
                    </Col>
                    <Col md={9} style={{padding:0}}>
                        <Card style={{border:'none'}}>
                            <Card.Body>
                                <Link to={{
                                    pathname,                    
                                    state: { product: announcement }
                                }}>
                                    <Card.Text>{announcement.name}</Card.Text>                                    
                                </Link>
                                {priceComponent}
                                <Rating value={announcement.ranking} />
                                <Card.Text>
                                    {announcement.headline ? announcement.headline.substr(0, 300) + '...' : ''}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )
        } else {
                return (
                    <Card                        
                        style={{ marginBottom: '20px' }}>
                        <div>
                            {/* <div>
                                <Button variant="link" 
                                    className="title primary_color_font"
                                    onClick={() => onBackPress()}>
                                    <i className="fa fa-times-circle" aria-hidden="true"></i>
                                </Button>
                            </div> */}
                            <Card.Body>   
                                <span className="title">{announcement.name}</span>                             
                                {/* <Card.Img variant="top"
                                    src={sanitizeImageURL(announcement.image_get)}
                                    style={{
                                        width: '100%',
                                        height: 400,
                                        objectPosition: 'center',
                                        objectFit: 'contain'
                                    }} /> */}

                                <ProductImageGallery product={announcement} />
                            </Card.Body>
                            <Card.Body style={{ paddingTop: 0, justifyContent: 'flex-start', fontSize: 16 }}>                                
                                <Card.Text style={{ color: 'black' }}>{announcement.description}</Card.Text>
                                <Card.Text className="secondary_color_font">Más Información:</Card.Text>                                
                                <div style={{ display: 'flex', flexDirection:'column' }}>
                                    <span><strong>Cantidad:</strong> {announcement.max_delivery}</span>
                                    <span><strong>Peso:</strong> {announcement.weight}</span>
                                </div>
                                <Row>                                    
                                    <Col>
                                        <span style={{marginRight:10}}><strong>Ubicación:</strong> {announcement.location}</span>                                        
                                    </Col>
                                    <Col>                                        
                                        <span><strong>Contacto:</strong> {announcement.contact}</span>
                                    </Col>
                                    <Col>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                            <span style={{ marginRight: 5 }}>Precio:</span>
                                            {priceComponent}
                                        </div>                                        
                                    </Col>
                                </Row>
                            </Card.Body>
                        </div>
                    </Card>
                )

        }

    }
}


export default connect()(AnnouncementFullCard)