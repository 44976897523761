import React from 'react'

const RoundedButton = ({style, children}) => {
    return (
        <div className="primary_color_background circle" style={style} >
            {children}
        </div>
    )
}

export default RoundedButton