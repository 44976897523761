import React, { Component } from "react";
import { Dropdown, Row, Col, Media, Button, Spinner, Badge } from "react-bootstrap";
import { connect } from "react-redux";
import { cartRemoveItem } from '../../store/cart';
import { isMobile } from "../../utils/Utils";


class TopMenuItemSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isMobile: isMobile()
        }
    }

    render() {
        const {onClick} = this.props  
        const {isMobile} = this.state      
        return (
            <div className="dropdown">
                <Button 
                    variant="link"
                    className={isMobile ?"nav-panel-icon-movil": "nav-panel-icon"}
                    onClick = {e => {                        
                        if (onClick) onClick()}
                    }
                    >
                        <i className="pe-7s-search" style={{fontSize: this.state.isMobile ? "1.5em":"2em"}}/>                        
                </Button>
            </div>           
        )
    }

}

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
    login: state.user.login
});

const mapDispatchToProps = {
    cartRemoveItem,
};


export default connect(mapStateToProps, mapDispatchToProps)(TopMenuItemSearch)