import React, { Component } from "react";
import { Dropdown, Button, Badge } from "react-bootstrap";
import PropTypes from 'prop-types';

import { isMobile } from "../../utils/Utils";
import { Fragment } from "react";

export default class TopMenuItem extends Component {

    constructor(props){
        super(props)
        this.state = {
            isMobile: isMobile()
        }
    }

    render() {
        const {children, iconComponent, indicatorText} = this.props
        const {isMobile} = this.state

        // The forwardRef is important!!
        // Dropdown needs access to the DOM node in order to position the Menu
        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <Button 
                variant="link"                
                className={isMobile ?"nav-panel-icon-movil": "nav-panel-icon"}
                ref={ref}
                onClick={e => {
                    e.preventDefault();                    
                    onClick(e);                    
                }}>              
                {/* Render custom icon here */}
                {children}
            </Button>
        ));

        //Separa los hijos en dos tipos para renderizarlos dentro o fuera del DropDown
        let inChildren = []
        let outChildren = []
        if (Array.isArray(children)) {
            inChildren = children.filter(it => !it.props || (it.props && !it.props.outOfDropDown))
            outChildren = children.filter(it => it.props && it.props.outOfDropDown)
        } else {
            if (children && children.props){
                if (children.props.outOfDropDown){
                    outChildren.push(children)
                } else {
                    inChildren.push(children)
                }
            }
        }

        // console.log('children', children)
        // console.log('inChildren', inChildren)
        
        return (
            <Fragment>

                <Dropdown {...this.props}>
                    <Dropdown.Toggle as={CustomToggle}                         
                        id="dropdown-custom-components">
                        {iconComponent}
                        {
                            indicatorText !== null && (
                                <div style={{position:'absolute', top:0, left:-2, display:'flex'}}>
                                    <Badge variant="secondary"
                                        style={{...(isMobile? {fontSize:'60%'} : {})}}
                                    >{indicatorText}</Badge>                 
                                </div>  
                            )
                        }
                    </Dropdown.Toggle>

                    {
                    // this.state.isMobile ? 
                            // <Dropdown.Menu >
                            //     {children}
                            // </Dropdown.Menu>
                            // :
                            <Dropdown.Menu alignRight>
                                {inChildren}
                            </Dropdown.Menu>
                    }

                </Dropdown>
                {
                    outChildren
                }
            </Fragment>
        )
    }
}