import React from 'react'
import { Row, Col } from 'react-bootstrap'


export const Title = (props) => {

    const title = props.title ? props.title : ''
    const hideLeftLine = true //props.hideLeftLine ? props.hideLeftLine : false
    const hideRightLine = true //props.hideRightLine ? props.hideRightLine : false
    const className = props.className ? props.className : ''
    const textStyle = props.textStyle ? props.textStyle : {}
    const titleClassName = props.className ? props.className : 'title'
    const rightComponent = props.rightComponent ? props.rightComponent : null
    return(
        <Row className={className}>
            <Col md={12} className={titleClassName}>
                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>                    
                    {
                        !hideLeftLine && (
                            <div style={{flexGrow:1, height:1, backgroundColor:'black'}}/>
                        )
                    }
                    <span className="title-product-list" style={{ ...textStyle}}>
                        {title}
                    </span>
                    {
                        !hideRightLine && (
                            <div style={{flexGrow:1, height:1, backgroundColor:'black'}}/>
                        )
                    }
                    {
                        rightComponent
                    }
                </div>
            </Col>            
        </Row>
    )

}

export default Title