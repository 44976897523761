import { CARGAR_MENU_CATEGORIAS, UPDATE_CATEGORIAS_MENU, MENU_PRINCIPAL } from "./menuActionTypes";
import { getCategoriasMenu } from "../../api/api";
import {getSubCategorias} from '../../api/api';

export const getmenucategorias = () => async (dispatch) => {

    getCategoriasMenu().then((result) => {
        
        if (result.status == 200) {
            dispatch({
                type: CARGAR_MENU_CATEGORIAS,
                payload: result.data,
            });
            //dispatch(synsSubCategories(result.data))
        } else {
            console.log(result.problem);
        }
    });
};

export const updatemenucategorias = (menu_categorias) => async (dispatch) => {
            dispatch({
                type: UPDATE_CATEGORIAS_MENU,
                payload: menu_categorias,
            });
};

export const menuprincipal = (menuprincipal) => async (dispatch) => {
    dispatch({
        type: MENU_PRINCIPAL,
        payload: menuprincipal,
    });
};

export const synsSubCategories = (categories) => async (dispatch) => {
    const updatedCategories = []
    for (let i = 0; i < categories.length; i++) {
        const category = categories[i]
        const result = await getSubCategorias(category.id)                
        if (result.status == 200){
            const subcategories = [...result.data]
            const object = {...category, subcategories}                                                    
            updatedCategories.push(object)            
        } else {
            updatedCategories.push(category)            
        }               
    }        
    
    dispatch({
        type: CARGAR_MENU_CATEGORIAS,
        payload: updatedCategories,
    });
};
