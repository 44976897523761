// types
import {
    SET_LOADING,
    SET_ADDRESSES,
    SET_ERROR
} from './types'

const defaultState = {
    items: [],
    loading: false,
    error: null
}

const reducer = (state = defaultState, action) => {
    switch(action.type){
        case SET_ADDRESSES:
            return {
                ...state,
                items: action.payload
            }

        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        
        case SET_ERROR:
            return {
                ...state,
                error: action.payload
            }

        default: return state
    }
}

export default reducer