// react
import React, { Component, Fragment } from 'react';

// third-party
import { MetaTags } from 'react-meta-tags';
import { connect } from 'react-redux';
import { Spinner, Row, Col, Container, Image, Pagination } from 'react-bootstrap';

import { sanitizeImageURL, getGenericList } from '../../api/api';
import Layout from '../../layouts/Layout';
import FiltersView from './FiltersView';
import ListView from './ListView';
import SearchGeneral from '../../components/header/SearchGeneral';
import AnnouncementFullCard from '../trade/AnnouncementFullCard';
import {getFullTreePagesDesign, renderDisegnerSections} from '../../components/shared/DesignerUtils';
import { isMobile } from '../../utils/Utils';
import { toast } from 'react-toastify';


class SectionOfficialStore extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filters: [],

            pages: [],            
            currentPage: 0,
            

            designer: null,
            layout: 'grid-6',            
        }
    }

    componentDidMount() {
        this.fetchMasters(0)
        this.getSearchParam()
    }


    fetchMasters(page, next) {
        this.setPageLoading(page, true)
        const {filters} = this.state
        const next_url = next ? next : this.props.section.api_url
        let queryParamsAttr = this.filtersToQueryparams(filters)   
        getGenericList(next_url, queryParamsAttr).then(result => {
            if (result.status === 200) {
                this.setPageResult(page, result.data)
            }
        })
    }

    filtersToQueryparams(filters) {
        let queryParams = filters.map(filter => {
            return filter.values >= 0 ? `activity_ids=${filter.values}&` : ''
        })
        let search = this.getSearchParam()
        if (search)
            queryParams.push(`term=${search}&`)
        return queryParams.join('')
    }

    getSearchParam() {
        const params = new URLSearchParams(window.location.search)
        let query_param = params.get('search')
        return query_param
    }

    setPageLoading(page, band) {
        const {pages} = this.state        
        pages[page] = {
            loading: band
        }
        this.setState({
            pages
        })  
    }

    setPageResult(page, result) {
        const {pages: pages} = this.state        
        pages[page] = {
            ...result,
            loading: false
        }
        this.setState({
            pages
        })  
    }

    renderNavigator() {
        const { pages, currentPage} = this.state 

        const items = pages.map((page, index) => {
            if (index === currentPage) {
                return (<Pagination.Item key={index} active>{index + 1}</Pagination.Item>)
            } else {
                return (<Pagination.Item onClick={(e) => this.onSetPage(index)}>{index + 1}</Pagination.Item>)
            }
        })
        
        return (            
            <Pagination>
                <Pagination.First onClick={(e) => this.onSetPage(0)}/>
                <Pagination.Prev onClick={(e) => this.onPreviusClick()}/>
                {items}
                <Pagination.Next onClick={(e) => this.onNextClick()}/>                
            </Pagination>
        )       
    }

    onSetPage(pageNumber) {        
        this.setState({
            currentPage: pageNumber
        })
    }

    onPreviusClick() {
        const { pages, currentPage } = this.state
        if (currentPage > 0) {
            this.setState({
                currentPage: currentPage - 1
            })            
        }
    }

    onNextClick() {
        const { pages, currentPage } = this.state        
        const nextPage = pages[currentPage + 1]      
        if (nextPage) {
            this.setState({
                currentPage: currentPage + 1
            })
        } else {
            const page = pages[currentPage]        
            if (page && page.next) {            
                this.setState({
                    currentPage: currentPage + 1
                }, () => this.fetchMasters(currentPage + 1, page.next))
            }
        }
    }

    onFiltersChange(values, categoryTag) {
        console.log('onFiltersChange', values, categoryTag)
        //Desselect announcement
        this.setState({
            selectedItem: null
        })

        const {filters} = this.state
        let currentFilter = filters.find( it => it.categoryTag.id === categoryTag.id)
        if (currentFilter) {
            currentFilter.values = values
        } else {
            filters.push({values, categoryTag})
        }
        this.setState({filters}, () => this.fetchMasters(0))
    }

    onSearchPress () {
        console.log('onSearchPress')
        const {category_id, ttype_id, max_price, max_weight} = this.state
        if (category_id || ttype_id || max_price > 0 || max_weight > 0) {
            let queryParams = ''
            if (category_id) {
                queryParams = `category_id=${category_id}`
            }
            if (ttype_id) {
                queryParams += queryParams.length > 1 ? `&ttype_ids=${ttype_id}` : `ttype_ids=${ttype_id}`
            }
            if (max_price > 0) {
                queryParams += queryParams.length > 1 ? `&` : ``
                queryParams += `price_min=0&price_max=${max_price}`
            }
            if (max_weight > 0) {
                queryParams += queryParams.length > 1 ? `&` : ``
                queryParams += `weight_min=0&weight_max=${max_weight}`
            }
            this.fetchProducts(0, null, queryParams)
        } else {
            toast.info('Elija una opción')
        }
    }

    onItemPress(item) {
        console.log('onAnnouncementPress', item)
        this.setState({selectedItem: item})
    }

    onSubmit(e, query, props) {
        props.history.push({
            pathname: '/directorio',
            search: `?search=${query}`
        });
    }

    render () {
        const { pages, currentPage, category} = this.state
        const { selectedAnnouncement } = this.state
        const { section, siteBar } = this.props
        const show_sitebar = !siteBar ? siteBar : true
        const page = pages[currentPage]
        let query = this.getSearchParam()
        let content = (
            <div className='content-center' style={{marginTop:30}}>
                <Spinner animation="border" variant='secondary' />
            </div>
        )

        if (section) {
            content = (
                <Fragment>
                    <Row style={{marginTop:30}}>
                        
                        { 
                        show_sitebar && 
                        <Col lg={{span: 3, order:1}} xs={{span: 12, order:2}} >
                            <FiltersView 
                                key={category ? category.id : '0'} 
                                category={category} 
                                onFiltersChange={(values, variant) => this.onFiltersChange(values,variant)}/>
                        </Col>
                        }
                        <Col lg={{span: show_sitebar ? 9:12, order:2}} 
                            xs={{span: 12, order:1}}
                            style={{borderLeft: "2px solid rgb(214,214,214)"}}
                            >
                            {/* <TopBar getLayout={(layout) => this.getLayout(layout)} /> */}
                            {/* {navigator} */}    

                            {
                                selectedAnnouncement ? (
                                    <AnnouncementFullCard 
                                        announcement={selectedAnnouncement} 
                                        onBackPress={() => this.setState({selectedAnnouncement: null})}
                                        />
                                )
                                :
                                <Fragment>
                                    <Row style={{justifyContent:'center'}}>
                                        <SearchGeneral 
                                            context='body' 
                                            onSubmit={(e, q, p) => this.onSubmit(e, q, p)}
                                            api_url='/commons/api/providers/'
                                            search_term='term'
                                            detail_url='/directorio/'
                                            search_classes='providers col-md-8 col-sm-10' />
                                    </Row>

                                    {
                                    query && (
                                        <Row className="justify-content-md-center">
                                            <h3>Texto buscado: <strong>{query}</strong></h3> 
                                        </Row>
                                    )
                                    }

                                    <div >
                                        <ListView 
                                            page={page} 
                                            layout={this.state.layout} 
                                            onItemPress={(item) => this.onItemPress(item)}
                                            imageRounded={false}
                                            />
                                    </div>                                

                                    <Row style={{justifyContent:'center'}}>
                                        {/* {navigator} */}
                                        {
                                            page && !page.loading  && page.results && page.results.length > 0 && (
                                                this.renderNavigator()
                                            )
                                        }
                                    </Row>
                                </Fragment>
                            }

                        </Col>
                    </Row>
                </Fragment>
            )
        }

        return (
            <Fragment>
                    <React.Fragment>                                   
                        <Container fluid>                       
                            {
                                content
                            }
                            <Row>
                                <Col md={12} style={{padding:"5px 0px"}}></Col>
                            </Row>
                        </Container>
                    </React.Fragment>
                </Fragment>
        )

    }
}

const mapStateToProps = (state) => ({
    categorias: state.shop.categorias,
    general: state.general.general,
    search: state.search
})

export default connect(mapStateToProps, null)(SectionOfficialStore);
