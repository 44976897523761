import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Row, Col } from 'react-bootstrap'
import { sanitizeImageURL } from '../../../api/api'
import Rating from '../../../components/shared/Rating'
import Currency from '../../../components/shared/Currency'
import { Link } from 'react-router-dom'
import { isMobile } from '../../../utils/Utils'

class ProductInline extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isMobile: isMobile()
        }
    }

    render() {
        const { product, layout } = this.props
        const { isMobile } = this.state

        let pathname = `/shop/product/${product.slug ? product.slug: product.id}`
        let priceComponent= null
        let currentPrice = product.price
        let oldPrice = null
        if (product.price_min > 0) {
            currentPrice = product.price_min
            if (product.price_market && product.price_market > currentPrice) {
                oldPrice = product.price_market
            }
        }

        if (product.price_discount > -1) {
            currentPrice = product.price_discount
            if (product.price > currentPrice) {
                oldPrice = product.price
            }
        }

        if (currentPrice && oldPrice) {
            priceComponent = (
                <div className="product-card__prices">
                    <span className="product-card__new-price"><Currency value={currentPrice} /></span>
                    {' '}
                    <span className="product-card__old-price"><Currency value={oldPrice} /></span>
                </div>
            );
        } else {
            if (currentPrice > 0) {
                priceComponent = (
                    <div className="product-card__prices">
                        <span className="product-card__new-price"><Currency value={currentPrice} /></span>
                    </div>
                );
            } else {
                priceComponent = (
                    <div className="product-card__prices">
                        Consultar
                    </div>
                );
            }
        }

        return (
            <Row className="justify-content-md-center"
                 style={{border: "1px solid rgba(0, 0, 0, 0.125)", marginBottom:'20px'}}>
                <Col md={3} style={{padding:0, display: 'flex', justifyContent:'center', alignItems:'center'}}>
                    <Card style={{border:'none'}}>
                        <Link to={{
                            pathname,                    
                            state: { product: product }
                        }}>
                            <Card.Img src={sanitizeImageURL(product.thumbnail_get)} />
                        </Link>
                    </Card>
                </Col>
                <Col md={9} style={{padding:0}}>
                    <Card style={{border:'none'}}>
                        <Card.Body>
                            <Link to={{
                                pathname,                    
                                state: { product: product }
                            }}>
                                <Card.Text>{product.name.substr(0, 17) + '...'}</Card.Text>                                    
                            </Link>
                            {priceComponent}
                            <Rating value={product.ranking} />
                            <Card.Text>
                                {product.headline ? product.headline.substr(0, 300) + '...' : ''}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )        

    }
}


export default connect()(ProductInline)