// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';

function Rating(props) {
    const { value } = props;

    const stars = [1, 2, 3, 4, 5].map((rating) => {
        const rootClasses = value >= rating ? "fa fa-star-o" : "fa fa-star-o active"
        return <i key={rating} className={rootClasses} style={{color: (value >= rating) ? "#ffa900":'' }}></i>
    });

    return (
        <div className="rating">
            <div className="rating__body">
                {stars}
            </div>
        </div>
    );
}

Rating.propTypes = {
    /**
     * rating value
     */
    value: PropTypes.number,
};
Rating.defaultProps = {
    value: 0,
};

export default Rating;
