import React from 'react';
import { Layout, Row, Col, Button } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import Logo from '../img/logo.png';
import styles from '../kenworth.module.scss';

const { Header } = Layout;

export const HeaderKenworth = () => {
  return (
    <>
      <Header className={styles.Header}>
        <Row>
          <Col span={4} offset={2}>
            <img className={styles.imgLogo} src={Logo} alt='logo' />
          </Col>
          <Col span={4} offset={6}>
            <p className={styles.backtitle}>Regresar a KENWORTH DE JALISCO</p>
            <p className={styles.backtitlemovil}>inicio</p>
          </Col>

          <Col span={8} align='middle'>
            <Button icon={<LeftOutlined />} size='small' /> <Button icon={<RightOutlined />} size='small' />
          </Col>
        </Row>
      </Header>

    </>
  );
};
