import React, { Component } from 'react'
import { Modal, Button, Form, Row, Spinner, Col } from 'react-bootstrap';
import FacebookLogin from 'react-facebook-login'

class LoginModal extends Component {

    render() {
        const { show, 
            handleClose, 
            facebookAppId,
            login_email,
            login_password,
            processing,
            onSetState,
            onLoginPress,
            onRegisterPress,
            onForgottenPassword,
            responseFacebook
         } = this.props            
        return (
            <Modal 
                size="lg"
                dialogClassName="login-modal" 
                show={show} 
                onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="secondary_color_background" style={{padding:10}} >
                        <Modal.Title className='text-center'>INICIA SESIÓN</Modal.Title>
                    </div>
                    <Row className="justify-content-md-center">
                        <Col md={10} style={{padding:30}}>
                            <Form onSubmit={(e) => onLoginPress(e)}>
                                <Form.Group controlId="formBasicEmail">                                    
                                    <Form.Control
                                        type="email"
                                        placeholder="Correo electrónico"
                                        value={login_email}
                                        onChange={(e) => onSetState({ login_email: e.target.value })}
                                    />
                                </Form.Group>
        
                                <Form.Group controlId="formBasicPassword">                                    
                                    <Form.Control
                                        type="password"
                                        placeholder="Contraseña"
                                        value={login_password}
                                        onChange={(e) => onSetState({ login_password: e.target.value })}
                                    />
                                    {
                                        processing ?
                                            <Button variant='link'>
                                                <Spinner animation="border" size="sm" role="status" />
                                            </Button>
                                            :
                                            null
                                    }
                                </Form.Group>

                                <Row style={{ justifyContent: 'space-evenly' }}>                                    
                                    <Button variant="primary" type="submit" style={{padding:'0.375rem 2rem'}}>
                                        ENTRAR
                                    </Button>
                                    {
                                        facebookAppId && (
                                            <FacebookLogin
                                                appId={facebookAppId}
                                                autoLoad={false}
                                                fields="name,email,picture"
                                                scope="public_profile,user_friends"
                                                size="small"
                                                textButton="  Login con Facebook"
                                                callback={(response) => responseFacebook(response)}
                                                cssclassaName="btn btn-login-facebook"
                                                icon="fa-facebook"
                                            />
                                        )
                                    }
                                </Row>        
                            </Form>
                        </Col>    
                    </Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-md-center" >
                    <div style={{flexGrow:1, margin:'0px 20px', display:'flex', alignItems:'center', justifyContent:'space-around', borderTop:'5px solid black'}}>
                        <Button
                            variant='link'
                            onClick={(e) => onForgottenPassword(e)}>
                            Olvidé mi contraseña
                        </Button>
                        <Button variant="link" onClick={() => onRegisterPress()}>
                            Registrate
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        );    
    }
}


export default LoginModal;