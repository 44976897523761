import React from 'react'
import { Fragment } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { isMobile } from '../../utils/Utils'
import { renderSection } from '../shared/DesignerUtils'


const SectionTabs = ({section}) => {

    let key = `${(new Date().getTime())}`
    let sectionClassName = `seccion-${key}`
    let tabs = []    
    if (section) {
        sectionClassName = `seccion-${section.id}`

        if (section.sections) {
            let tabsSubsections = section.sections.filter(it => it.otype.slug === "tab")
            tabs = tabsSubsections.map(it => (
                <Tab eventKey={it.id} title={it.title}>
                    {
                        renderSection({...it, title: null})
                    }
                </Tab>
            ))
        }
    }


    return (
        <Fragment>
            <Helmet>
                {section && (
                    <style type="text/css">{`

                    .${sectionClassName} {
                        ${section.html_attrs}
                        ${isMobile() ? section.html_attrs_mobile : ''}
                    }

                `}</style>
                )}
            </Helmet>
            <div className={`${sectionClassName} container-section-tabs`}>
                <Tabs id="uncontrolled-tab-section"
                    className="section-tabs">
                    {tabs}
                </Tabs>
            </div>

        </Fragment>
    )
}

export default SectionTabs;