import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Col, Nav, Navbar } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { getCategoriasMenu, getSubCategorias } from '../../api/api'
import { isMobile } from '../../utils/Utils'

import Search from '../header/Search'
import DropdownMenuCategory from './DropdownMenuCategory'
import Section from "./Section"

const SectionCategoryTreeMenu = ({section}) => {

    const [categories, setCategories] = useState([])

    useEffect(() => {
        getCategoriasMenu().then(result => {
            if (result.status === 200) {
                const promises = []
                result.data.forEach(cat => 
                    promises.push(getSubCategorias(cat.id).then(result => {
                        if (result.status === 200) {
                            cat.subCats = result.data
                            result.data.forEach(subCat =>
                                promises.push(getSubCategorias(subCat.id).then(result => {
                                    if (result.status === 200) {
                                        subCat.subCats = result.data
                                    }
                                })
                            ))
                        }
                    }))
                )
                Promise.all(promises).then(() => {
                    setCategories(result.data)
                })
            }
        }).catch(error => console.warn('Error fetchCategories', error))

    }, [])

    const catLinks = []
    categories.forEach((cat, index) =>
        catLinks.push(
                <DropdownMenuCategory
                    link={`/shop/category/${cat.slug}/`}
                    link_text={cat.name}
                    sub_cats={cat.subCats}
                />
        ) 
    )

    let key=`${(new Date().getTime())}`
    let sectionClassName = `seccion-${key}`
    if (section) {
        sectionClassName = `seccion-${section.id}`
    }

    return (
        <Fragment>
            <Helmet>
                {section && (
                    <style type="text/css">{`

                    .${sectionClassName} {
                        ${section.html_attrs}
                        ${isMobile() ? section.html_attrs_mobile: ''}
                    }

                `}</style>
                )}
            </Helmet>
            
            <Navbar
                bg="navbar"
                fixed="top"
                sticky="top"
                className="nav-panel col header-links d-none d-lg-block bg-secondary"
                expand="lg"
                style={{ fontSize: 20 }}>
                <Nav className="mr-auto header-links justify-content-center">
                    {
                        catLinks
                    }
                </Nav>
            </Navbar>        
        </Fragment> 
    )
}

export default SectionCategoryTreeMenu