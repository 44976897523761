import { CARGAR_MASTER, CARGANDO, ERROR, CARGAR_MASTER_CONFIGS, CARGAR_MARKETPLACE_CONFIGS } from './masterActionTypes';

const INITIAL_STATE = {
	master: null,
	master_configs: null,
	marketplace_configs: null,
	cargando: false,
	error: ''
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CARGAR_MASTER:
			return { 
				...state,
				master: action.payload,
				cargando: false,
				error: ''
			};
		case CARGAR_MASTER_CONFIGS:
			return { 
				...state,
				master_configs: action.payload,
				cargando: false,
				error: ''
			};
		case CARGAR_MARKETPLACE_CONFIGS:
			return { 
				...state,
				marketplace_configs: action.payload,
				cargando: false,
				error: ''
			};

		case CARGANDO:
			return { ...state, cargando: true };

		case ERROR:
			return { ...state, error: action.payload, cargando: false };

		default: return state;
	};
};