import React, { Component, Fragment } from "react";
import { getFeatureProducts, getProducts } from "../../api/api";
import SectionProducts from "./SectionProducts";

export default class SectionIsFeatureProducts extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pages: []
        }
    }

    componentDidMount() {
        getProducts(null, 'is_featured=1').then(result => {
            if(result.status === 200) {
                let pages = []
                pages[0] = {
                    ...result.data,
                    products: result.data.results.slice(0,12)
                }
                this.setState({
                    pages                    
                })
            }
        })
    }

    render(){
        const {section} = this.props
        const {pages} = this.state                                       
        return (
            <SectionProducts section={section} pages={pages}/>
        )
    }
}