import React, { Component } from "react";
import { Container, Row, Card, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getColumnClasses } from "../../utils/Utils";

export default class CardThumbnail extends Component {

    render(){        
        const {element} = this.props
        let renderElements = null
        if(element) {
            renderElements = (
                <Col key={element.id}                       
                    className={`card-thumbnail card-thumbnail-${element.id} ${getColumnClasses(element)}`}                    >
                    <Card className="text-center" style={{ border: 'none' }}>
                        <Card.Body style={{ padding: 0 }}>
                            {
                                element.image && (
                                    <Card.Img variant="top" src={element.image} />
                                )
                            }
                            <Card.Title className="mt-2" style={{fontWeight:'300'}}>{element.name}</Card.Title>
                            <div className='card-text-styled'>
                                <div dangerouslySetInnerHTML={{__html:element.description}} />
                            </div>
                            {
                                element.link && (
                                    <div>     
                                        
                                        <a href={element.link}>                       
                                        <Button>
                                            {element.link_text}
                                        </Button>
                                        </a>
                                    </div>
                                )
                            }
                        </Card.Body>
                    </Card>
                </Col>
            )
        }
        return (renderElements)
    }
}