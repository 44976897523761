import { CARGAR_GENERAL, CARGANDO, ERROR } from './generalActionTypes';

const INITIAL_STATE = {
	general: null,
	cargando: false,
	error: ''
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CARGAR_GENERAL:
			return { 
				...state,
				general: action.payload,				
				cargando: false,
				error: ''
			};

		case CARGANDO:
			return { ...state, cargando: true };

		case ERROR:
			return { ...state, error: action.payload, cargando: false };

		default: return state;
	};
};