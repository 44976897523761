export const version = "0.1.6";


export const PAY_PLATFORMS = {
    MercadoPago: 'mercadopago',
    Conekta: 'conekta',
    OpenPay: 'openpay'
}

export const PAYMENT_TYPES = {
    TARJETA_CREDITO_DEBITO: 'credit-card',
    EFECTIVO: 'cash',
    TRANSFERENCIA: 'spei',
    OXXO: 'oxxo'
}