export const GET_USER="get_user";
export const CARGANDO="cargando_user";
export const ERROR="error_user";
export const RESET_USER="reset_user";
export const DO_REGISTER="do_register";
export const DO_LOGIN="do_login";
export const DO_LOGOUT="do_logout";
export const UPDATE_USER="update_user";
export const GET_MASTER_USER="get_master_user";


