import React, { useState } from 'react'
import { Fragment } from 'react'
import { Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { getElementStyle, isMobile } from '../../utils/Utils'
import SectionMenuItem from './SectionMenuItem'

const FloatingSection = ({ section }) => {
    const [onlyIcons, setOnlyIcons] = useState(false)

    let renderElements = []    
    let key = `${(new Date().getTime())}`
    let elementsStyles = ""
    let sectionClassName = `seccion-${key}`
    if (section) {
        sectionClassName = `seccion-${section.id}`
        if (section.elements) {
            let activeElements = section.elements.filter(it => !it.deleted)
            renderElements = activeElements.map((element, index) => {
                switch (element.otype.slug) {                    
                    case "menu-item":
                        elementsStyles = elementsStyles.concat(getElementStyle(element, 'menuItem', sectionClassName))
                        return (
                            <SectionMenuItem 
                                key={element.id} 
                                element={element} 
                                onlyIcons= {onlyIcons}
                                customMenuItemclassaName="floating-widget-menu-item"
                                customMenuItemIconclassaName="floating-widget-menu-item-icon"
                                />)                    
                    case 'button':
                        elementsStyles = elementsStyles.concat(getElementStyle(element, 'button-container', sectionClassName))
                        return (
                            <Button as={Link} to={element.link}
                                className={`button-container button-container-${element.id}`}
                            >
                                {element.link_text}
                            </Button>
                        )                    
                    default:
                        console.log('Elemento no reconocido:', element)
                        return null;
                }
            })
        }
    }

    const flipComponent = (
        <div style={{position:'absolute', bottom: -30, right:0}}>
             <Button 
                className="justify-content-center"
                style={{color:'white', borderRadius:2, width:30, height:30, padding:0}}
                onClick={() => setOnlyIcons(!onlyIcons)}>
                <i className={onlyIcons ? "pe-7s-angle-left-circle": "pe-7s-angle-right-circle" }
                    style={{fontSize:30}}/>    
            </Button>
        </div>
    ) 

    return (
        <Fragment>
            <Helmet>
                {section && (
                    <style type="text/css">{`

                        .${sectionClassName} {
                            ${section.html_attrs}
                            ${isMobile() ? section.html_attrs_mobile : ''}
                        }

                        ${elementsStyles}

                    `}</style>
                )}
            </Helmet>

            <div className={`${sectionClassName} floating-section primary_color_background color_primary_letter_important`}>
                {flipComponent}
                {renderElements}                
            </div>
        </Fragment>
    )
}

export default FloatingSection;