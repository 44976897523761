import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { multilanguage } from "redux-multilanguage";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

class Logo extends Component {
    
    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

    render(){
        const {general, height, width, fontSize} = this.props
        const logo = general ? general.logo: null
        const brand = general ? general.page_title: ''
        const custom_height = height ? height : '60px'
        const custom_width = width ? width: 'auto'
        const custom_fontSize = fontSize ? fontSize : 40
        const custom_brand = brand ? brand : 'Your Brand Here!'

        return (
            <Fragment>
            {
                logo ?
                    <Navbar.Brand as={Link} to="/">
                        <img
                            alt="brand"
                            src={logo}
                            style={{ height: custom_height, width: custom_width }}
                            className="d-inline-block align-top"
                        />
                    </Navbar.Brand>
                    :
                    <Navbar.Brand as={Link} to="/" style={{ fontSize: custom_fontSize }}>{custom_brand}</Navbar.Brand>
            }
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        general: state.general.general,
    }
}
export default connect(mapStateToProps)(multilanguage(Logo))