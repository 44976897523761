import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Button, Col, Nav, Navbar, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import Swiper from "react-id-swiper";

import { getCategoriasMenu, getSubCategorias } from '../../api/api'
import { isMobile } from '../../utils/Utils'
import FeatureIcons from './FeatureIcon'
import Title from '../../components/shared/Title'
import { Link } from 'react-router-dom';
import CardsCarousel from '../shared/CardsCarousel';

const SectionCategoryCarousel = ({section}) => {
    const [categories, setCategories] = useState(null)
    const [swiper, updateSwiper] = useState(null);

    useEffect(() => {
        getCategoriasMenu().then(result => {
            console.log('SectionCategoryCarousel', result)
            if (result.status === 200) {                
                setCategories(result.data)
            }
        }).catch(error => console.warn('Error fetchCategories', error))

    }, [])

    const catLinks = []
    if (categories) {
        categories.forEach((cat, index) =>
            catLinks.push(
                <Link to={`/shop/category/${cat.slug}/`}>
                    <div className="category-card">
                        <FeatureIcons 
                            feature={cat}
                            titleClassName="category-title"
                            />
                    </div>
                </Link>
            ) 
        )
    }

    let key=`${(new Date().getTime())}`
    let sectionClassName = `seccion-${key}`
    if (section) {
        sectionClassName = `seccion-${section.id}`
    }

    return (
        <Fragment>
            <Helmet>
                {section && (
                    <style type="text/css">{`

                    .${sectionClassName} {
                        ${section.html_attrs}
                        ${isMobile() ? section.html_attrs_mobile: ''}
                    }

                `}</style>
                )}
            </Helmet>
            <CardsCarousel 
                cards={catLinks}
                title="Categorias"
                />
                   
        </Fragment> 
    )
}

export default SectionCategoryCarousel