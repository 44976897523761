import moment from 'moment'
require('moment/locale/es');

export const PRODUCT_ACTIVITIES = {
    VIEW: 'view',
    SHARE: 'share',
    CONTACT_WHASTSAPP:'contact-whatsapp',
    CONTACT_PHONE:'contact-phone',
    CONTACT_WEB:'contact-web',
    CONTACT_MAP:'contact-map',
    CONTACT_FACEBOOK:'contact-facebook',
    CONTACT_INSTAGRAM:'contact-instagram',
    CONTACT_TWITTER:'contact-twitter',
    CONTACT_LINKEDIN:'contact-linkedin'
}


export function formattedMessageActivity(activity) {    
    let formattedMessage = ''
    if (activity) {
        let formattedMessage = `${moment(activity.created_at).fromNow()} ${activity.client ? activity.client.name : 'un usuario'}`
        switch (activity.otype.slug) {
            case 'view':
                formattedMessage = `${formattedMessage} vio este producto.`
                break;
            case 'bargain':
                formattedMessage = `${formattedMessage} obtendrá este producto GRATIS.`
                break;
            case 'bargain-registry':
                formattedMessage = `${formattedMessage} ayudó a un amigo a obtener este producto GRATIS.`
                break;
            case 'comment':
                formattedMessage = `${formattedMessage} calificó este producto.`
                break;
            case 'share':
                formattedMessage = `${formattedMessage} compartió este producto.`
                break;
            case 'shared-order':
                formattedMessage = `${formattedMessage} hizo una Compra Compartida.`
                break;
            case 'buy':
                formattedMessage = `${formattedMessage} Compró este producto.`
                break;
            case 'order-completed':
                formattedMessage = `${formattedMessage} Recibió este producto.`
                break;
            default:
                formattedMessage = `${formattedMessage} Mostró Interés en este producto.`
                break;
        }
        if (formattedMessage.length > 0) {
            formattedMessage = formattedMessage.charAt(0).toUpperCase() + formattedMessage.slice(1)
        }
        return formattedMessage
    }
    return formattedMessage
  }


export const getPrice = (product) => {
    let price = product ? product.price_get : 0
    if (product.price_discount >= 0 && product.price_discount < price) {
        price = product.price_discount
    }        
    return price
}

/**
 * price_market > price_get regreso price_market
 * else  si price > price_get regresa price  
 * @param {*} product 
 */
export const getPriceMayor = (product) => {
    let price = 0
    if (product.price_market > product.price_get) {
        price = product.price_market
    } else if (product.price > product.price_get) {
        price = product.price
    }        
    return price
}

export const getDiscountInPercent = (product) => {
    const {price, price_discount} = product
    let priceDiscount = Number(price_discount)
    let priceNumber = Number(price)		
    if (priceDiscount
        && !Number.isNaN(priceDiscount)
        && priceDiscount > 0 
        ) {				
            let discountPercent = ((priceDiscount / priceNumber) * 100) - 100
        return Math.round(discountPercent * 100) / 100
    } 
    return null
}
