import React from 'react'
import Logo from '../header/Logo'
import Section from "./Section"

const SectionBrandIcon = ({section}) => {

    return (
        <Section key={section.id} section={section}> 
            <Logo />
        </Section>
    )
}

export default SectionBrandIcon