import React, { Component, Fragment } from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import { connect } from "react-redux";
import moment from 'moment'
import { multilanguage } from "redux-multilanguage";
import FooterContacts from "./FooterContacts";
import FooterLinks from "./FooterLinks";
import { getSocialNetworks } from "../../api/api";
import {version} from '../../configs'
import Section from "../features/Section";
import Slides from "../../components/slides";
import FooterSubscriber from "./FooterSubscriber";
import { renderDisegnerSections } from "../shared/DesignerUtils";
import { isMobile } from "../../utils/Utils";

class Footer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isMobile: isMobile()
        }
    }

    componentDidMount() {        
        this.fetchSocialNetworks()
    }


    fetchSocialNetworks() {
        getSocialNetworks().then(result => {
            if (result.status === 200) {
                this.setState({
                    socialNetworks: result.data
                })
            }
        }).catch(error => console.error(error))
    }

    renderDisegnerSections() {
        const {designer} = this.props
        if (designer) {
            return renderDisegnerSections(designer)            
        }
    }

    render(){                
        const {socialNetworks, isMobile} = this.state
        const {strings, general, designer} = this.props
        let logo = null
        let brand = ''
        if (general) {
            logo = general.logo
            brand = general.page_title
        }
        return (
            <footer className={`containerFooter ${designer.css_class}`}>
                <Container fluid >
                    <Row >
                    {
                        this.renderDisegnerSections()
                    }
                    </Row>
                </Container>
                <Container fluid style={{marginTop:5}}>
                    {
                        isMobile ? 
                            <Fragment>
                                {/*<Row>
                                    <Col md={12} className='mt-5 mb-5'
                                            style={{ display: 'flex', alignItems: 'flex-end', justifyContent: isMobile ? 'center' : 'flex-start' }}>
                                            <div>
                                                <Image src="/assets/icons/VISA.png" style={{ width: 'auto', height: 40, marginRight: 5 }} />
                                                <Image src="/assets/icons/MASTERCARD.png" style={{ width: 'auto', height: 40, marginRight: 5 }} />
                                                <Image src="/assets/icons/AMERICAN-EXPRESS.png" style={{ width: 'auto', height: 40, marginRight: 5 }} />
                                                <Image src="/assets/icons/OXXO.png" style={{ width: 'auto', height: 40, marginRight: 5 }} />
                                            </div>
                                        </Col>
                                </Row>*/}
                                <Row noGutters                                     
                                    style={{alignItems: 'center' }}>
                                    <Col
                                        md={{ span: 4, order: 1 }}
                                        xs={{ span: 12, order: 2 }}
                                        className='mb-5'
                                        style={{ display: 'flex', alignItems: 'flex-end', justifyContent: isMobile ? 'center' : 'flex-start' }}>
                                        <div style={{ display: 'flex' }}>
                                            {
                                                this.renderSocialNetworks()                                                 
                                            }
                                        </div>
                                    </Col>
                                    <Col
                                        md={{ span: 4, order: 2 }}
                                        xs={{ span: 12, order: 1 }}
                                        className='mb-5'
                                        style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                                        {
                                            logo ?
                                                <Image
                                                    alt="brand"
                                                    src={logo}
                                                    style={{ height: 25, width: 'auto' }}
                                                    className="d-inline-block align-top"
                                                />
                                                : brand ?
                                                    <h2>{brand.toUpperCase()}</h2>
                                                    : null
                                        }
                                    </Col>
                                    <Col
                                        md={{ span: 4, order: 3 }}
                                        xs={{ span: 12, order: 3 }}
                                        
                                        style={{ fontWeight: '300', display: 'flex', alignItems: 'flex-end', justifyContent: isMobile ? 'center' : 'flex-end' }}>
                                        <span>{`${moment().format('YYYY')} ${brand}©`}</span>
                                    </Col>
                                </Row>
                            </Fragment>
                            :
                            <Fragment>
                                <Row style={{ marginTop: 5 }}>
                                    <Col md={9}></Col>
                                    <Col md={3}>
                                        <div style={{ display: 'flex' }}>
                                            {
                                                this.renderSocialNetworks()                                                 
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                {/*<Row noGutters style={{ marginTop: 90, alignItems: 'center' }}>
                                    <Col md={4} className='mb-3'
                                        style={{ display: 'flex', alignItems: 'flex-end', justifyContent: isMobile ? 'center' : 'flex-start' }}>
                                        <div>
                                            <Image src="/assets/icons/VISA.png" style={{ width: 'auto', height: 40, marginRight: 5 }} />
                                            <Image src="/assets/icons/MASTERCARD.png" style={{ width: 'auto', height: 40, marginRight: 5 }} />
                                            <Image src="/assets/icons/AMERICAN-EXPRESS.png" style={{ width: 'auto', height: 40, marginRight: 5 }} />
                                            <Image src="/assets/icons/OXXO.png" style={{ width: 'auto', height: 40, marginRight: 5 }} />
                                        </div>
                                    </Col>
                                    <Col md={4} className='mb-3' style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                                        {
                                            logo ?
                                                <Image
                                                    alt="brand"
                                                    src={logo}
                                                    style={{ height: 25, width: 'auto' }}
                                                    className="d-inline-block align-top"
                                                />
                                                : brand ?
                                                    <h2>{brand.toUpperCase()}</h2>
                                                    : null
                                        }
                                    </Col>
                                    <Col md={4} style={{ fontWeight: '300', display: 'flex', alignItems: 'flex-end', justifyContent: isMobile ? 'center' : 'flex-end' }}>
                                        <span>{`${moment().format('YYYY')} ${brand} ©`}</span>
                                    </Col>
                                    </Row>*/}
                            </Fragment>
                    }
                    
                    <Row noGutters style={{marginTop:5}}>
                       <Col md={4} >                           
                        </Col> 
                       <Col md={4}>                           
                        </Col> 
                        <Col md={4} style={{fontWeight:'300', display:'flex', alignItems:'flex-end', justifyContent:'flex-end'}}>
                           <p style={{textAlign:'right',}}><span>{`${moment().format('YYYY')} ${brand} © `} 
                           Desarrollado Por: <a href="https://www.herosuite.io">HeroSuite</a> <br />
                           {` v.${version}`}</span></p>
                        </Col> 
                    </Row>
                </Container>
            </footer>
        )
    }


    renderSocialNetworks() {
        const {socialNetworks} = this.state
        const notfound = /product_image_not_found/
        if (socialNetworks) {
            return socialNetworks.map(social => {
                return (
                    <a key={social.id} href={social.url}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 10, paddingLeft:10 }}>
                        {
                            social.logo && !notfound.test(social.logo) ?
                                <Image src={social.logo} style={{ width: 25, height: 'auto' }} />
                                :
                                this.renderSocialNetworkByName(social)
                                
                        }
                    </a>
                )
            })            
        }
    }

    renderSocialNetworkByName(social) {
        let component = (<span>{social.name}</span>)
        switch (social.name.toLowerCase()) {
            case 'facebook':
                component = (<i className="fa fa-facebook-square fa-2" aria-hidden="true"></i>)
                break;
            case 'twitter':
                component = (<i className="fa fa-twitter-square fa-2" aria-hidden="true"></i>)
                break;
            case 'instagram':
                component = (<i className="fa fa-instagram fa-2" aria-hidden="true"></i>)
                break;
            case 'youtube':
                component = (<i className="fa fa-youtube-play fa-2" aria-hidden="true"></i>)
                break;
            default:
                break;
        }        
        return (component)
    }
    
}

const mapStateToProps = state => {
    return {
        general: state.general.general
    }
}

export default connect(mapStateToProps)(multilanguage(Footer))