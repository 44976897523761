import React, { Fragment, useEffect, useState } from 'react'
import { connect } from "react-redux";
import { Dropdown, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import TopMenuItem from "./TopMenuItem";
import Section from "../features/Section"

import { SwipeableDrawer } from '@material-ui/core';

// redux actions
import { getAddresses } from '../../store/addresses';

const AddressData = ({ data, idx }) => (
    <Dropdown.Item
    key={idx}
    style={{
        display: 'flex',
        flexWrap: 'wrap',
        borderTop: '1px solid #eeeeee',
        padding: '1em 1.2em',
    }}>
        <span
        style={{
            backgroundColor: '#8A8A8A',
            width: '40px',
            height: '40px',
            borderRadius: '50px',
            marginRight: '.8em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <i
            className="pe-7s-bottom-arrow"
            style={{
                fontSize: "25px",
                color: '#F1F1F1',
            }} />
        </span>
        <div>
            <p style={{
                marginBottom: 0
            }}>
                Calle {data.street} #{data.zip_code}, {data.zone_text}
            </p>
            <span>
                {data.atype.name}
            </span>
        </div>
        <i
        className="pe-7s-bottom-arrow"
        style={{
            fontSize: "2em",
            float: 'right',
            marginLeft: 'auto'
        }}/>
    </Dropdown.Item>
)

const LocationsList = ({ 
    section,
    user,
    login,
    addresses,
    getAddresses,
    loading
}) => {
    const [address, setAddress] = useState('')
    const [display, setDisplay] = useState(false)

    const fetchAddreses = () => {
        if (login && login.key) getAddresses(login)
    }

    const switchDisplay = (state) => setDisplay(state)

    // useEffect(() => {
    //     if (section) console.log('section', section)
    // }, [section])

    useEffect(() => {
        fetchAddreses()
    }, [])

    return (
        <Section key={section.id} section={section}>
            <div>
                <div 
                onClick={() => switchDisplay(true)}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                 }}>
                     <i
                      className="pe-7s-bottom-arrow"
                      style={{
                          fontSize: "2em",
                          marginRight: '.5rem'
                      }} />

                    <span
                    style={{
                        marginRight: '.5rem'
                    }}>
                        Ingresar ubicación
                    </span>

                    <TopMenuItem
                    iconComponent={
                        <i className="pe-7s-bottom-arrow" style={{fontSize: "2em"}}/>
                    }></TopMenuItem>
                </div>

                <SwipeableDrawer
                 anchor={ section.display_option.slug.split('-')[1] || 'up' }
                 open={display}
                 onClose={() => switchDisplay(false)}
                 onOpen={() => switchDisplay(true)}
                 PaperProps={{
                    style:{
                        minWidth:'30%' 
                    }
                 }}>
                    { user && login && login.key && login.csrftoken ? (
                        <Fragment>
                            <div
                            className="menu_color_font"
                            style={{ position: 'relative' }}>
                                <i
                                onClick={() => switchDisplay(false)}
                                className="pe-7s-close"
                                aria-hidden="true"
                                style={{
                                    position: 'absolute',
                                    top: '0px',
                                    right: '0px',
                                    cursor: 'pointer',
                                    fontSize: 50,
                                    zIndex: 99999
                                }}></i>
                                <Row noGutters>
                                    <Col
                                    className="content-center"
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap'
                                    }}>
                                        <i className="pe-7s-bottom-arrow" style={{ fontSize: "2em" }}/>
                                        <h4 style={{ margin: '0 .4em' }}>
                                            Ingresar ubicación
                                        </h4>
                                        <i className="pe-7s-bottom-arrow" style={{ fontSize: "2em" }}/>
                                    </Col>
                                </Row>
                                <Row
                                className="content-center"
                                style={{
                                    marginTop: '2em'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        borderRadius: '10px',
                                        width: "80%",
                                        height: '50px',
                                        padding: '.4em',
                                        border: 'none',
                                        fontSize: '.8rem',
                                        backgroundColor: '#F2F2F2'
                                    }}>
                                        <i
                                         className="pe-7s-bottom-arrow"
                                         aria-hidden="true"
                                         style={{fontSize: '2em'}}></i>
                                        <input
                                        style={{
                                            border: 'none',
                                            outline: 'none',
                                            background: 'none',
                                            padding: '0 .8em',
                                            width: '100%'
                                        }}
                                        type="text"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        placeholder="Ingresa una nueva dirección" />
                                    </div>
                                </Row>
                                <Row
                                style={{
                                    marginTop: '2em',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    marginLeft: '1.7em'
                                }}>
                                    <span
                                    style={{
                                        backgroundColor: '#8A8A8A',
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '50px',
                                        marginRight: '.8em',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <i
                                        className="pe-7s-bottom-arrow"
                                        style={{
                                            fontSize: "25px",
                                            color: '#F1F1F1',
                                        }} />
                                    </span>
                                    <span>
                                        Ubicación actual
                                    </span>
                                </Row>
                            </div>
                            { addresses && addresses.length ? (
                                <Fragment>
                                    <Dropdown.Divider />
                                        { addresses && addresses.map((addr, idx) => (
                                            <AddressData
                                             key={idx}
                                             data={addr} />
                                        )) }
                                    <Dropdown.Divider />
                                </Fragment>
                            ) : (
                                <Dropdown.Item as={Link} to='/account/addresses'>
                                    Da de alta una dirección
                                </Dropdown.Item>
                            ) }
                        </Fragment>
                    ) : (
                        <Dropdown.Item as={Link} to='/login'>
                            Iniciar sesión
                        </Dropdown.Item>
                    )}
                </SwipeableDrawer>
            </div>
        </Section>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    login: state.user.login,
    addresses: state.addresses.items,
    loading: state.addresses.loading,
});

const mapDispatchToProps = {
    getAddresses
};


export default connect(mapStateToProps, mapDispatchToProps)(LocationsList)