import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card, Nav, Accordion, Button, ToggleButtonGroup, ToggleButton, Image } from 'react-bootstrap'
import { getActivities } from '../../api/api'

export default class FiltersView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filters: []
        }
    }

    componentDidMount() {   
        this.fetchActivities()     
    }

    fetchActivities() {
        getActivities().then(result => {
            if (result.status === 200) {
                this.setState({activities: result.data})
                let filters = [
                    {
                        id: "0",
                        slug: "activities",
                        name: 'CATEGORÍAS',
                        elements: result.data
                    }
                ]
                this.setState({filters})
            }
        })
    }
    
    render() {        
        return(
            <Container style={{marginTop:40}}>                     
            {
                this.renderFilters()
            }       
            </Container>
        )
    }

    renderFilters() {
        const {filters} = this.state
        if (filters && filters.length > 0) {            
            let withElements = filters.filter( it => it.elements.length > 0)
            return withElements.map(filter => {                
                let toggleButtons = []
                switch (filter.slug) {                                    
                    default:
                        toggleButtons = [
                            <ToggleButton
                                key='Todas'
                                bsPrefix='customToggleButton'
                                value={-1} >
                                <div className='withColorPrimary'>
                                    {'Todas'}
                                </div>
                            </ToggleButton>
                        ]
                        toggleButtons.push(filter.elements.map(element => {
                            return (
                                <ToggleButton
                                    key = {element.id}
                                    bsPrefix='customToggleButton'
                                    value={element.id} >
                                    <div className='withColorPrimary'>
                                        {element.name}
                                    </div>
                                </ToggleButton>
                            )
                        }))
                        break;
                }
                return (
                    <Accordion key={filter.id} defaultActiveKey={filter.id}>
                        <Card bsPrefix='filter-container' style={{marginBottom:20}}>
                            <Card.Header bsPrefix="filter-header">
                                <Accordion.Toggle as={Card.Header} eventKey={filter.id}>
                                    <Row style={{justifyContent:'space-between', fontSize:17}}>
                                        <strong>{filter.name}</strong>
                                        <Image src="/assets/icons/FLECHA-ABAJO.png" style={{width:7.75 * 2, height:4.9 * 2}}/>
                                    </Row>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={filter.id}>
                                <Card.Body className='px-0'>
                                    <ToggleButtonGroup 
                                        type="radio"    
                                        defaultValue={-1}
                                        name={filter.id}                                     
                                        onChange={(values) =>  this.onFilterPress(values, filter)}
                                        vertical
                                        >                                        
                                        {
                                            toggleButtons
                                        }
                                    </ToggleButtonGroup>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                )
            })
        }
    }

    onFilterPress(values,  variant) {        
        const {onFiltersChange} = this.props
        if (onFiltersChange) {
            onFiltersChange(values, variant)
        }
    }

}