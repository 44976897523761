import React, { Component } from "react";
import { Container, Row, Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {getColumnClasses} from '../../utils/Utils'

export default class FeatureCard extends Component {

    render(){
        const {card} = this.props
        let renderCard = null
        if (card) {
            renderCard = (
                <Col bsPrefix="column"  
                    className={`featureCard featureCard-${card.id} ${getColumnClasses(card)}`}>
                    <Card style={{ padding: 0, border: 'none' }}>
                        <Link to={card.link ? card.link :`#`} >
                            <Card.Img variant="top"
                                src={`${card.image}`}
                                style={{ borderRadius: 0 }} />
                        </Link>
                    </Card>
                </Col>
            )
        }
        
        return (renderCard)
    }

}