import React, { Component } from "react";
import { Container, Row, Card, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import {getColumnClasses} from '../../utils/Utils'
import { sanitizeImageURL, URL_TESTER } from "../../api/api";

export default class SectionMenuItem extends Component {

    render(){        
        const {
            element,
            onlyIcons,
            customMenuItemClass,
            customMenuItemIconClass
        } = this.props
        let renderElement = null
        if (element) {
            const linkContent = (
                <div>
                    {
                        element.image ?
                            <Image
                                src={sanitizeImageURL(element.image)}
                                className={`section-menu-item-icon ${customMenuItemIconClass ? customMenuItemIconClass : ''}`} />
                            :
                            null
                    }
                    {
                        !onlyIcons ?
                            <span className={`menuItem-${element.id} ${customMenuItemClass ? customMenuItemClass : ''}`}>
                                {element.link_text}
                            </span>
                            : null
                    }
                </div>
            )
            if (URL_TESTER.test(element.link)) {
                renderElement = (
                    <Col bsPrefix="column"  
                        className={`menuItem menuItem-${element.id} ${getColumnClasses(element)}`}>
                        <a href={element.link}
                            className="card-link"
                        >
                            {
                               linkContent
                            }
                        </a>                    
                    </Col>
                )
            } else {
                renderElement = (
                    <Col bsPrefix="column"  
                        className={`menuItem menuItem-${element.id} ${getColumnClasses(element)}`}>
                        <Link to={element.link ? element.link :`#`}
                            className="card-link"
                        >
                            {
                               linkContent
                            }
                        </Link>                    
                    </Col>
                )
            }
        }
        
        return (renderElement)
    }

}