import { combineReducers } from 'redux';

// reducers
import cartReducer from './cart';
import compareReducer from './compare';
import currencyReducer from './currency';
import localeReducer from './locale';
import mobileMenuReducer from './mobile-menu';
import quickviewReducer from './quickview';
import sidebarReducer from './sidebar';
import wishlistReducer from './wishlist';
import masterReducer from './master';
import menuReducer from './menu'
import userReducer from './user'
import generalReducer from './general'
import sharedBuyReducer from './shared-buys'
import shopReducer from './shop'
import addrReducer from './addresses'
import { createMultilanguageReducer } from "redux-multilanguage";


export default combineReducers({
    multilanguage: createMultilanguageReducer({ currentLanguageCode: "es" }),
    cart: cartReducer,
    compare: compareReducer,
    currency: currencyReducer,
    locale: localeReducer,
    mobileMenu: mobileMenuReducer,
    quickview: quickviewReducer,
    sidebar: sidebarReducer,
    wishlist: wishlistReducer,
    master: masterReducer,
    menu: menuReducer,
    user: userReducer,
    general: generalReducer,
    sharedBuy: sharedBuyReducer,
    shop: shopReducer,
    addresses: addrReducer
});
