import React, { useEffect, useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getSectionsToMenu } from '../../api/api'
import { isMobile } from '../../utils/Utils'
import DropDownMenu from './DropdownMenu'
import DropdownMenuSection from './DropdownMenuSection'

import Section from "./Section"

const SectionNavbarMenu = ({ section, general}) => {    

    const [customSections, setCustomSections] = useState([])
    useEffect(() => {
        getSectionsToMenu().then(result => {
            if (result.status === 200) {
                const customSections = result.data.filter(it => it.menu)
                setCustomSections(customSections)
            }
        }).catch(error => console.error('Error', error))
    }, [])

    const createMenuOption = (option, url, order = 0) => {
        return {
            option,
            url: url ? url:option,
            order
        }
    }


    const createLinksFromDesigner = (section) => {
        let links = []        
        let validElements = section.elements.filter(it => !it.deleted)
        validElements.forEach(it => {
            if (it.otype.slug === 'menu-item'
                || it.otype.slug === 'menu-dropdown-item'
                || it.otype.slug === 'menu-section'
            ) {
                links.push(
                    {
                        option: it.link_text,
                        url: it.link,
                        component: it
                    }
                )
            }
        })

        return links
    }

    let links = []
    let classNavBar= "nav-panel-custom"
    if (section) {
        links = createLinksFromDesigner(section)
    }

    if (links.length === 0) {
        if (general) {
            classNavBar= "nav-panel"
            //Menu Options
            for (let property in general) {
                if (/.*_menu_text/.test(property)) {
                    const propertyBand = property.substring(0, property.indexOf('_menu_text'))
                    if (general[propertyBand]) {
                        if (propertyBand === 'blog')
                            links.push(createMenuOption(general[property], `/${propertyBand}`, 9))
                        else
                            links.push(createMenuOption(general[property], `/${propertyBand}`))
                    }
                }
            }
        }

        if (customSections) {
            customSections.forEach(section => {
                links.push(createMenuOption(section.menu_text, `/section/${section.slug}/`, 1))
            });
        }
    }

    const menuLinks = []
    if (links) {
        let linksOrdered = links.sort((a, b) => a.order - b.order)
        linksOrdered.forEach((link, index) => {
            if (link.component) {

                let componentStyle = {}
                if (link.component.html_attrs) {
                    try {
                        let attributes = link.component.html_attrs.split(';')
                        let entries = []
                        for (const attribute of attributes) {
                            entries.push(attribute.split(":"))
                        }
                        componentStyle = Object.fromEntries(new Map(entries))
                    } catch (e) {
                        console.warn("html_attr invalidos", e)
                    }
                }
                switch (link.component.otype.slug) {
                    case "menu-item":
                        menuLinks.push(
                            <Nav.Link key={index}
                                as={Link}
                                to={link.url}
                                className={link.component.css_class}
                                style={componentStyle}
                            >
                                {link.option}
                            </Nav.Link>
                        )
                        break;
                    case "menu-dropdown-item":
                        menuLinks.push(
                            <DropDownMenu {...link.component} />
                        )
                        break;

                    case "menu-section":
                        menuLinks.push(
                            <DropdownMenuSection {...link.component} />
                        )
                        break;

                    default:
                        break;
                }
            } else {
                menuLinks.push(
                    <Nav.Link key={index} as={Link} to={link.url}>
                        {link.option}
                    </Nav.Link>
                )
            }
        })
    }

    let classHeaderLinks = isMobile() ? "header-links-movil": "header-links" 

    return (
        <Section key={section.id} section={section} noElements noSubSections>
            <Navbar
                className={`${classNavBar} ${classHeaderLinks}`}
                bg="navbar"
                fixed="top"
                sticky="top"
                expand="lg"
                style={{ fontSize: 20 }}>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className={`mr-auto ${classHeaderLinks}`}>
                        {
                            menuLinks
                        }
                    </Nav>

                    {/* <Row noGutters style={{ justifyContent: 'flex-end' }}>
                            {
                                general && general.shop && (
                                    <Fragment>
                                        <TopMenuItemWishlist />
                                        <TopMenuItemCart />
                                    </Fragment>
                                )
                            }
                            {
                                master && master.crm_config && master.crm_config.web_sign_up && (
                                    <TopMenuItemAccount />
                                )
                            }                            
                        </Row> */}

                </Navbar.Collapse>
            </Navbar>
        </Section>
    )
}

const mapStateToProps = state => {
    return {
        general: state.general.general
    }
}

export default connect(mapStateToProps)(SectionNavbarMenu)