import { CARGAR_MENU_CATEGORIAS, UPDATE_CATEGORIAS_MENU, MENU_PRINCIPAL } from "./menuActionTypes";

const INITIAL_STATE = {
    menu_categorias: [],
	menu_submenu: [],
	menu_principal: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CARGAR_MENU_CATEGORIAS:
            return {
                ...state,
                menu_categorias: action.payload,
                menu_submenu: [],
            };

        case UPDATE_CATEGORIAS_MENU:
            return {
                ...state,
                menu_submenu: action.payload,
            };
        case MENU_PRINCIPAL:
            return {
                ...state,
                menu_principal: action.payload,
            };

        default:
            return state;
    }
};
