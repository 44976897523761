import React from 'react';
import { Card, Col } from 'react-bootstrap';

import { getColumnClasses } from '../../../utils/Utils';
import './styles.css';

function ThumbnailHoverover({ element }) {

  return (
    <Col
      key={element.id}
      className={`card-thumbnail card-thumbnail-${
        element.id
      } ${getColumnClasses(element)}`}
    >
      <Card className="text-center" style={{ border: 'none' }}>
        <Card.Body style={{ padding: 0 }}>
          {element.image && (
            <>
              <Card.Img
                variant="top"
                src={element.image}
              />
              <Card.ImgOverlay className="thumb__hoverover">
              <a href={element.link} style={{color:'white'}}>
                  <div
                  className="overlay__content"
                    dangerouslySetInnerHTML={{ __html: element.description }}
                  />
              </a>
              </Card.ImgOverlay>
            </>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
}

export default ThumbnailHoverover;
