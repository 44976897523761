import React from 'react'
import { Row, Col } from 'react-bootstrap'


export const TitleMobile = (props) => {

    const title = props.title ? props.title : ''
    return(
        <Row>
            <Col md={12} className="title-mobile">
                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>                                        
                    <span style={{marginLeft:40, marginRight:40}}>{title}</span>                    
                </div>
            </Col>            
        </Row>
    )

}