import { CARGAR_MASTER, CARGANDO, ERROR, CARGAR_MASTER_CONFIGS, CARGAR_MARKETPLACE_CONFIGS } from "./masterActionTypes";
import {loadShopConfigs, loadAllCategories} from '../shop/shopActions'
import { getMasterVars, getMasterConfigs, getMarketplaceConfig, getProviders } from "../../api/api";

export const getmaster = () => async (dispatch) => {
    dispatch({
        type: CARGANDO,
    });

    getMasterVars().then((result) => {
        if (result.status == 200) {
            const masterData = result.data[0]
            dispatch({
                type: CARGAR_MASTER,
                payload: masterData,
            });

            dispatch(loadShopConfigs(masterData.id));
            dispatch(loadSiteprovider(masterData.id));
            dispatch(loadAllCategories());            
        } else {
            console.log(result.problem);
            dispatch({
                type: ERROR,
                payload: "Algo salió mal, intente más tarde.",
            });
        }
    });

    getMasterConfigs().then(result => {
        if (result.status == 200) {
            const masterConfigs = result.data[0]
            dispatch({
                type: CARGAR_MASTER_CONFIGS,
                payload: masterConfigs,
            });
        } else {
            console.log(result.problem);
            dispatch({
                type: ERROR,
                payload: "Algo salió mal, intente más tarde.",
            });
        }
    }).catch(error => console.error('Error', error))

    getMarketplaceConfig().then(result => {
        if (result.status == 200) {
            const marketplacesConfig = result.data[0]
            dispatch({
                type: CARGAR_MARKETPLACE_CONFIGS,
                payload: marketplacesConfig,
            });
        } else {
            console.log(result.problem);
            dispatch({
                type: ERROR,
                payload: "Algo salió mal, intente más tarde.",
            });
        }
    }).catch(error => console.error('Error', error))
    
};


export const loadSiteprovider = (siteId) => async (dispatch) => {
    // console.log("######################### loadSiteprovider")
    getProviders(`site_provider_id=${siteId}`).then(result => {
        if (result.status == 200) {
            // const masterConfigs = result.data[0]
            // dispatch({
            //     type: CARGAR_MASTER_CONFIGS,
            //     payload: masterConfigs,
            // });
        } else {
            console.log(result.problem);
            dispatch({
                type: ERROR,
                payload: "Algo salió mal, intente más tarde.",
            });
        }
    }).catch(error => console.error('Error', error))

};