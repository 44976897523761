import moment from "moment"

export function isValidDate(expires_at) {        
    const now = moment()
    const expiraAt = moment(expires_at)
    let diff = expiraAt.diff(now, "hours", true)
    const hours = Math.floor(diff)        
    if (hours >=0) {
        return true
    } else {
        return false
    }        
}


export function checkContactarVendedor(product, quantity, master_configs, shop_configs) {    
    if (shop_configs && shop_configs.block_sales) {
        return true
    }
    if (product.price === 0)
        return true
    
    if(master_configs && master_configs.inventory){
        const qty_sale = product.qty_sale
        if (quantity > qty_sale) {
            return true
        } 
    } else {
        let maxDelivery = product.max_delivery   
        if(maxDelivery > 0 ){ 
            if (quantity > maxDelivery) {
                return true
            } 
        }        
    }                
    return false
}

export function getColumnClasses(element) {
    let bootstrap = {}
    let css_class = element && element.css_class ? element.css_class : ''    
    return css_class
}

export function getElementStyle(element, elementClassName, sectionClassName) {        
    if (element.html_attrs && element.html_attrs.length > 0){
        return `
            .${sectionClassName} .${elementClassName}-${element.id} {
                ${element.html_attrs}
                ${isMobile() ? element.html_attrs_mobile: ''}
            }
        `
    } else {
        return ``
    }
}

export function isMobile() {
    return /iPhone|iPod|Android/i.test(navigator.userAgent)
}

export function isTrue(value){
    if (typeof(value) === 'string'){
        value = value.trim().toLowerCase();
    }
    switch(value){
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default: 
            return false;
    }
}


export function renderOnlyXCharacters(text, limit=40) {
    if (text){
        if (text.length > limit)
            return text.substr(0, limit) + '...'
        return text
    }
    return ''
}

export const SOCIAL_NETWORKS = {
    FACEBOOK: 'Facebook',
    INSTAGRAM: 'Instagram',  
    TWITTER: 'Twitter',  
    YOUTUBE: 'Youtube',  
  }
  