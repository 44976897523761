import { GET_USER, CARGANDO, ERROR, RESET_USER, DO_LOGIN, DO_LOGOUT, UPDATE_USER, GET_MASTER_USER } from './userActionTypes';

const INITIAL_STATE = {
	user: {},
	login: {},
	cargando: false,
	error: ''
};

export default (state = INITIAL_STATE, action) => {	
	switch (action.type) {
		case GET_USER:
			return {
				...state,
				user: action.payload,
				cargando: false,
				error: ''
			};
		case DO_LOGIN:
			return {
				...state,
				login: action.payload,
				cargando: false,
				error: ''
			};

		case DO_LOGOUT:
			return {
				...state,
				login: action.payload,
				cargando: false,
				error: ''
			};

		case CARGANDO:
			return { ...state, cargando: true };

		case ERROR:
			return { ...state, error: action.payload, cargando: false };

		case RESET_USER:
			return { user: {}, error: '', cargando: false };

		case UPDATE_USER:
			return {
				...state, 
				user: {...state.user, ...action.payload}
			};

		case GET_MASTER_USER:
			return {
				...state, 
				master_user: {...action.payload.master_user}
			};

		default: return state;
	};
};