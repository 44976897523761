import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Button, Col, Nav, Navbar, Row } from 'react-bootstrap'
import Swiper from "react-id-swiper";
import Title from '../../components/shared/Title'


const CardsCarousel = ({cards, title}) => {        
    const [swiper, updateSwiper] = useState(null);
    const params = {        
        pagination: false,        
        spaceBetween: 30,
        breakpoints: {
            1024: {
              slidesPerView: 5,
              spaceBetween: 10
            },
            768: {
              slidesPerView: 5,
              spaceBetween: 10
            },
            640: {
              slidesPerView: 4,
              spaceBetween: 10
            },
            320: {
              slidesPerView: 2,
              spaceBetween: 10
            }
          }
      }

      const goNext = () => {          
        if (swiper !== null) {
          swiper.slideNext();
        }
      };
    
      const goPrev = () => {
        if (swiper !== null) {
          swiper.slidePrev();
        }
      };

    return (           
            <Col sm={12}>
                    <Title 
                        title={title ? title : ''}
                        hideLeftLine
                        rightComponent = {
                            <div className="row" style={{marginLeft:10, marginRight:0}}>                                                                
                                <Button variant="light"                                    
                                    onClick={goPrev}
                                    style={{padding:5, paddingRight:2}}
                                    >
                                    <i classaName="fa fa-caret-square-o-left" aria-hidden="true"></i>
                                </Button>
                                <Button variant="light" 
                                    style={{padding:5, paddingLeft:2}}
                                    onClick={goNext}>                                    
                                    <i classaName="fa fa-caret-square-o-right" aria-hidden="true"></i>
                                </Button>                                
                            </div>
                        }
                    />
            {
                cards && cards.length > 0 && (
                    <Swiper {...params} getSwiper={updateSwiper}>
                        {cards}
                    </Swiper>
                )
            }
            </Col>
    )
}

export default CardsCarousel