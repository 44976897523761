import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Spinner, Image } from 'react-bootstrap'
import { sanitizeImageURL } from '../../api/api';

export default class ListView extends Component {

    render() {
        const {page, layout, onItemPress, imageRounded} = this.props

        let cols = 12
        let class5 = ""
        switch(layout) {
            case "grid-2":
                cols= 6; break;
            case "grid-3":
                cols= 4; break;
            case "grid-4":
                cols= 3; break;
            case "grid-5":
                class5 = "col-5-lists";
                cols= {span:2}; break;
            case "grid-6":
                cols= 2; break;
                case "list": 
                cols = 12; break;
            default: 
                cols = 12; 
        }

        let productsComponents = null
        if (page && (page.loading || (page.results && page.results.length > 0) )){
            if (page.loading){
                productsComponents = (
                    <Col md={12} className="text-center" style={{height:300, paddingTop:100}}>
                        <Spinner animation="border" />
                    </Col>
                )
            } else {
                if (page.results) {
                    let results = [...page.results]
                    productsComponents = results.map(it => {
                        let link = `/directorio/${it.slug}/`
                        return (                    
                            <Col key={it.id} md={cols} className={`${layout !== 'list' ? class5 + ' content-center':''}`}>
                                <Link to={link}>
                                {/* <a onClick={() => onItemPress(it)}></a> */}
                                    <Card style={{marginBottom:40, fontSize:12, border:'none'}}>
                                        <Card.Body className="text-center thumbnail-card" style={{display:'flex', flexDirection:'column', alignItems:'center'}}>                                            
                                            {/* <Image src={"https://ravishly.com/sites/default/files/images/article/2019-04/Bratchild.jpg"} roundedCircle style={{width:120, height:120, objectFit:'cover'}}/> */}
                                            <Image src={sanitizeImageURL(it.image_thumbnail_get)} roundedCircle={imageRounded} style={{width:120, height:120, objectFit:'cover'}} alt={it.site_provider.name} />
                                            {/*<strong>{`${it.site_provider.contact_name} ${it.site_provider.contact_last_name}`}</strong>*/}
                                            {/*<span>{`${it.name}`}</span>*/}
                                            {/*<span style={{color:'gray'}}>{`${it.site_provider.activity ? it.site_provider.activity.name:''}`}</span>*/}                              
                                            {/*<strong>{`Ubicación: ${it.site_provider.state ? it.site_provider.state.name: ''} ${it.site_provider.country ? it.site_provider.country.name:''}`}</strong>*/}
                                            {/*<strong>{`Contacto: ${it.site_provider.contact_phone}`}</strong>*/}
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        )                
                    })
                }
            }
        } else {
            productsComponents = (                    
                <Col md={12} className="text-center">
                    No hay elementos en el directorio.
                </Col>
            )
        } 
        return(
                <Row style={{margin:'10px 0px'}} className="justify-content-md-center" >
                    {productsComponents}                    
                </Row>
            // <Container style={{margin:'40px 0px 40px 0px'}}>
            // </Container>
        )
    }

}