import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap'

import ProductCard from './ProductCard'

export default class ProductsView extends Component {

    render() {
        const {productPage, layout} = this.props

        let cols = 12
        let grid5 = false
        switch(layout) {
            case "grid-2":
                cols= 6; break;
            case "grid-3":
                cols= 4; break;
            case "grid-4":
                cols= 3; break;
            case "grid-5":
                grid5 = true
                cols= {span:2}; break;
            case "list": 
                cols = 12; break;
            default: 
                cols = 12; 
        }

        let productsComponents = null
        if (productPage && (productPage.loading || (productPage.products && productPage.products.length > 0) )){
            if (productPage.loading){
                productsComponents = (
                    <Col md={12} className="text-center" style={{height:300, paddingTop:100}}>
                        <Spinner animation="border" />
                    </Col>
                )
            } else {
                if (productPage.products) {
                    productsComponents = productPage.products.map((it, index) => {
                        let aux
                        let class5 = ""
                        if (grid5){
                            aux = 2 
                            class5 = 'col-5-lists' /*{
                                ...cols,
                                offset: ((index % 5 === 0) ? 1 : 0)
                            }*/
                        } else {
                            aux = cols
                        }
                        return (                    
                            <Col key={it.id} md={aux} xs={6} className={`${layout !== 'list' ? class5 + ' text-center content-center padding-off':''}`}>
                                <ProductCard product={it} layout={layout}/>
                            </Col>
                        )                
                    })
                }
            }
        } else {
            productsComponents = (                    
                <Col md={12} className="text-center">
                    No hay productos
                </Col>
            )
        } 
        return(
                <Row style={{margin:'10px 0px'}} className="justify-content-md-center" >
                    {productsComponents}                    
                </Row>
            // <Container style={{margin:'40px 0px 40px 0px'}}>
            // </Container>
        )
    }

}