import React, { Component } from "react";
import { Container, Row, Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {getColumnClasses} from '../../utils/Utils'
import Subscriber from "./Subscriber";

export default class SuscriberCard extends Component {

    render(){
        const {card} = this.props        
        let renderCard = null
        if (card) {
            renderCard = (
                <Col bsPrefix="column"  
                    className={`subscriptionCard subscriptionCard-${card.id} ${getColumnClasses(card)}`}>
                    <Card style={{ padding: 0, border: 'none' }}>
                        <Subscriber />
                    </Card>
                </Col>
            )
        }
        
        return (renderCard)
    }

}