import { toast } from 'react-toastify';
import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM, WISHLIST_CLEAR } from './wishlistActionTypes';
import {addToWhisList, getWishLists, removeFromWishlist} from '../../api/api'

export function wishlistAddItemSuccess(product, silent) {
    if (!silent) {
        toast.success(`Producto "${product.name}" agregado a la lista de deseos!`);
    }

    return {
        type: WISHLIST_ADD_ITEM,
        product,
    };
}

export function wishlistRemoveItemSuccess(productId) {
    return {
        type: WISHLIST_REMOVE_ITEM,
        productId,
    };
}

export function clearWhislist() {
    return {
        type: WISHLIST_CLEAR,
    };
}

export function wishlistAddItem(product, loginDetails) {
    // sending request to server, timeout is used as a stub
    return (dispatch, getState) => (
        new Promise((resolve) => {
            //const shop_configs = getState().shop.shop_configs            
            if (loginDetails && loginDetails.key) {
                addToWhisList({
                    product_id: product.id,
                    quantity: 1
                }, loginDetails).then(result => {
                    if (result.status == 200) {
                        dispatch(wishlistAddItemSuccess(product));
                    } else {
                        toast.error(`Error al guardar en la lista de deseos!`);
                    }
                    resolve()
                }).catch(error => console.warn('error addToShopCart', error))            
            } else {
                toast.warn(`Inice sesión para agregar productos a la lista de deseos!`);
                resolve()
            }
        })        
    );
}

export function wishlistRemoveItem(product, loginDetails) {
    // sending request to server, timeout is used as a stub
    //console.log('wishlistRemoveItem', product, loginDetails)
    if (loginDetails && loginDetails.key
        && product.wishlist_id && product.wishlist_item_id) {
        return (dispatch) => (
            new Promise((resolve) => {
                removeFromWishlist(product.wishlist_item_id, product.wishlist_id, loginDetails).then(result => {
                    if (result.status == 204 || result.status == 404) {
                        dispatch(wishlistRemoveItemSuccess(product.id));
                    } else {
                        toast.error(`Error al eliminar de la lista de deseos!`);
                    }
                    resolve()
                }).catch(error => console.warn('error addToShopCart', error))               
            })
        );
    } else {
        return (dispatch) => (
            new Promise((resolve) => {
                setTimeout(() => {
                    dispatch(wishlistRemoveItemSuccess(product.id));
                    resolve();
                }, 2000);
            })
        );
    }
}

export function synsWishlist(loginDetails) {
    console.log('###### synsWishlist')
    return (dispatch) => (
        new Promise((resolve) => {   
                dispatch(clearWhislist())                
                getWishLists(loginDetails).then(result => {
                    if (result.status == 200) {                        
                        const lists = result.data
                        for (let i = 0; i < lists.length; i++) {
                            const lista = lists[i];
                            for (let j = 0; j < lista.wishlist_items.length; j++) {
                                const item = lista.wishlist_items[j];
                                const product = {...item.product}
                                product.wishlist_id = lista.id
                                product.wishlist_item_id = item.id
                                dispatch(wishlistAddItemSuccess(product, true));                                
                            }                            
                        }
                    } else {
                        console.warn(`Error al sincronizar la lista de deseos!`);
                    }
                    resolve()
                }).catch(error => {
                    console.warn('error synsWishlist', error)
                    resolve()
                })                
        })
    );
}