import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet-async";

export default class DesignerStyles extends Component {

    render(){
        const {designer} = this.props        
        let elementsStyles= designer ? designer.styles : '';

        return (
            <Fragment>
                <Helmet>
                    {designer && (
                        <style type="text/css">{`
                            ${elementsStyles}
                        `}
                        </style>
                    )}
                </Helmet>
            </Fragment>
        )
    }
}