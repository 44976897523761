import React from 'react'
import { ButtonGroup, Dropdown, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const DropDownMenu = (props) => {    
    let submenuItems = []
    if (props.sub_element) {
        submenuItems = props.sub_element.map(it => (
            <Dropdown.Item eventKey={it.id} 
                as={Link}
                to={it.link}>
                {it.link_text}
            </Dropdown.Item>
        ))

    }
    return (
        <Dropdown>
            <Dropdown.Toggle as={Nav.Link} >{props.link_text}</Dropdown.Toggle>
            <Dropdown.Menu className="super-colors">
                {submenuItems}
                {/* <Dropdown.Item eventKey="3" active>Active Item</Dropdown.Item>                
                <Dropdown.Divider /> */}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default DropDownMenu