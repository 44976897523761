// react
import React, { useState, Fragment, useEffect } from 'react';
import {useSelector} from 'react-redux'
import { Card, Form } from 'react-bootstrap';
import { UseConfiguradorContext } from "../../pages/CustomClients/Context/kenworthContext";
import { createSendForm } from "../../api/api";
import { toast } from 'react-toastify';

export default function DynamicForm(props) {
    // const { HandleBack, HandleNext } = UseConfiguradorContext();
    const general = useSelector(state => state.general.general)
    const master = useSelector(state => state.master.master)
    const [dataform, setdataform] = useState([]);
    const [ischecked, setischecked] = useState(false);
    const {element} = props
    let form_elements = element.description ? JSON.parse(element.description) : null

    function handleChange({ target }) {
        setdataform({
          ...dataform,
          [target.name]: target.value,
        });
    }

    // useEffect(() => {
    //     console.log("Dataform", dataform);
    //     console.log("ischecje", ischecked);
    // }, [dataform, ischecked]);

    function HandleSend() {
        if (dataform && Object.keys(dataform).length >= form_elements.length - 1) {
            console.log("enviar estos datos", dataform);
            createSendForm(dataform, element.api_url)
            .then((res) => {
                if (res.status == 200) {
                    toast.success("Datos enviados con éxito");
                    document.getElementById("formDynamic").reset();
                }
            })
            .catch((err) => {
                toast.error("Ocurrio un error");
            });
        } else {
            toast.error("Por favor llene todos los campos");
        }
    }

    if (general && master) {
        // const recaptcha_key = master.crm_config ? master.crm_config.recaptcha_key : null
        let renderForm = form_elements.map((input, index) => {
            switch (input.type) {
                case 'text':
                    return (
                        <Form.Group controlId="formBasicText">                            
                            <Form.Control type={input.type} 
                                style={input.style} 
                                className={input.class}
                                placeholder={input.placeholder ? input.placeholder : input.label} 
                                name={input.field}
                                required={input.required}
                                onChange={(e) => handleChange(e)}
                                /> 
                        </Form.Group>                                                             
                    )
                case 'button':
                    return (
                        <Form.Group controlId="formBasicText">                            
                            <input type={input.type} 
                                className={input.class}
                                value={input.label}
                                name={input.field}
                                onClick={() => {
                                    HandleSend();
                                }}
                                /> 
                        </Form.Group>                                                             
                    )
                case 'checkbox':
                case 'radio':
                    let group_checks = input.options.map((el, i) => {
                        return (
                            <Form.Check 
                                id={`radio-${i}`} 
                                type={input.type} 
                                name={input.field} 
                                value={el} 
                                label={el} 
                                required={input.required}
                                onChange={(e) => handleChange(e)}
                            />
                        )
                    })
                    return (
                        <Form.Group controlId="formBasicChecks" className={input.class}>                            
                            <Form.Label>{input.label}</Form.Label>
                            {group_checks} 
                        </Form.Group>
                    )
                default:
                    return null;
            }
        })
        return (            
            <Card className='' style={{border:'none', width: '100%'}}>
                <Card.Body bsPrefix='custom'>
                    <Form id="formDynamic" className={element.css_class}>
                        {renderForm}
                    </Form>
                </Card.Body>
            </Card>
        )   
    } else {
        return null
    }
}
