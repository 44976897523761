import { CARGAR_GENERAL, CARGANDO, ERROR, CARGAR_CATEGORIAS } from "./shopActionTypes";
import { getShopConfigs, getAllCategories, getCategoriasMenu, getSubCategorias } from "../../api/api";

export const loadShopConfigs = (siteId) => async (dispatch) => {
    getShopConfigs(siteId).then(result => {
        if (result.status === 200) {
            dispatch({
                type: CARGAR_GENERAL,
                payload: result.data
            });
        } else {
            dispatch({
                type: ERROR,
                payload: result.status
            });

        }
    }).catch(error => {
        dispatch({
            type: ERROR,
            payload: error
        });

    })

};

export const setShopConfigs = (data) => async (dispatch) => {
    dispatch({
        type: CARGAR_GENERAL,
        payload: data
    });
};


export const loadAllCategories = () => (dispatch) => {
    getCategoriasMenu().then(result => {
        if (result.status === 200) {
            // dispatch({
            //     type: CARGAR_CATEGORIAS,
            //     payload: result.data
            // });
            dispatch(synsSubCategories(result.data))
        }
    }).catch(error => console.error('No se pudieron cargar las categorias', error))
}

export const synsSubCategories = (categories) => async (dispatch) => {
    const updatedCategories = []
    for (let i = 0; i < categories.length; i++) {
        const category = categories[i]
        const result = await getSubCategorias(category.id)
        if (result.status == 200){
            const subcategories = [...result.data]
            const object = {...category, subcategories}
            updatedCategories.push(object)
        } else {
            updatedCategories.push(category)
        }
    }

    dispatch({
        type: CARGAR_CATEGORIAS,
        payload: updatedCategories,
    });
};