import React, { Component, Fragment } from "react";
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Container, Dropdown, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { multilanguage } from "redux-multilanguage";
import { getSectionsToMenu, getCategoriasMenu, getSubCategorias } from "../../api/api";
import TopMenuItemCart from "./TopMenuItemCart";
import TopMenuItemAccount from "./TopMenuItemAccount";
import Search from "./Search";
import TopMenuItemWishlist from "./TopMenuItemWishlist";
import { Link } from "react-router-dom";
import { renderDisegnerSections } from "../shared/DesignerUtils";
import DropDownMenu from "../features/DropdownMenu";
import DropdownMenuSection from "../features/DropdownMenuSection";
import DropdownMenuCategory from "../features/DropdownMenuCategory";

class Header extends Component {

    constructor(props) {
        super(props)
        this.state = {                 
        }
    }

    componentDidMount() {        
    }

    renderDisegnerSections() {
        const {designer} = this.props
        //console.log('headerDesigner', JSON.stringify(designer))
        if (designer) {
            return renderDisegnerSections(designer)            
        }
    }


    render() {
        return (
            <div id="headerWithNavBar" style={{ 
                position: '-webkit-sticky', 
                position: 'sticky', 
                top: 0, 
                zIndex: 999 }}>
                <Container fluid >
                    <Row >
                    {
                        this.renderDisegnerSections()
                    }
                    </Row>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        general: state.general.general,
        master: state.master.master,
        shop_configs: state.shop.shop_configs
    }
}

export default connect(mapStateToProps)(multilanguage(Header))