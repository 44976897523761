// react
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

// third-party
import { withRouter, Link } from 'react-router-dom';

// application
import { Cross20Svg, Search20Svg } from '../../svg';

import { searchProducts, searchProductsPaginated, sanitizeImageURL } from '../../api/api';
import { Container, Dropdown, Form, FormControl, Button, Overlay, Popover, Media, Row, Col, Spinner } from 'react-bootstrap';
import Currency from '../shared/Currency';
import { isMobile } from '../../utils/Utils';


function Search(props) {
    const {
        context,
        className,
        inputRef,
        onClose,
        location,
    } = props;
    const [suggestionsOpen, setSuggestionsOpen] = useState(false);
    const [hasSuggestions, setHasSuggestions] = useState(false);
    const [searching, setSearching] = useState(false);
    const [timeoutObject, setTimeoutObject] = useState(null);
    const [suggestedProducts, setSuggestedProducts] = useState([]);
    const [query, setQuery] = useState('');
    const wrapper = useRef(null);
    const target = useRef(null);

    const close = useCallback(() => {
        if (onClose) {
            onClose();
        }

        setSuggestionsOpen(false);
    }, [onClose]);

    useEffect(() => close(), [close, location]);

    useEffect(() => {
        const onGlobalClick = (event) => {
            if (wrapper.current && !wrapper.current.contains(event.target)) {
                close();
            }
        };

        document.addEventListener('mousedown', onGlobalClick);

        return () => document.removeEventListener('mousedown', onGlobalClick);
    }, [close]);

    useEffect(() => {
        if (query === '' || query.length <= 1) {
            setHasSuggestions(false);

            return undefined;
        }

        if (timeoutObject) {
            clearTimeout(timeoutObject)
        }

        // Use ajax request instead of timeout.
        const timeout = setTimeout(() => {
            setSearching(true)
            searchProductsPaginated(null, query, 10).then(result => {
                if (result.status === 200) {
                    const products = result.data.results
                    setSuggestedProducts(products);
                    setHasSuggestions(true);
                    setSuggestionsOpen(true);
                } else {
                    setSuggestionsOpen(false);
                }
                setSearching(false)
            }).catch(error => {
                console.error('Error searchProducts', error)
                setSearching(false)
                return undefined
            })
        }, 1000)

        setTimeoutObject(timeout)

    }, [query]);

    const handleFocus = () => {
        setSuggestionsOpen(true);
    };

    const handleChange = (event) => {
        setQuery(event.target.value);
    };

    const handleBlur = () => {
        setTimeout(() => {
            if (!document.activeElement || document.activeElement === document.body) {
                return;
            }

            // Close suggestions if the focus received an external element.
            if (wrapper.current && !wrapper.current.contains(document.activeElement)) {
                close();
            }
        }, 10);
    };

    const handleKeyDown = (event) => {
        // Escape.
        if (event.which === 27) {
            close();
        }
    };

    const closeButton = context !== 'mobile-header' ? '' : (
        <button className="search__button search__button--type--close" type="button" onClick={close}>
            <Cross20Svg />
        </button>
    );

    const submitForm = (e) => {
        e.preventDefault()
        props.history.push({
            pathname: '/catalog',
            search: `?search=${query}`
        }); // <--- The page you want to redirect your user to.
    }

    let buttonSearchClass = (isMobile() ? "nav-panel-icon-movil": "nav-panel-icon")

    return (
        // <Col sm={12} className="h-100 d-flex align-items-center">
        //     <div style={{flex:1}}>
        <Form
            className="searcher-container"
            // action="/catalog"
            onSubmit={submitForm}
        >
            <Form.Row style={{ flexWrap: 'nowrap' }}>
                {
                    !searching ?
                        <Button
                            type="submit"
                            variant="outline-light"
                            className={`${buttonSearchClass} nav-panel-search-icon`}
                            style={{ borderColor: 'transparent' }}>
                            <i className="pe-7s-search" style={{ fontSize: "2em" }} />
                        </Button>
                        :
                        <Button
                            variant="outline-light"
                            className={`${buttonSearchClass} nav-panel-search-icon`}
                            style={{ borderColor: 'transparent' }}>
                            <Spinner animation="border" size="sm" />
                        </Button>
                }
                <FormControl
                    type="text"
                    placeholder={'BUSCAR'}
                    className="searcher-input-header"
                    style={{ backgroundColor: 'transparent', border: 'none', outlineWidth: 0 }}
                    name="search"
                    onChange={handleChange}
                    onFocus={handleFocus}
                    autoComplete="off"
                    ref={target}
                />
            </Form.Row>

            {
                suggestedProducts && suggestedProducts.length > 0 && (
                    <Overlay
                        show={suggestionsOpen}
                        target={target.current}
                        placement="bottom-end"
                        // container={ref.current}
                        containerPadding={20}
                        //rootClose = {true} //for the user clicks outside the overlay
                        onExit={() => console.log('onExit')}
                        onHide={() => console.log('onHide')}

                    >
                        <div className="overlay-container">
                            <div>
                                <Row noGutters>
                                    <Col md={10}>
                                        Resultados:
                                            </Col>
                                    <Col md={2} className='text-right'>
                                        <Button variant="outline-light" style={{ borderColor: 'transparent', color: 'black', fontSize: 25, padding: 0, margin: 0 }}
                                            onClick={() => setSuggestionsOpen(false)}
                                        ><i className="pe-7s-close-circle" /></Button>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                {
                                    suggestedProducts && suggestedProducts.map(product => (
                                        <Dropdown.Item
                                            key={product.id}
                                            eventKey={product.id}
                                            href={`/shop/product/${product.slug ? product.slug : product.id}`} >
                                            <Media>
                                                <img src={sanitizeImageURL(product.thumbnail_get)} alt=""
                                                    width='auto'
                                                    height={64}
                                                    style={{ marginRight: '10px' }} />
                                                <Media.Body style={{ maxWidth: '400px', minWidth: '200px', whiteSpace: 'normal' }}>
                                                    <Row>
                                                        <Col md={10}>
                                                            <div style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}>
                                                                {product.name}
                                                            </div>
                                                        </Col>
                                                        <Col md={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Currency value={product.price} />
                                                        </Col>
                                                    </Row>
                                                </Media.Body>
                                            </Media>
                                        </Dropdown.Item>
                                    ))
                                }
                                {
                                    suggestedProducts && (
                                        <Dropdown.Header>
                                            <Dropdown.Item
                                                eventKey={-212}
                                                href={`/products/?search=${query}`} >
                                                <Media>
                                                    <Media.Body style={{ maxWidth: '400px', minWidth: '200px', whiteSpace: 'normal' }}>
                                                        <Row className="justify-content-md-center">
                                                            <Col md={10}>
                                                                <div style={{ overflowWrap: 'break-word', wordWrap: 'break-word', textAlign: 'center' }}>
                                                                    Ver todos
                                                                    </div>
                                                            </Col>
                                                        </Row>
                                                    </Media.Body>
                                                </Media>
                                            </Dropdown.Item>

                                        </Dropdown.Header>
                                    )
                                }
                            </div>
                        </div>
                    </Overlay>

                )
            }


        </Form>
        //     </div>
        // </Col>
    )
}

export default withRouter(Search);