import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap'

// import ProductCard from './ProductCard'
import FeatureIcon from '../features/FeatureIcon'

export default class ListView extends Component {

    render() {
        const {itemPage, layout} = this.props

        let cols = 12
        switch(layout) {
            case "grid-2":
                cols= 6; break;
            case "grid-3":
                cols= 4; break;
            case "grid-4":
                cols= 3; break;
            case "grid-5":
                cols= 2; break;
            case "list": 
                cols = 12; break;
            default: 
                cols = 12; 
        }

        let itemComponents = null
        if (itemPage && (itemPage.loading || (itemPage.items && itemPage.items.length > 0) )){
            if (itemPage.loading){
                itemComponents = (
                    <Col md={12} className="text-center" style={{height:300, paddingTop:100}}>
                        <Spinner animation="border" />
                    </Col>
                )
            } else {
                if (itemPage.items) {
                    itemComponents = itemPage.items.map(it => {
                        it.link = `/negocios-oficiales/${it.id}/`
                        return (                    
                            <Col key={it.id} md={cols} xs={6} className={`${layout !== 'list' ? 'text-center content-center padding-off':''}`}>
                                <FeatureIcon key={it.id} feature={it}/>
                            </Col>
                        )                
                    })
                }
            }
        } else {
            itemComponents = (                    
                <Col md={12} className="text-center">
                    Nada que mostrar
                </Col>
            )
        } 
        return(
                <Row style={{margin:'30px 0px'}} className="justify-content-md-center" >
                    {itemComponents}                    
                </Row>
            // <Container style={{margin:'40px 0px 40px 0px'}}>
            // </Container>
        )
    }

}