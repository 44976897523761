import React from 'react'

import Search from '../header/Search'
import Section from "./Section"

const SectionSearchProducts = ({section}) => {

    return (
        <Section key={section?.id} section={section}> 
            <Search />
        </Section>
    )
}

export default SectionSearchProducts