import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Row, Col, Image } from 'react-bootstrap'
import { sanitizeImageURL } from '../../../api/api'
import Rating from '../../../components/shared/Rating'
import Currency from '../../../components/shared/Currency'
import { Link } from 'react-router-dom'
import { isMobile, renderOnlyXCharacters } from '../../../utils/Utils'
import { getDiscountInPercent, getPrice } from '../../../utils/ShopUtilities'
import {BCIcons} from '../../../assets/ImageResources'

class ProductWithDiscount extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isMobile: isMobile()
        }
    }

    render() {
        const { product, layout } = this.props
        const { isMobile } = this.state

        let pathname = `/shop/product/${product.slug ? product.slug: product.id}`
        let priceComponent= null
        let currentPrice = getPrice(product)
        let discountPercent = getDiscountInPercent(product)                
        
        if (currentPrice && discountPercent < 0) {
            priceComponent = (
                <div className="product-card__prices">
                    <span className="product-card__new-price"><Currency value={currentPrice} /></span>
                    {' '}
                    <span className="product-card__old-price">{`${discountPercent}%`}</span>
                </div>
            );
        } else {
            if (currentPrice > 0) {
                priceComponent = (
                    <div className="product-card__prices">
                        <span className="product-card__new-price"><Currency value={currentPrice} /></span>
                    </div>
                );
            } else {
                priceComponent = (
                    <div className="product-card__prices">
                        Consultar
                    </div>
                );
            }
        }

        return (
            <Link to={{
                pathname,                    
                state: { product: product }
            }}>
            <Card 
                bsPrefix="product-card"
                className="text-justify"
                style={{marginBottom:'20px'}}>
                    <Card.Body className="padding-off card-portrait">
                            <Card.Img variant="top" 
                                src={sanitizeImageURL(product.thumbnail_get)} 
                                style={{
                                    width:'100%', 
                                    height: '100%',
                                    objectPosition:'center',
                                    objectFit: 'contain'
                                    }}/>
                    </Card.Body>
                <Card.Body className="padding-2 card-description d-flex flex-column">
                    
                        {priceComponent}    
                    <div className="d-flex flex-grow-1">
                        <Card.Text style={{color:'black', fontSize: 14, fontWeight:900}}>
                            {renderOnlyXCharacters(product.name.substr(0, 17) + '...')}
                        </Card.Text>                                                                
                    </div>                        

                        <Card.Text style={{color:'#869ba2', fontSize: 12}}>
                            {product.provider ? renderOnlyXCharacters(product.provider.name): ''}
                        </Card.Text>                                                                                    
                    {/* <Rating value={product.ranking} /> */}
                </Card.Body>
            </Card>
            </Link> 
        )


    }
}


export default connect()(ProductWithDiscount)