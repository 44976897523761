import React, { Component } from "react";
import { Dropdown, Row, Col, Media, Button, Spinner, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import TopMenuItem from "./TopMenuItem";
import { sanitizeImageURL } from "../../api/api";
import { cartRemoveItem } from '../../store/cart';
import Currency from "../shared/Currency";
import { Cross10Svg } from "../../svg";
import AsyncAction from "../shared/AsyncAction";
import { Design, adapter, adapterToShadow } from '../../pages/category_profile/Desing';
import { isMobile, renderOnlyXCharacters } from "../../utils/Utils";

import { SwipeableDrawer } from '@material-ui/core';
import { Fragment } from "react";

class TopMenuItemCart extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isMobile: isMobile(),
            open: false
        }
    }

    render() {
        const {cart} = this.props

        let totals;
    
        if (cart.extraLines.length > 0) {
            const extraLines = cart.extraLines.map((extraLine, index) => (
                <tr key={index}>
                    <td>{extraLine.title}</td>
                    <td><Currency value={extraLine.price} /></td>
                </tr>
            ));
    
            totals = (
                <React.Fragment>
                    <tr>
                        <td>Subtotal</td>
                        <td><Currency value={cart.subtotal} /></td>
                    </tr>
                    {extraLines}
                </React.Fragment>
            );
        }

        let cartItems = (
            <Dropdown.Item eventKey="0">
                El carrito esta vacio
            </Dropdown.Item>)
        if (cart && cart.items && cart.items.length > 0) {
            cartItems = cart.items.map((item) => {
    
                const removeButton = (
                    <AsyncAction
                        action={() => this.cartRemoveItem(item)}
                        render={({ run, loading }) => {
                            if (loading){
                                return <Spinner animation="grow" size="sm" />
                            } else
                                return (
                                    <Button variant='link' 
                                        onClick={run} 
                                        className="menu_color_font"
                                        style={{fontSize:20}}
                                        >
                                        <i classaName="fa fa-trash-o" aria-hidden="true"></i>
                                    </Button>
                                );
                        }}
                    />
                );
    
                return(
                    <Dropdown.Item key={item.id} eventKey={item.id} 
                        onSelect={() => console.log('OnSelect')} 
                        style={{borderBottom:'1px solid #fafafa'}}                       
                        >
                        <Media className="content-center">
                            <Link to={`/shop/product/${item.product.slug ? item.product.slug : item.product.id}`}>                                
                                <div style={{ position: 'relative', padding: 2 }}>
                                    {
                                        item.custom_item ? 
                                            <div style={{ width: 120, height: 120, display: 'flex', justifyContent: 'center', alignItems: 'center', overflow:'hidden' }}>
                                                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 120,  display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                    <Image
                                                        src={sanitizeImageURL(item.product.thumbnail_get)}
                                                        style={{ width: 'auto', height: 120}} />
                                                </div>
                                                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 120, zIndex: 9, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Design
                                                        customization={adapter(item.custom_item)}
                                                        rectSize={120}
                                                        showBorder={false}
                                                    />
                                                </div>
                                                {
                                                    item.custom_item && item.custom_item.color_2 && (
                                                        <div style={{ position: 'absolute', top: 1, left: 1, width: '100%', height: 120, zIndex: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Design
                                                                customization={adapterToShadow(item.custom_item)}
                                                                rectSize={120}
                                                                showBorder={false}
                                                                isShadow = {true}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            :
                                            <div style={{ width: 100, height: 120, display: 'flex', justifyContent: 'center', alignItems: 'center', overflow:'hidden' }}>
                                                <img src={sanitizeImageURL(item.product.thumbnail_get)} alt=""                                                     
                                                    style={{ width: 'auto', height: 120, borderRadius:20 }} />
                                            </div>

                                    }
                                </div>
                            </Link>
                            <Media.Body style={{maxWidth:'400px', minWidth:'200px', whiteSpace:'normal'}}>                        
                                <Row>
                                    <Col md={10}>
                                        <div >                                    
                                            <Link to={`/shop/product/${item.product.slug ? item.product.slug : item.product.id}`}
                                                style={{overflowWrap:'break-word', wordWrap:'break-word'}}
                                                className="menu_color_font">
                                                {renderOnlyXCharacters(item.product.name, 40)}</Link>
                                        </div>
                                        <div className="secondary_color_font" style={{fontSize:25}}>
                                            <span className="dropcart__product-quantity">{item.quantity}</span>
                                            {' × '}
                                            <span className="dropcart__product-price"><Currency value={item.price} /></span>
                                        </div>
                                    </Col>
                                    <Col md={2} 
                                        style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
                                        {removeButton}
                                    </Col>
                                </Row>
                            </Media.Body>
                        </Media>
                    </Dropdown.Item>
                )
            })
        }

        return (
            <Fragment>
                <TopMenuItem
                    iconComponent={
                        <i className="pe-7s-cart flip-horizontal"
                            style={{ fontSize: this.state.isMobile ? "1.5em" : "2em" }} />}
                    indicatorText={cart && cart.items ? cart.items.length : null}
                    onClick={() => this.setState({ open: true })}
                >
                </TopMenuItem>
                <SwipeableDrawer
                    anchor="right"
                    open={this.state.open}                    
                    onClose={() => this.setState({ open: false })}
                    onOpen={() => this.setState({ open: true })}
                    PaperProps={
                        {style:{minWidth:'30%'}}
                    }
                >
                    <div className="menu_color_font" >
                        <Row noGutters style={{borderBottom:'1px solid #fafafa'}}>
                            <div style={{fontSize:70}}>
                                <div onClick={() => this.setState({open:false})}>
                                    <i classaName="pe-7s-close" aria-hidden="true"></i>
                                </div>
                            </div>
                            <Col className="content-center">
                                <h4>Tu carrito</h4>
                            </Col>
                        </Row>
                    </div>
                    {cartItems}
                    <div style={{flexGrow:1, maxHeight:100, minHeight:50}}>

                    </div>
                    <div className="menu_color_font" >
                        <div className="dropcart__totals">
                            <table>
                                <tbody>
                                    {totals}
                                    <tr>
                                        <td>Total a pagar</td>
                                        <td><strong><Currency value={cart.total} /></strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Dropdown.Item as='div' eventKey="3">
                        <Button variant="secondary" size="lg" block as={Link} to="/shop/cart">
                            Carrito
                    </Button>
                    </Dropdown.Item>
                    <Dropdown.Item as='div' eventKey="3">
                        <Button variant="primary" size="lg" block as={Link} to="/shop/checkout">
                            Ir a pagar
                    </Button>
                    </Dropdown.Item>
                </SwipeableDrawer>
            </Fragment>
        )
    }


    cartRemoveItem(item) {
        const {login} = this.props        
        if (login && login.key) {
            return this.props.cartRemoveItem(item, login)
        } else {
            return this.props.cartRemoveItem(item)            
        }
    }

}

const mapStateToProps = (state) => ({
    cart: state.cart,
    login: state.user.login
});

const mapDispatchToProps = {
    cartRemoveItem,
};


export default connect(mapStateToProps, mapDispatchToProps)(TopMenuItemCart)