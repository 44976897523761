import React, { useState } from "react";

const ConfiguradorContext = React.createContext();

export function ConfiguratorProvider({ children }) {
  const [datasettings, setdatasettings] = useState([]);
  const [unidadselected, setunidadselected] = useState(1);
  const [contenttab, setcontenttab] = useState(1);

  function HandleChangeUnidadSelected(id) {
    setunidadselected(id);
  }

  function HandleNext() {
    setcontenttab(contenttab + 1);
  }

  function HandleBack() {
    setcontenttab(contenttab - 1);
  }

  function RecolectedData({ target }) {
    setdatasettings({
      ...datasettings,
      [target.name]: target.value,
    });
  }

  function HandleDataGroupRadios(datainterior) {
    setdatasettings({
      ...datasettings,
      interior: datainterior,
    });
  }

  function HandleDataGroupRadiosSeguridad(dataseguridad) {
    setdatasettings({
      ...datasettings,
      seguridad: dataseguridad,
    });
  }

  function HandleColorUnidad(color) {
    setdatasettings({
      ...datasettings,
      color_unidad: color,
    });
  }

  const value = React.useMemo(() => {
    return {
      datasettings,
      contenttab,
      HandleNext,
      HandleBack,
      RecolectedData,
      HandleChangeUnidadSelected,
      unidadselected,
      HandleDataGroupRadios,
      HandleDataGroupRadiosSeguridad,
      HandleColorUnidad,
    };
  }, [contenttab, datasettings, unidadselected]);

  console.log("datasettings", datasettings);

  return (
    <ConfiguradorContext.Provider value={value}>
      {children}
    </ConfiguradorContext.Provider>
  );
}

export function UseConfiguradorContext() {
  const contextconfigurador = React.useContext(ConfiguradorContext);
  if (!contextconfigurador) {
    throw new Error("ConfiguradorProvider debe estar dentro del proveedor");
  }

  return contextconfigurador;
}
