import React from 'react'
import Section from "../features/Section"
import Slides from "../../components/slides";
import CustomSlider from '../features/CustomSlider';
import SectionIsFeatureProducts from '../features/SectionIsFeatureProducts';
import SectionIsBestsellerProducts from '../features/SectionIsBestsellerProducts';
import SectionListProducts from '../features/SectionListProducts';
import SectionNews from '../features/SectionNews';
import DesignerStyles from '../features/DesignerStyles';
import { getPageDesignById, getPagesDesign, getSectionDesignById, getSectionsDetails } from '../../api/api';
import SectionBrandIcon from '../features/SectionBrandIcon';
import SectionSearchProducts from '../features/SectionSearchProducts';
import SectionNavbarMenu from '../features/SectionNavbarMenu';
import SectionCategoryTreeMenu from '../features/SectionCategoryTreeMenu';
import SectionGeneralList from '../features/SectionGeneralList';
import SectionOfficialStore from '../../pages/master_directory/SectionOfficialStore';
import SectionCategoryCarousel from '../features/SectionCategoryCarousel';
import SectionCategoryGrid from '../features/SectionCategoryGrid';
import SectionTabs from '../features/SectionTabs';
import SlideSection from '../slides/SlideSection';
import SectionListOffices from '../features/FloatingSection';
import FloatingSection from '../features/FloatingSection';
import LocationsList from '../header/LocationsList'

export function renderDisegnerSections(designer) {  
    console.log('renderSections', designer);  
    if (designer && designer.sections) {
        let sectionsToRender = designer.sections.sort((a, b) => a.order - b.order)
        /*Agrear a la lista sectionsToRender el componente que contendra la sobrescritura del header
            {otype: {slug: 'styles'}, content: designer, active: true, order: -1}
            agregar al pricipio de la lista
        */
       console.log('elements', sectionsToRender);
        if (designer.styles)
            sectionsToRender.unshift({otype: {slug: 'styles'}, designer: designer, active: true});
        sectionsToRender = sectionsToRender.filter(it => it.active)        
        return sectionsToRender.map(section => {
            return renderSection(section)
        })
    }
}

export function renderSection(section) {
    switch (section.otype.slug) {
        case 'slider':
            return <Slides key={section.id} section={section}/>                    
        case 'slide':
            return <SlideSection key={section.id} section={section}/>                    
        case 'slider-custom':
            return <SlideSection key={section.id} section={section}/>                    
        case 'Slider Personalizado':
            return <CustomSlider key={section.id} section={section} />
        case 'products-is-feature':
            return <SectionIsFeatureProducts key={section.id} section={section}/>                           
        case 'products-is-bestseller':
            return <SectionIsBestsellerProducts key={section.id} section={section}/>                           
        case 'products-section':
            return <SectionListProducts key={section.id} section={section}/>                           
        case 'blog-articles':
            return <SectionNews key={section.id} section={section}/>                                   
        case 'styles':
            return <DesignerStyles designer={section.designer}/>
        case 'navbar-brand':
            return <SectionBrandIcon section={section} />
        case 'product-search':
            return <SectionSearchProducts section={section} />
        case 'navbar-menu':
            return <SectionNavbarMenu section={section} />
        case 'category-tree-menu':
            return <SectionCategoryTreeMenu section={section} />
        case 'category-list':
            return <SectionCategoryCarousel section={section} />
        case 'category-grid':
            return <SectionCategoryGrid section={section} />
        case 'stores-list':
            return <SectionOfficialStore section={section} siteBar={false} />
        case 'tabs':
            return <SectionTabs section={section} />
        case 'floating-section':
            return <FloatingSection section={section} />        
        case 'list-offices':
            return <SectionListOffices section={section} />
        case 'carrouse-3d':
            return <Section key={section.id} section={section}/> 
        case 'location-section':
            return <LocationsList section={section} />
        default:
            return <Section key={section.id} section={section}/> 
    }
    return null
}


export async function getFullTreePagesDesign(pageSlug, isMobile) {
    const result = await getPagesDesign(pageSlug, isMobile)
    console.log('getPagesDesign', result)
    if (result.status === 200) {
        let activePages = result.data.filter(it => it.active)
        for (const page of activePages) {
            //console.log('Page', page)   
            if(page.active && page.sections) {
                let sections = page.sections.filter(it => !it.parent)
                let seccionsWithDetails = await fetchSectionsDetails(sections)
                //console.log('seccionsWithDetails', seccionsWithDetails)
                page.sections = seccionsWithDetails                
            }         
        }
        //console.log('fullPagesDesign', activePages)
        return activePages
    }
    return []    
}

export async function getTreePageDesignById(pageSlug, isMobile) {
    const result = await getPageDesignById(pageSlug, isMobile)
    if (result.status === 200) {
        let page = result.data
        if(page.active && page.sections) {
            let sections = page.sections.filter(it => !it.parent)
            let seccionsWithDetails = await fetchSectionsDetails(sections)
            //console.log('seccionsWithDetails', seccionsWithDetails)
            page.sections = seccionsWithDetails                
        }
        return page
    }
    return null
}

export async function fetchSectionsDetails(sections) {
    //console.log('getSecctionsDetails', sections)
    let seccionsWithDetails = []
    for (const section of sections) {
        if (section.active) {
            if (section.has_children) {
                let sectionDetailsResponse = await getSectionDesignById(section.id, true)
                //console.log('sectionDetails', sectionDetailsResponse)                    
                if (sectionDetailsResponse.status === 200) {
                    let sectionDetails = sectionDetailsResponse.data
                    if(sectionDetails.sections.length > 0){
                        let seccionsWithDetails = await fetchSectionsDetails(sectionDetails.sections)
                        sectionDetails.sections = seccionsWithDetails                
                    } 
                    seccionsWithDetails.push(sectionDetails)
                } else {
                    seccionsWithDetails.push({...section})
                }
            } else {
                seccionsWithDetails.push({...section})
            }
        }
    }
    return seccionsWithDetails
}