import React, {useState} from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import './styles.css';

export default function CustomSlider(props) {
  const { section } = props;
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const renderImages = section.elements.map(({
    image,
    image_alt,
    name,
    description,
    link,
    link_text,
  }, idx) => (
    <div key={idx} className="carousel__container__item " style={{height: '300px'}}>
      <div className="relative h-full mt-40">
        <img src={image} alt={image_alt} className={`absolute top-0 right-0 ${activeItemIndex === idx ? 'carousel__active__image blur-image' : '' }`} style={{opacity: 1}}/>
        <img src={image} alt={image_alt} className={`carousel__image absolute ${activeItemIndex === idx ? 'animate-image' : '' }`} />
        <div className="carousel__card__kw border-2 border-red-600 border-solid inset-0 absolute top-0 flex flex-col items-center bg-white shadow-md" style={{
            opacity: idx === activeItemIndex ? 1 : 0,
          }}>
          <h3 >{name}</h3>
          <div className="flex justify-center w-full text-center" dangerouslySetInnerHTML={{ __html: description }} />
          <Link className="mt-auto mb-2 p-2 mt-2 bg-red-600 rounded-md text-white mt-4" to={link}>{link_text}</Link>
        </div>
      </div>
    </div>
  ));

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: renderImages.length - 1 || 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1624,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ],
    afterChange: current => setActiveItemIndex(current),
  };

    return (
      <div className="carousel__container h-80 w-screen">
        <div className="carousel__container__body px-16 mb-64 h-full">
          <Slider {...settings}>
            { renderImages }
          </Slider>
        </div>
      </div>
    );
}