import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import Swiper from "react-id-swiper";
import { getProductById, sanitizeImageURL } from "../../api/api";
import { Button, Image } from "react-bootstrap";

const URL_TESTER = /(http|https):\/\/.*/

function getImages(product) {
  if (product) {
    let image = sanitizeImageURL(product.image)    
    const images = [image]
    if (product.gallery_images) {
      images.push(...product.gallery_images.map(gallery => sanitizeImageURL(gallery.image) ))
    }
    return images
  }
  return []
}

const ProductImageGallery = ({ product, containerSize, hideZoomButton, hideThumbnailSwiper, onSlideChange}) => {
  const [gallerySwiper, getGallerySwiper] = useState(null);
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null);

  // effect for swiper slider synchronize
  useEffect(() => {
    if (
      gallerySwiper !== null &&
      gallerySwiper.controller &&
      thumbnailSwiper !== null &&
      thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;

      if (thumbnailSwiper) {
        thumbnailSwiper.on("click", (event) => {
          //console.log('thumbnailSwiper click', event)
          if (gallerySwiper) {
            try {              
              //console.log('gallerySwiper.slideTo', gallerySwiper)
              if (thumbnailSwiper.clickedIndex) {
                //console.log('gallerySwiper.slideTo', thumbnailSwiper.clickedIndex)
                gallerySwiper.slideTo(thumbnailSwiper.clickedIndex, 300, true)            
              }
            } catch(e){
              console.log('gallerySwiper', e)
            }
          }
        })
      }

      if (gallerySwiper) {
        gallerySwiper.on("slideChange", (event) => {
          //console.log('gallerySwiper slideChange', gallerySwiper.activeIndex)
          if (onSlideChange) {
            onSlideChange(gallerySwiper.activeIndex)
          }
        })
      }

    }


  }, [gallerySwiper, thumbnailSwiper]);

  // swiper slider settings
  const gallerySwiperParams = {
    getSwiper: getGallerySwiper,
    spaceBetween: 10,    
    loop: false,
    effect: "fade",        
  };

  const thumbnailSwiperParams = {
    getSwiper: getThumbnailSwiper,
    spaceBetween: 10,
    slidesPerView: 4,
    loopedSlides: 4,
    touchRatio: 0.2,
    freeMode: true,
    loop: false,
    slideToClickedSlide: true,    
    //slidesPerGroupSkip:0,
    //preventInteractionOnTransition: true,

    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom"
    },
    renderPrevButton: () => <button className="swiper-button-prev-custom">
      <Image src="/assets/icons/FLECHA-IZQ.png" style={{height:30, width:'auto'}}/>
    </button>,
    renderNextButton: () => <button className="swiper-button-next-custom">
      <Image src="/assets/icons/FLECHA-DER.png" style={{height:30, width:'auto'}} />
    </button>,
    breakpoints: {
      1024: {
        slidesPerView: 3,
        slidesPerGroup:1,
        spaceBetween: 40
      },
      768: {
        slidesPerView: 3,
        slidesPerGroup:1,
        spaceBetween: 30
      },
      640: {
        slidesPerView: 2,
        slidesPerGroup:1,
        spaceBetween: 20
      },
      320: {
        slidesPerView: 1,
        slidesPerGroup:1,
        spaceBetween: 10
      }
    }
  };

  useEffect(() => {
    if (!product.gallery_images){
      getProductById(product.id).then(response => {
        if (response.status === 200) {
            const details = response.data
            product.gallery_images = details.gallery_images
        }
      })
    }
  }, [product])

  const images = getImages(product)
  return (
    <Fragment>
      <div className="product-large-image-wrapper">
        {product.discount || product.new ? (
          <div className="product-img-badges">
            {product.discount ? (
              <span className="pink">-{product.discount}%</span>
            ) : (
              ""
            )}
            {product.new ? <span className="purple">New</span> : ""}
          </div>
        ) : (
          ""
        )}
        <LightgalleryProvider>
          <Swiper {...gallerySwiperParams} key={'gallery'+images.length}>
            {images &&
              images.map((single, key) => {
                return (
                  <div key={key}
                    style={{height: containerSize, width:'auto', backgroundColor:'white', borderRadious:'20px'}}
                    >
                      {
                        !hideZoomButton && (
                          <LightgalleryItem
                            group="any"
                            src={single}
                            itemClassName = 'galleryItemExpand'
                          >
                            <button>
                              <i className="pe-7s-expand1"></i>
                            </button>
                          </LightgalleryItem>
                        )
                      }
                    <div className="single-image">
                      <img
                        src={single}
                        className="img-fluid"
                        alt=""
                        style={{objectFit: 'contain', height: containerSize, width: 'auto'}}
                      />
                    </div>
                  </div>
                );
              })}
          </Swiper>
        </LightgalleryProvider>
      </div>
      <div className="product-small-image-wrapper mt-15"
        style={{...(hideThumbnailSwiper? {height:0, overflow:'hidden'}: {})}}>
        <Swiper {...thumbnailSwiperParams} key={'thumb'+images.length}>
          {images &&
              images.map((single, key) => {
              return (
                <div key={key} style={{height:100, width:'auto'}}>
                  <div className="single-image">
                    <img
                      src={single}
                      alt={product.name}
                      style={{objectFit: 'contain', height:100, width: 'auto'}}
                    />
                  </div>
                </div>
              );
            })}
        </Swiper>
      </div>
    </Fragment>
  );
};

ProductImageGallery.propTypes = {
  product: PropTypes.object
};

export default ProductImageGallery;
