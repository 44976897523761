import React, { Component, Fragment } from "react";
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Container, Dropdown, Row, Col as div, Image, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { multilanguage } from "redux-multilanguage";
import { getSectionsToMenu } from "../../api/api";
import TopMenuItemCart from "./TopMenuItemCart";
import TopMenuItemAccount from "./TopMenuItemAccount";
import Search from "./Search";
import TopMenuItemWishlist from "./TopMenuItemWishlist";
import { Link } from "react-router-dom";
import { renderDisegnerSections } from "../shared/DesignerUtils";
import TopMenuItemSearch from "./TopMenuItemSearch";
import DropDownMenu from "../features/DropdownMenu";
import DropdownMenuSection from "../features/DropdownMenuSection";

class Header extends Component {

    constructor(props) {
        super(props)
        this.state = {     
            loading: true,
            showSearchBar: false      
        }
    }

    componentDidMount() {        
        setTimeout(() => {
            this.setState({loading:false})
        }, 2000);
    }

    createMenuOption(option, url, order = 0) {
        return {
            option,
            url: url ? url:option,
            order
        }
    }

    renderNavBarSections() {
        return this.renderSectionsByType('navbar-menu')
    }

    renderWidgetSections() {
        return this.renderSectionsByType('widget-section')
    }

    renderWidgetSections() {
        return this.renderSectionsByType('floating-section')
    }

    renderSectionsByType(sectionType) {
        const {designer} = this.props
        // console.log('headerDesigner', designer)
        if (designer && designer.sections) {
            let navbarMenus = []
            let sections = designer.sections
            for (const section of sections) {
                navbarMenus.push(...this.getNavBarSections(section, sectionType))
            }  
            // console.log('navbarMenus', navbarMenus)          
            return renderDisegnerSections({sections: navbarMenus})            
        }
    }

    getNavBarSections(section, sectionType) {
        //console.log('getNavBarSections', section, sectionType)
        let navbarMenus = []
        if (section.sections && section.sections.length > 0){
            for (const sec of section.sections) {
                navbarMenus.push(...this.getNavBarSections(sec, sectionType))
            }
        } else {
            if (section.otype.slug === sectionType) {
                navbarMenus.push(section)
            }
        }
        return navbarMenus        
    }

    createLinksFromDesigner(designer) {
        let links = []
        let header = designer.sections.find(it => it.otype.slug === "header")
        if (header) {
            let validElements = header.elements.filter(it => !it.deleted)
            validElements.forEach( it => {
                if (it.otype.slug === 'menu-item'
                    || it.otype.slug === 'menu-dropdown-item'
                    || it.otype.slug === 'menu-section'
                    ) {
                    links.push(
                        {
                            option:it.link_text, 
                            url: it.link,
                            component: it
                        }
                    )
                }
            })
        }
        return links
    }

    render(){
        const {strings, general, master, shop_configs, customSections, designer} = this.props
        const {loading} = this.state
        
        let logo = general ? general.logo : null
        let brand = general ? general.page_title: ''
        let renderHeader = null
        if (designer) {
            renderHeader = (
                <Container fluid>
                    <Row noGutters>
                    {
                        this.renderNavBarSections()
                    }
                        <div className="brand-icon-movil">
                            <img
                                alt="brand"
                                src={logo}
                                style={{ height: '20px', width: 'auto' }}
                                className="d-inline-block align-top"
                            />
                        </div>
                        <div className="widget-icon-movil">
                        {
                            this.renderWidgetSections()
                        }
                        </div>
                    </Row>
                </Container>
            )            
        } else {
            if (general) {
                let links = []                    
                for(let property in general) {                
                    if (/.*_menu_text/.test(property)) {
                        const propertyBand = property.substring(0, property.indexOf('_menu_text'))                    
                        if (general[propertyBand]) {
                            if (propertyBand==='blog')
                                links.push(this.createMenuOption(general[property], `/${propertyBand}`, 9))                        
                            else 
                                links.push(this.createMenuOption(general[property], `/${propertyBand}`))                        
                        } 
                    }
                }
    
                if (shop_configs && shop_configs.trade) {
                    links.push(this.createMenuOption("Directorio", `/directorio`))                        
                    links.push(this.createMenuOption("Comercio", `/trade`))                        
                }
                
                const menuLinks = []
                if (links) {
                    let linksOrdered = links.sort((a,b) => a.order - b.order)
                    linksOrdered.forEach((link, index) => {                    
                        return(
                            menuLinks.push(
                                <Nav.Link 
                                    key={index} 
                                    as={Link} 
                                    to={link.url} 
                                    style={{backgroundColor:'white', color:'#262626', paddingLeft:10, borderBottom:'#666666 solid 0.5px'}}                        
                                    >{link.option}</Nav.Link>
                            )                    
                        )
                    })
                }
    
    
                renderHeader = (
                    <Navbar bg="navbar" fixed="top" sticky="top" expand="lg" collapseOnSelect
                        className="nav-panel nav-panel-mobile header-mobile-links"
                        style={{ fontSize: 20 }}>                    
                        <div style={{width:'100%', display:'flex', position:'relative', alignItems:'center'}}>                    
                            <div className="absolute-left">
                                <Navbar.Toggle aria-controls="basic-navbar-nav" style={{border: 0}} as={div}/>
                            </div>
                            <div className="content-center" style={{flexGrow:1}}>
                                {
                                    logo ?                                    
                                        <Navbar.Brand className="content-center" as={Link} to="/"
                                            style={{margin:5}}>
                                            <img
                                                alt="brand"
                                                src={logo}
                                                style={{ height: '20px', width: 'auto'}}
                                                className="d-inline-block align-top"
                                            />
                                        </Navbar.Brand>
                                        :
                                        <Navbar.Brand as={Link} to="/" style={{ fontSize: 40 }}>{brand}</Navbar.Brand>
                                }
                            </div>
                            <div className="absolute-right">
                                <Row noGutters style={{ justifyContent: 'flex-end'}}>
                                    {
                                        master && master.shop && (
                                            <TopMenuItemSearch onClick={e => this.onSearchPress()}/>
                                            )
                                    }
                                    {
                                        general && general.shop && (
                                            <Fragment>
                                                <TopMenuItemWishlist />
                                                <TopMenuItemCart />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        master && master.crm_config && master.crm_config.web_sign_up && (
                                            <TopMenuItemAccount />
                                        )
                                    }                            
                                </Row>
                            </div>
                        </div>                    
                        
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto header-mobile-links">
                                {
                                    (!loading ) && menuLinks
                                }
                            </Nav>
    
                        </Navbar.Collapse>
                    </Navbar>
                )
            }
        }
        


        return (
            <div id="headerWithNavBar" style={{ position: '-webkit-sticky', position: 'sticky', top: 0, zIndex: 999 }}>
                {
                    renderHeader
                }
                
                {
                    this.state.showSearchBar && (
                        <Container fluid >
                            <Row >
                                <Col style={{backgroundColor:'white'}}>
                                {
                                    <Search context="header" />
                                }
                                </Col>
                            </Row>
                        </Container>
                    )
                }
            </div>
        )
    }

    onSearchPress() {
        this.setState({
            showSearchBar: !this.state.showSearchBar
        })
    }
}

const mapStateToProps = state => {
    return {
        general: state.general.general,
        master: state.master.master,
        shop_configs: state.shop.shop_configs
    }
}

export default connect(mapStateToProps)(multilanguage(Header))