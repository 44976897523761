import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy, Component } from "react";
import ScrollToTop from "./helpers/scroll-top";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect, useSelector } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { ToastContainer } from "react-toastify";

import { getmenucategorias } from "./store/menu/menuActions";
import { getmaster } from "./store/master/masterActions";
import { getGeneral } from "./store/general/generalActions";
import { HelmetProvider, Helmet } from "react-helmet-async";

//Analytics
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import Layout from "./layouts/Layout";

import 'antd/dist/antd.css'; 

// home pages
//const HomeGridBanner = lazy(() => import("./pages/home/HomeGridBanner"));
const HomeOne = lazy(() => import("./pages/home/HomeOne"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const Catalog = lazy(() => import("./pages/catalog"));
const Finder = lazy(() => import("./pages/finder"));
const ShopProduct = lazy(() => import("./pages/shop/ShopProduct"));
const ShopPageCart = lazy(() => import("./pages/shop/ShopPageCart"));
const ShopPageCart2 = lazy(() => import("./pages/shop/checkout/ShopPageCart2"));
const ShopPageWishlist = lazy(() => import("./pages/shop/ShopPageWishlist"));
const ShopPageCheckout = lazy(() => import("./pages/shop/ShopPageCheckout"));
const ShopPageCheckout2 = lazy(() =>
  import("./pages/shop/checkout/ShopPageCheckout2")
);
const ShopPageOrderResult = lazy(() =>
  import("./pages/shop/ShopPageOrderResult")
);
const LoginPage = lazy(() => import("./pages/account/LoginPage"));
const RegisterPage = lazy(() => import("./pages/account/RegisterPage"));
const AccountLayout = lazy(() => import("./pages/account/AccountLayout"));
const SectionPage = lazy(() => import("./pages/sections/SectionPage"));
const Pages = lazy(() => import("./pages/sections/Pages.jsx"));
const BlogPage = lazy(() => import("./pages/blog/BlogPage"));
const BlogDetailsPage = lazy(() => import("./pages/blog/BlogDetailsPage"));
const SitePageAboutUs = lazy(() => import("./pages/other/SitePageAboutUs"));
const SitePageContact = lazy(() => import("./pages/other/SitePageContact"));
const SitePagePrivacy = lazy(() => import("./pages/other/SitePagePrivacy"));
const SitePageOffices = lazy(() => import("./pages/other/SitePageOffices"));
const SitePageTerms = lazy(() => import("./pages/other/SitePageTerms"));
const CategopryProfile = lazy(() => import("./pages/category_profile/index"));
const Trade = lazy(() => import("./pages/trade"));
const MasterDirectory = lazy(() => import("./pages/master_directory"));
const MasterProfileDirectory = lazy(() =>
  import("./pages/master_directory/profile")
);
const ProductProfileSelector = lazy(() =>
  import("./pages/shop/ProductProfileSelector")
);
const AnnouncementPage = lazy(() => import("./pages/trade/AnnouncementPage"));
const ShopPageSharedLinkAnalizer = lazy(() =>
  import("./pages/shop/ShopPageSharedLinkAnalizer")
);
const CustomPage = lazy(() => import("./pages/custom/index"));
const PasswordRecoveryByEmail = lazy(() =>
  import("./pages/account/PasswordRecoveryByEmail")
);
const AccountValidation = lazy(() =>
  import("./pages/account/AccountValidation")
);
const Plans = lazy(() => import("./pages/plans/Plans"));
const PlansCheckout = lazy(() => import("./pages/plans/ShopPageCheckout"));
const KenworthConfigurator = lazy(() => import("./pages/CustomClients/KenworthConfigurator"));

const keyFormat = /^[a-zA-Z0-9_-]*$/;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reactGaInitialised: false,
    };
  }

  componentDidMount() {
    //console.log('aPP coponentDidMount', this.props)

    this.props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          es: require("./translations/spanish.json"),
        },
      })
    );

    this.props.getmaster();
    this.props.getGeneral();
    this.props.getmenucategorias();

    setTimeout(() => {
      this.setupConfigs();
    }, 2000);
  }

  setupConfigs() {
    const { general } = this.props;
    if (general) {
      this.initGoogleAnalytics(general);
      this.initFacebookPixel(general);
      this.initTawk(general);
      this.initJivoChat(general);
    } else {
      setTimeout(() => {
        this.setupConfigs();
      }, 100);
    }
  }

  render() {
    const { general, master } = this.props;
    let favicon = general ? general.favicon : null;
    return (
      <HelmetProvider>
        <Helmet>
          {master && master.google_maps_api ? (
            <script
              type="text/javascript"
              src={`https://maps.googleapis.com/maps/api/js?key=${master.google_maps_api}&libraries=places`}
            ></script>
          ) : null}

          {favicon != null ? <link rel="shortcut icon" href={favicon} /> : ""}
          {favicon != null ? (
            <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
          ) : (
            ""
          )}
          {favicon != null ? (
            <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
          ) : (
            ""
          )}
          {favicon != null ? (
            <link rel="apple-touch-icon" sizes="180x180" href={favicon} />
          ) : (
            ""
          )}
          {general ? <title>{general.page_title}</title> : null}
          {general && general.meta_description ? (
            <meta name="description" content={general.meta_description}></meta>
          ) : null}
          {general && general.meta_keywords ? (
            <meta name="keywords" content={general.meta_keywords}></meta>
          ) : null}

          {
            //  Social Tags
          }
          {favicon ? <meta property="og:image" content={favicon} /> : null}
          {general ? (
            <meta property="og:title" content={general.page_title}></meta>
          ) : null}
          {general ? (
            <meta property="og:description" content={general.page_title}></meta>
          ) : null}
          <meta property="og:url" content={window.location.href}></meta>
          <meta property="og:type" content="article"></meta>

          {general && (
            <style type="text/css">{`
                            body {
                              font-family: ${
                                general.font_name ? general.font_name : ""
                              };
                            }

                            @media screen only and (max-width:800px) and (min-width:300px){
                              .col-5-lists {
                                flex: 0 0 18.66667% !important;
                                max-width: 18.66667% !important;
                              }
                            }

                            /* Used in Footer and others different to list elements */
                            .col-5 {
                              margin-left: 3.166666667%;
                            }
                        
                            .primary_color_background {
                                background-color: #${general.color_primary};
                                color: #${general.color_primary_letters};
                            }

                            .color_primary_letter_important a {                                
                                color: #${
                                  general.color_primary_letters
                                } !important;
                            }

                            .secondary_color_background {
                                background-color: #${general.color_secondary};
                                color: #${
                                  general.color_primary_letters
                                };                                
                            }
    
                            .primary_color_font {
                              color: #${general.color_primary}
                            }

                            .secondary_color_font {
                              color: #${general.color_secondary}
                            }

                            .menu_color_font {
                              color: #${general.color_menu_fonts} !important;
                            }
                                                    
                            .btn-primary, btn-primary:disabled {
                                background-color: #${
                                  general.color_primary
                                } !important;
                                border-color: #${
                                  general.color_primary
                                } !important;
                                color: #${general.color_primary_letters};
                            }

                            .btn-outline-primary, .btn-outline-primary:disabled {
                                color: #${general.color_primary} !important;
                                border-color: #${
                                  general.color_primary
                                } !important;
                            }
    
                            .account-nav__item--active a {
                                border-color: #${general.color_primary};
                                border-left-color: #${general.color_primary};
                            }
    
                            .address-card__badge {
                                background-color: #${general.color_primary};
                            }
    
                            .order-success__icon {
                                fill: #${general.color_primary};
                            }
    
                            a {
                                color: #${general.color_primary};
                            }

                            a:hover {
                                color: #${general.color_secondary};
                            }
    
                            .layout-switcher__button--active,
                            .layout-switcher__button--active:hover {
                                border-color: #${general.color_primary};
                                background: #${general.color_primary};
                            }
                                
                            .page-item.active .page-link {
                                background: #${
                                  general.color_primary
                                } !important;
                                border-color: #${
                                  general.color_primary
                                } !important;
                                color: #${
                                  general.color_primary_letters
                                } !important;
                            }
    
                            .page-link {                        
                              color: #${general.color_primary};
                              background: #${general.color_primary_letters};
                            }
    
                            .nav-panel {                                        
                                background: #${general.color_header};
                                // color: #${
                                  general.color_menu_fonts
                                } !important;
                            }

                            #headerWithNavBar {                                        
                                background: #${
                                  general.color_header
                                };                                
                            }
    
                            .nav-panel-icon {                                                                    
                                // color: #${general.color_menu_fonts};
                                padding: 0.1rem;
                                max-width: 80px;
                                max-height: 80px;
                                height: 100%;
                                width: auto;
                            }

                            .header-links .nav-link {
                              font-size: 20px;
                            }

                            .nav-link.active {
                              color: #${general.color_secondary} !important;
                              text-decoration: underline;                              
                              font-weight: bold;
                            }
    
                            .header-links .nav-link {                                                                    
                                // color: #${
                                  general.color_menu_fonts
                                } !important;
                                font-weight: 300;
                            }

                            .header-links .nav-link:hover {                                                                                                    
                                font-weight: 600;
                                color: #${general.color_primary} !important;
                            }

                            .header-mobile-links .nav-link {                                                                    
                                color: #${
                                  general.color_menu_fonts
                                };                            
                            }
    
                            .nav-panel .navbar-brand {                                                                    
                                color: #${
                                  general.color_menu_fonts
                                } !important;                            
                            }
    
                            .containerFooter{
                                background-color: #${
                                  general.color_footer_primary
                                };
                                color: #${
                                  general.color_primary_letters
                                };        
                                padding-top: 30px;                                                        
                            }
    
                            .containerFooter .card-link {
                              color: #${
                                general.color_primary_letters
                              } !important;
                              font-weight: 300;
                            }

                            .containerFooter a {
                              color: #${
                                general.color_primary_letters
                              };        
                            }
    
                            .mobile-header__panel {                                        
                                background: #${general.color_primary};
                                color: #${
                                  general.color_menu_fonts
                                };                                        
                            }
    
                            .block-features__item {                                        
                                border-color: #${general.color_primary};
                                color: #${general.color_primary};
                            }
                            
                            .block-features__icon {
                                fill: #${general.color_primary};
                            }
    
                            .product-tabs__item--active {
                                border-bottom-color: #${
                                  general.color_primary
                                };                                        
                            }
    
                            .product-tabs__item--active:hover {
                                border-bottom-color: #${
                                  general.color_primary
                                };                                        
                            }
    
                            .searcher-input-header, 
                            .searcher-input-header:focus {
                              // color: #${general.color_menu_fonts};
                            }

                            .searcher-input-header:focus {                              
                              box-shadow: none;
                            }
    
                            .searcher-container {
                              // border: 1px solid #${general.color_menu_fonts};
                              padding: 0px;
                              border-radius: 5px;
                            }
    
                            .searcher-container ::placeholder {
                              // color: #${general.color_menu_fonts};
                              opacity: 0.5;
                            }
    
                            .product__discount,
                            .product-card__new-price {
                                color: #${general.color_primary};
                            } 
                           
                            .customToggleButton.active .withBorder {
                              border: 1px solid #${general.color_primary};
                              box-shadow: inset 0px 0px 0px 4px #${
                                general.color_primary
                              };
                              color: black;
                            }

                            .customToggleButton.active .withBorderOnlyInActive {
                              // border: 1px solid #${general.color_primary};
                              box-shadow: inset 0px 0px 0px 4px #${
                                general.color_primary
                              };
                              color: black;  
                            }

                            .customToggleButton.active .withColorPrimary {                              
                              color: #${general.color_primary};  
                              font-weight: 900;                              
                            }
                            
                            .modern-link {
                              color: black;
                            }

                            .modern-link:hover {
                              color: #${general.color_primary};
                              text-decoration: none;
                            }

                            .card-thumbnail .btn:hover {
                              background-color: #${
                                general.color_secondary
                              } !important;                              
                            }

                            .MuiDrawer-paper .form-control {
                              border-radius: 2;
                              border: 1px solid #${general.color_primary};
                            }

                            .MuiDrawer-paper  {
                              color: #${general.color_menu_fonts};
                            }

                            .plan-card {
                              border: 0px;
                              border-radius: 20px;
                              box-shadow: 1px 8px 24px 4px rgb(86 91 115 / 15%);
                              margin: 10px 0px;
                            }

                            .plan-card-selected {
                              border: 3px solid #${general.color_primary};
                            }

                            .plan-card .card-header {
                              background-color: #${general.color_secondary};
                              border-radius: 17px 17px 0px 0px;
                              text-align: center;
                              font-size: 40px !important;
                              padding: 30px;
                            }
                            .plan-card-selected .card-header {
                              background-color: #${general.color_primary};
                            }

                            .plan-card .card-header .card-title {
                              font-weight: 900;
                              font-size: 30px;
                            }

                            .plan-card .card-body {
                              background: #FFFFFF;
                              border-radius: 0px 0px 20px 20px;
                            }

                            .plan-card-body {
                              background: #FFFFFF;
                            }

                            .plan-card .prices {
                              color: #${general.color_primary};
                            }

                            .plan-card .term {
                              color: #${general.color_primary};
                            }

                            .admin-access-button {
                              background-color: #${general.color_primary};
                              font-size: 12px;
                              text-align: center;
                            }

                            .admin-access-button a {                              
                              color: white;
                            }
                        
                        `}</style>
          )}
        </Helmet>

        <ToastProvider placement="bottom-left">
          <ToastContainer autoClose={5000} hideProgressBar />
          <BreadcrumbsProvider>
            <Router>
              <ScrollToTop>
                <Suspense
                  fallback={
                    <div className="flone-preloader-wrapper">
                      <div className="flone-preloader">
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  }
                >
                  <Layout reactGaInitialised={this.state.reactGaInitialised}>
                    <Switch>
                      <Route
                        exact
                        path={process.env.PUBLIC_URL + "/"}
                        component={HomeOne}
                      />
                      <Route
                        exact
                        path={"/recovery/UID/:UID/"}
                        component={PasswordRecoveryByEmail}
                      />
                      <Route
                        exact
                        path={"/web/account-validation/:UID/"}
                        component={AccountValidation}
                      />
                      <Route exact path={"/login"} component={LoginPage} />
                      <Route
                        exact
                        path={"/register"}
                        component={RegisterPage}
                      />
                      <Route path={"/account"} component={AccountLayout} />
                      {/* <Route
                          exact
                          path={"/products"}
                          component={Catalog}
                        /> */}
                      <Route exact path={"/products"} component={Finder} />
                      <Route exact path={"/catalog"} component={Finder} />
                      <Route path={"/trade/:categorySlug"} component={Trade} />
                      <Route exact path={"/trade"} component={Trade} />
                      <Route
                        exact
                        path={"/directorio"}
                        component={MasterDirectory}
                      />
                      <Route
                        exact
                        path={"/directorio/:providerSlug"}
                        component={MasterProfileDirectory}
                      />
                      {/* <Route
                          exact
                          path="/shop/category/:categorySlug"
                          component={Catalog}
                        /> */}
                      <Route
                        path="/shop/category/:categorySlug"
                        component={Finder}
                      />
                      <Route
                        path="/shop/product/:productId"
                        component={ProductProfileSelector}
                      />
                      <Route
                        exact
                        path="/shop/ecommerce/product/:productId"
                        component={ShopProduct}
                      />
                      {/* <Route
                          exact
                          path="/shop/cart"
                          component={ShopPageCart}
                        /> */}
                      <Route
                        exact
                        path="/shop/cart"
                        component={ShopPageCart2}
                      />
                      <Route
                        exact
                        path="/shop/wishlist"
                        component={ShopPageWishlist}
                      />
                      <Route
                        exact
                        path="/shop/checkout"
                        component={ShopPageCheckout2}
                      />
                      <Route
                        exact
                        path="/shop/checkout/:status/:orderId"
                        component={ShopPageOrderResult}
                      />
                      <Route
                        exact
                        path="/nosotros"
                        component={SitePageAboutUs}
                      />
                      <Route
                        exact
                        path="/contacto"
                        component={SitePageContact}
                      />
                      <Route
                        exact
                        path="/offices/"
                        component={SitePageOffices}
                      />
                      <Route
                        exact
                        path="/sucursales/"
                        component={SitePageOffices}
                      />
                      <Route
                        exact
                        path="/section/:sectionSlug/"
                        component={SectionPage}
                      />
                      <Route exact path="/page/:pageSlug/" component={Pages} />
                      <Route exact path="/blog" component={BlogPage} />
                      <Route
                        exact
                        path="/blog/:categorySlug"
                        component={BlogPage}
                      />
                      <Route
                        exact
                        path="/blog/post/:slug"
                        component={BlogDetailsPage}
                      />
                      <Route
                        exact
                        path="/aviso-de-privacidad"
                        component={SitePagePrivacy}
                      />
                      <Route
                        exact
                        path="/terminos-condiciones"
                        component={SitePageTerms}
                      />
                      <Route
                        exact
                        path="/anuncio/:productId"
                        component={AnnouncementPage}
                      />
                      <Route
                        exact
                        path="/pages/:pageSlug"
                        component={CustomPage}
                      />
                      <Route exact path="/plans/" component={Plans} />
                      <Route
                        exact
                        path="/plans/checkout/"
                        component={PlansCheckout}
                      />
                      <Route
                        exact
                        path="/configurador-kenworth"
                        component={KenworthConfigurator}
                      />
                      <Redirect from="/catalogo" to="/catalog" />
                      <Redirect from="/catálogo" to="/catalog" />
                      <Redirect from="/shop" to="/products" />
                      <Redirect from="/cart" to="/shop/cart" />
                      <Redirect from="/aboutus" to="/nosotros" />
                      <Redirect from="/contact" to="/contacto" />
                      <Redirect from="/configurador-kenworth" to="/configurador-kenworth" />
                      <Route
                        exact
                        from="/commons/redirect"
                        component={ShopPageSharedLinkAnalizer}
                      />
                      a
                      <Route exact component={NotFound} />
                    </Switch>
                  </Layout>
                </Suspense>
              </ScrollToTop>
            </Router>
          </BreadcrumbsProvider>
        </ToastProvider>
      </HelmetProvider>
    );
  }

  initGoogleAnalytics(generalData) {
    const { google_webmaster_verification, google_analytics } = generalData
      ? generalData
      : {};
    if (google_analytics && keyFormat.test(google_analytics)) {
      ReactGA.initialize(google_analytics, {
        debug: false,
      });
      this.setState({ reactGaInitialised: true });
    } else {
      // console.log("Google analytics is not configured.", google_analytics);
    }

    if (
      google_webmaster_verification &&
      keyFormat.test(google_webmaster_verification)
    ) {
      TagManager.initialize({
        gtmId: google_webmaster_verification,
      });
    } else {
      // console.log("Google Tag Manager is not configured.");
    }
  }

  initFacebookPixel(generalData) {
    // console.log("initFacebookPixel", generalData);
    const { facebook_pixel } = generalData;
    if (facebook_pixel && keyFormat.test(facebook_pixel)) {
      ReactPixel.init(
        facebook_pixel,
        {},
        {
          autoConfig: true, // set pixel's autoConfig
          debug: true, // enable logs
        }
      );
    } else {
      console.warn("Facebook Pixel Inválido");
    }
  }

  initJivoChat(generalData) {
    try{
        const {jivochat_code} = generalData;
        // console.log("jivochat_code,",generalData.jivochat_code)
        const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = `${jivochat_code}`;
            document.head.appendChild(script);
    }catch(e){
        console.log("error",e)
    }
  }

  initTawk(generalData) {
    try {
      const { twak_code } = generalData;
      if (twak_code) {
        let tawkScript = twak_code;
        if (keyFormat.test(twak_code)) {
          tawkScript = `            
                    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                    (function(){
                    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                    s1.async=true;
                    s1.src='https://embed.tawk.to/${twak_code}/default';
                    s1.charset='UTF-8';
                    s1.setAttribute('crossorigin','*');
                    s0.parentNode.insertBefore(s1,s0);
                    })();                
                `;
        } else {
          let Script_begin = '<script type="text/javascript">';
          let Script_end = "</script>";
          if (twak_code.includes(Script_begin)) {
            let indexOfBegin =
              twak_code.indexOf(Script_begin) + Script_begin.length;
            let indexOfEnd = twak_code.indexOf(Script_end);
            tawkScript = twak_code.substring(indexOfBegin, indexOfEnd);
          }
        }
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = tawkScript;
        document.body.appendChild(script);
      }
    } catch (error) {
      console.error("Error initTawk", error);
    }
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
};

const mapStateToProps = (state) => ({
  locale: state.locale,
  master: state.master.master,
  general: state.general.general,
});

export default connect(mapStateToProps, {
  getmenucategorias,
  getmaster,
  getGeneral,
})(multilanguage(App));
