import React, { Component, Fragment } from "react";
import { Dropdown, Row, Col, Media, Button, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import TopMenuItem from "./TopMenuItem";
import { cartRemoveItem } from '../../store/cart';
import {doLogout} from '../../store/user/userActions'
import { isMobile } from "../../utils/Utils";
import LoginController from "../../pages/home/components/login/LoginController";


class TopMenuItemCart extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showLoginModal: false,
            isMobile: isMobile()            
        }
    }

    render() {        
        const {login, user, master_user} = this.props
        if (login && user && login.key && login.csrftoken) {
            const links_account = [
                // { title: 'Portada', url: 'dashboard' },
                { title: 'Editar perfil', url: '/account/profile' },            
                { title: 'Historial de compras', url: '/account/orders', 
                    hide: () => (this.props.shop_configs.block_sales)},            
                { title: 'Direcciones', url: '/account/addresses', 
                    hide: () => (this.props.shop_configs.block_sales)},            
            ].map((link) => {                
                if (link.hide && link.hide()){
                    return null
                } else {
                    return (
                    <Dropdown.Item as={Link}  to={link.url}>{link.title}</Dropdown.Item>
                    );
                }
        
            });
            const links_seller = [
                { title: 'Perfil Empresarial', url: '/account/register-master', 
                    hide: () => !(  this.props.master_user 
                                &&  this.props.master_user.id)  },            
                { title: 'Mis Publicaciones', url: '/account/my-products', 
                    hide: () => !(
                           this.props.master_user && this.props.master_user.id) },                                            
                { title: 'Publicar', url: '/account/new-product', 
                           hide: () => !(
                                  this.props.master_user && this.props.master_user.id) },                                            
            ].map((link) => {                
                if (link.hide && link.hide()){
                    return null
                } else {
                    return (
                    <Dropdown.Item as={Link}  to={link.url}>{link.title}</Dropdown.Item>
                    );
                }
        
            });
            const links_about = [
                // { title: 'Portada', url: 'dashboard' },
                { title: 'Políticas de Privacidad', url: '/aviso-de-privacidad/' },            
                { title: 'Preguntas Frecuentes', url: '/pages/preguntas-frecuentes/' },            
            ].map((link) => {                
                if (link.hide && link.hide()){
                    return null
                } else {
                    return (
                    <Dropdown.Item as={Link}  to={link.url}>{link.title}</Dropdown.Item>
                    );
                }
        
            });

            return (
                <TopMenuItem iconComponent={<i className="pe-7s-user" style={{fontSize: "2em"}}/>}>                
                    <Dropdown.Item as={Link}  to='/account'>                        
                        <Media>
                        {
                            user.image_get ? 
                            <Image 
                                width={50}
                                height={50}                                
                                src={user.image_get} 
                                roundedCircle
                                className="align-self-center mr-3"
                                style={{backgroundColor:'lightgray'}}                                
                                />
                                :
                            <div style={{backgroundColor:'lightgray', width:50, height:50, borderRadius:25, marginRight:10}} />
                                
                        }
                            <Media.Body style={{maxWidth:'400px', minWidth:'200px', whiteSpace:'normal'}}>
                                <div>
                                    {user.name}                                
                                </div>
                                <div style={{minWidth:'30%'}}>
                                    {user.email}
                                </div>
                            </Media.Body>
                        </Media>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    {
                        links_account
                    }                    
                    <Dropdown.Divider />
                    {
                        links_seller
                    }
                    <Dropdown.Divider />
                    {
                        links_about
                    }
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={(e) => this.onLogoutPress(e)}>Cerrar Sesión</Dropdown.Item>                                    
                </TopMenuItem>
            )            
        } else {
            return (
                <Fragment>
                    <TopMenuItem 
                        iconComponent={<i className="pe-7s-user" 
                        style={{fontSize: this.state.isMobile ? "1.5em":"2em"}}/>}                        
                        onToggle={(isOpen) => isOpen ? this.setState({ showLoginModal: true }) : null}

                        > 
                                       
                        {/* <Dropdown.Item as={Link} variant='link' onClick={() => this.onLoginPress()}>Login</Dropdown.Item> */}
                        {/* <Dropdown.Item as={Link}  to="/login">Login</Dropdown.Item> */}
                        {/* <Dropdown.Item as={Link}  to="/register">Registro</Dropdown.Item>                 */}                         

                        <LoginController 
                            show={this.state.showLoginModal} 
                            handleClose={() => this.setState({showLoginModal: false})}
                            // asRightSlide
                            // outOfDropDown
                            />

                    </TopMenuItem>


                </Fragment>
            )
        }
    }


    onLogoutPress(event) {                
        event.preventDefault()
        const {login} = this.props
        this.props.doLogout(login)
        setTimeout(() => {
            this.setState({redirect:'/'}, 
                () => this.setState({redirect:null}))
        }, 500);
    }

    onLoginPress() {
        this.setState({showLoginModal: true})
    }

}

const mapStateToProps = (state) => ({
    cart: state.cart,
    login: state.user.login,
    user: state.user.user,
    master_user: state.user.master_user,
    shop_configs: state.shop.shop_configs
});

const mapDispatchToProps = {
    cartRemoveItem,
    doLogout
};


export default connect(mapStateToProps, mapDispatchToProps)(TopMenuItemCart)