import React, { Component, Fragment } from 'react'
import { Container, Carousel, Row } from 'react-bootstrap'
import { getSlides } from '../../api/api';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { isMobile } from '../../utils/Utils';

class Slides extends Component {

    constructor(props){
        super(props);
        this.state={
            slides:[]
        }
    }

    componentDidMount() {
        this.fetchSlides()
    }

    fetchSlides() {
        const {master} = this.props
        if (master && master.master){
            getSlides(master.master.id).then(result =>{
                if (result.status === 200) {
                    this.setState({
                        slides: result.data
                    })
                }
            })
        } else {
            //console.log('getSlides retry')
            setTimeout(() => {
                this.fetchSlides()
            }, 1000);
        }   
    }

    render() {
        const {slides} = this.state
        const {section} = this.props
        let carrouselItems 

        let key=`${(new Date().getTime())}`
        if (section) {            
            key=section.id
        }        
        let sectionClassName = `seccion-${key}`

        if (slides && slides.length > 0) {
            carrouselItems = slides.map(slide => {
                const image = slide.image;
                if (slide.link) {
                    return (
                        <Carousel.Item key={slide.id}>  
                            <a href={slide.link}>
                                <img
                                className="d-block w-100"
                                src={image}
                                alt="First slide"
                                />                        
                            </a>                      
                        </Carousel.Item>
                    )
                } else {
                    return (
                        <Carousel.Item key={slide.id}>                        
                            <img
                            className="d-block w-100"
                            src={image}
                            alt="First slide"
                            />                        
                        </Carousel.Item>
                    )
                }
            })
            return (
                <Fragment>
                    <Helmet>
                        {section && (
                            <style type="text/css">{`

                            .${sectionClassName} {
                                ${section.html_attrs}
                                ${isMobile() ? section.html_attrs_mobile: ''}
                            }

                        `}</style>
                        )}
                    </Helmet>
                    <Container fluid className={sectionClassName}>
                        <Row style={{display:'block'}}>
                            <Carousel>
                                {carrouselItems}
                            </Carousel>
                        </Row>
                    </Container>
                </Fragment>
            )
        } else {
            return null
        }
    }
    
}

const mapStateToProps = (state) => ({
    locale: state.locale,
    master: state.master
});

export default connect(mapStateToProps)(Slides)