import { SET_SHARED_BUY } from "./sharedBuysTypes";

const INITIAL_STATE = {
    product: null,
	product_variable: null,
	quantity: 0
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_SHARED_BUY:
            return {
                ...state,
                product: action.product,
                product_variable: action.product_variable,
                quantity: action.quantity
            };

        default:
            return state;
    }
};
