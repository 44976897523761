import React, { Component } from "react";
import { Dropdown, Row, Col, Media, Button, Spinner, Badge } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import TopMenuItem from "./TopMenuItem";
import { sanitizeImageURL } from "../../api/api";
import { cartRemoveItem } from '../../store/cart';
import Currency from "../shared/Currency";
import { Cross10Svg } from "../../svg";
import AsyncAction from "../shared/AsyncAction";
import { isMobile } from "../../utils/Utils";


class TopMenuItemWishlist extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isMobile: isMobile()
        }
    }

    render() {
        const {wishlist} = this.props
        const {isMobile} = this.state

        let items = (
            <Dropdown.Item eventKey="0">
                La lista de deseos esta vacia.
            </Dropdown.Item>)
        if (wishlist && wishlist.length > 0) {
            items = wishlist.map((item) => {
    
                const removeButton = (
                    <AsyncAction
                        action={() => this.cartRemoveItem(item)}
                        render={({ run, loading }) => {
                            if (loading){
                                return <Spinner animation="grow" size="sm" />
                            } else
                                return (
                                    <Button variant='secondary' onClick={run} >
                                        <Cross10Svg />
                                    </Button>
                                );
                        }}
                    />
                );

                let product = item;
    
                return(
                    <Dropdown.Item eventKey={product.id} onSelect={() => console.log('OnSelect')}>
                        <Media>
                            <Link to={`/shop/product/${product.slug ? product.slug : product.id}`}>
                                <img src={sanitizeImageURL(product.thumbnail_get)} alt="" 
                                    width={64}
                                    height={64}
                                    style={{marginRight:'10px'}}/>
                            </Link>
                            <Media.Body style={{maxWidth:'400px', minWidth:'200px', whiteSpace:'normal'}}>                        
                                <Row>
                                    <Col md={10}>
                                        <div >                                    
                                            <Link to={`/shop/product/${product.slug ? product.slug : product.id}`}
                                                style={{overflowWrap:'break-word', wordWrap:'break-word'}}>
                                                {product.name}</Link>
                                        </div>
                                        <div>
                                            <span className="dropcart__product-quantity">{item.quantity}</span>
                                            {' × '}
                                            <span className="dropcart__product-price"><Currency value={item.price} /></span>
                                        </div>
                                    </Col>
                                    <Col md={2} style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
                                        {removeButton}
                                    </Col>
                                </Row>
                            </Media.Body>
                        </Media>
                    </Dropdown.Item>
                )
            })
        }

        return (
            <div className="dropdown">
                <Button 
                    variant="link"
                    className={isMobile ?"nav-panel-icon-movil": "nav-panel-icon"}
                    // href="/shop/wishlist"
                    as={Link} to={"/shop/wishlist"}
                    >
                        <i className="pe-7s-like" style={{fontSize: isMobile ? "1.5em":"2em"}} />
                        {
                            wishlist && (
                                <div style={{position:'absolute', top:3, left:10, display:'flex'}}>
                                    <Badge variant="secondary"
                                        style={{...(isMobile? {fontSize:'60%'} : {})}}
                                    >{wishlist.length}</Badge>                 
                                </div>  
                            )
                        }
                </Button>
            </div>
        )
    }


    cartRemoveItem(item) {
        const {login} = this.props        
        if (login && login.key) {
            return this.props.cartRemoveItem(item, login)
        } else {
            return this.props.cartRemoveItem(item)            
        }
    }

}

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
    login: state.user.login
});

const mapDispatchToProps = {
    cartRemoveItem,
};


export default connect(mapStateToProps, mapDispatchToProps)(TopMenuItemWishlist)