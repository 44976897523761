// react
import React, { useState, Fragment } from 'react';
import {useSelector} from 'react-redux'
import { ListGroup, ListGroupItem, Card, Form, Button, Col } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { createSubscription } from '../../api/api';
import { toast } from 'react-toastify';

export default function FooterSubscriber() {
    const general = useSelector(state => state.general.general)
    const master = useSelector(state => state.master.master)
    const recaptchaRef = React.useRef();
    const [email, setEmail] = useState("");
    const [resetCaptcha, setResetCaptcha] = useState(false);

    const onSubmitWithReCAPTCHA = (e) => {        
        e.preventDefault()
        const token = recaptchaRef.current.getValue();             
        if (token && email) {
            createSubscription({
                email,
                captation_id:11, //Hardcode
                'g-recaptcha-response': token
            }).then(result => {
                if (result.status === 200) {
                    toast.success(result.data.message)
                    setEmail("")
                    setResetCaptcha(false)
                    recaptchaRef.current.reset()
                } else {
                    let error = "No hay descripción del error"
                    if (result.status === 400) {
                        if (result.data && result.data.non_field_errors) {
                            error = result.data.non_field_errors.join("\n")
                        }
                        toast.warn(error)
                    } else {
                        if (result.data && result.data.non_field_errors) {
                            error = result.data.non_field_errors.join("\n")
                        }
                        toast.error(error)
                    }
                    setResetCaptcha(true)
                }
            })
        } else {
            if (!email) {
                toast.warn('Falta el correo para subscribir.')
            }
            if (!token) {
                toast.warn('Falta marcar la casilla de verificación.')
            }
        }
      }

    if (general && master) {
        const recaptcha_key = master.crm_config ? master.crm_config.recaptcha_key : null
        return (
            <Col md={12}>
                <Card style={{border:'none'}}>
                    <Card.Body style={{padding:0}}>                                    
                        {/* <Card.Title><strong>SUSCRIBETE A </strong></Card.Title>                                     */}
                        <Card.Text style={{fontWeight:'300'}}>
                            Sé el primero en conocer nuestros nuevos diseños, promociones y mucho más.
                        </Card.Text>                    
                        <br />
                    </Card.Body>
                    <Card.Footer bsPrefix='custom'>
                        <Form onSubmit={onSubmitWithReCAPTCHA}>
                            <Form.Group controlId="formBasicEmail">                            
                                <Form.Control type="email"  
                                    style={{backgroundColor:'transparent', border:'none', borderBottom:'1px solid black', borderRadius:0}} 
                                    placeholder="CORREO" 
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    /> 
                            </Form.Group>
                            
                            <div>
                                {
                                    recaptcha_key ?
                                        <div className='content-center'>
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey={recaptcha_key}
                                                onChange={value => console.log('reCaptcha', value)}
                                            />
                                            {
                                            resetCaptcha && (
                                                    <div>
                                                        <Button variant='link' onClick={() => recaptchaRef.current.reset()} >
                                                            <i className="fa fa-refresh" aria-hidden="true"></i>
                                                        </Button>
                                                    </div>
                                            ) 
                                            }
                                        </div>
                                        : null
                                }
                                <Button variant="primary" type="submit" 
                                    style={{width:'100%', color:'black', fontSize:18, borderRadius:0}}>
                                    SUSCRIBIRSE
                                </Button>
                            </div>
                        </Form>
                    </Card.Footer>
                </Card>
            </Col>
        )   
    } else {
        return null
    }
}
