import React, { Fragment } from 'react'
import { Textfit } from 'react-textfit'


export function adapter(custom_item) {
    return ({
        text: custom_item.text,
        style: custom_item.style,
        font: {value: custom_item.font_name},
        upper_color: custom_item.color_1,
        shadow_color: custom_item.color_2,
        position: custom_item.text_position,
        additional: custom_item.additional ? JSON.parse(custom_item.additional) : null
    })
}

export function adapterToShadow(custom_item) {
    return ({
        text: custom_item.text,
        style: custom_item.style,
        font: {value: custom_item.font_name},
        upper_color: custom_item.color_2, 
        position: custom_item.text_position,
        shadow_color: custom_item.color_2, //Only for Desinger purpose           
        additional: custom_item.additional ? JSON.parse(custom_item.additional) : null
    })
}

export const Design = (props) => {
    const {customization, rectSize, showBorder, isShadow} = props
    //console.log('customization', customization)
    let positionStyle = getPositionStyle(rectSize, customization)    
    let barWidth = rectSize * 0.08
    return (
        <div
            style={{
                border: showBorder ? '1px solid gray': 'none',
                lineHeight: 1.0,
                color: `${customization.upper_color}`,
                fontFamily: `${customization.font ? customization.font.value : ''}`,
                ...positionStyle
            }}
        >
            {
                customization.shadow_color && customization.additional 
                && customization.additional.showVerticalBars ? (
                    <Fragment>
                        <div style={{display:'flex', flex:1, justifyContent:'center', marginTop: -0.8 * positionStyle.paddingTop}}>
                            {
                                !isShadow && (
                                    <Fragment>
                                        <div style={{backgroundColor: `${customization.upper_color}`, width:barWidth}}/>
                                        <div style={{backgroundColor: `${customization.shadow_color}`, width:barWidth}}/>
                                    </Fragment>
                                )
                            }
                        </div>

                        <div className="column-100 text-center" style={{minHeight:10, padding:"10px 0px"}}>
                            <Textfit key={new Date().getTime()}
                                mode="single"
                                max={140}
                                className="box box-fat">
                                {customization.text}
                            </Textfit>
                        </div>

                        <div style={{display:'flex', flex:1, justifyContent:'center'}}>
                            {
                                !isShadow && (
                                    <Fragment>
                                        <div style={{backgroundColor: `${customization.upper_color}`, width:barWidth}}/>
                                        <div style={{backgroundColor: `${customization.shadow_color}`, width:barWidth}}/>
                                    </Fragment>
                                )
                            }
                        </div>

                    </Fragment>
                )
                :
                    <div className="column-100 text-center">
                        <Textfit key={new Date().getTime()}
                            mode="single"
                            max={140}
                            className="box box-fat">
                            {customization.text}
                        </Textfit>
                    </div>

            }
               
        </div>
    )
}

function getPositionStyle(rectSize, customization) {    
    let aPercent = 0.85
    let bPercentHorizontal = 0.35
    let bPercentVertical = 0.40
    let cPercent = 0.15
    let style = {
        display: 'flex',
        flexDirection: 'column',
        height: rectSize * aPercent, 
        width: rectSize * bPercentHorizontal,
        paddingTop: rectSize * cPercent, 
    }
    switch (customization.position) {
        case 'centro':
            return ({
                ...style,
                justifyContent: 'center'
            })
        case 'arriba-centro':
            return ({
                ...style,
                justifyContent: 'flex-start'
            })
        case 'abajo-centro':
            return ({
                ...style,
                justifyContent: 'flex-end'
            })
        case 'centro-girado':
            return ({
                ...style,
                justifyContent: 'center',
                transform: 'rotate(270deg)',
                height: rectSize * bPercentVertical, 
                width: rectSize * aPercent,
                paddingTop: 0, 
                paddingRight: rectSize * cPercent
            })
        case 'derecha-girado':
            return ({
                ...style,
                justifyContent: 'flex-end',
                transform: 'rotate(270deg)',
                height: rectSize * bPercentVertical, 
                width: rectSize * aPercent,
                paddingTop: 0, 
                paddingRight: rectSize * cPercent 
            })
    
        default:
            return ({
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            })                
    }
}
