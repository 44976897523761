import React, { Component } from "react";
import { Container, Row, Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import featureIconData from "../../data/feature-icons/feature-icon.json";
import { getColumnClasses } from "../../utils/Utils";

export default class FeatureIcons extends Component {

    render(){
        const {feature} = this.props
        let renderElements = null
        let image = feature.image ? feature.image : feature.image_get ? feature.image_get : null
        if(feature) {
            if (feature.link) {
                renderElements = (
                    <Col key={feature.id} bsPrefix="column"                       
                        className={`featureIcon featureIcon-${feature.id} ${getColumnClasses(feature)}`}                    >
                        <Card className="text-center" style={{ border: 'none' }}>
                            <Link to={feature.link}>
                                <Card.Body style={{ padding: 0, overflow:'hidden'}}>
                                    {
                                        image && (                                            
                                            <div style={{display:'flex', justifyContent:'center'}}>
                                                <Card.Img variant="top" src={image} />
                                            </div>
                                        )
                                    }
                                    <Card.Title style={{fontWeight:'900', fontSize:14 }}>{feature.name}</Card.Title>
                                    <div className='card-text-styled'>
                                        <div dangerouslySetInnerHTML={{__html:feature.description}} />
                                    </div>
                                </Card.Body>
                            </Link>
                        </Card>
                    </Col>
                )
            } else {
                renderElements = (
                    <Col key={feature.id} bsPrefix="column"                       
                        className={`featureIcon featureIcon-${feature.id} ${getColumnClasses(feature)}`}                    >
                        <Card className="text-center" style={{ border: 'none' }}>
                            <Card.Body style={{ padding: 0, overflow:'hidden'}}>
                                {
                                    image && (
                                        <div style={{display:'flex', justifyContent:'center'}}>
                                            <Card.Img variant="top" src={image} />
                                        </div>
                                    )
                                }
                                <Card.Title style={{fontWeight:'900', fontSize:14 }}>{feature.name}</Card.Title>
                                <div className='card-text-styled'>
                                    <div dangerouslySetInnerHTML={{__html:feature.description}} />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                )
            }
        }
        return (renderElements)
    }
}