import React, { useEffect, useState } from 'react'
import { ButtonGroup, Col, Container, Dropdown, Nav, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import { renderSection } from '../shared/DesignerUtils'
import {isMobile as functionIsMobile} from '../../utils/Utils'
import { Link } from "react-router-dom";


const DropdownMenuSection = (props) => {   
   const [show, setShow] = useState(false)
   const [isMobile, setMobile] = useState(false)
   
   useEffect(() => {
      setMobile(functionIsMobile())
   }, [])

   let section = {
      "id": -3,
      "name": "",
      "background_image": null,
      "otype": {
         "id": 2,
         "name": "Sección",
         "slug": "section",
         "description": null,
         "created_at": "2020-07-25T19:53:38Z",
         "updated_at": "2020-07-25T19:53:38Z",
         "active": true
      },
      "css_class": "col-md-12 col-sm-12",      
      "order": 0,
      "elements": [],
      "title": null,
      "description": "",
      "active": true,
      "parent": null,
      "sections": [],
      "has_children": false,
      "api_url": ""
   }

   let thumbnailWidth = 140
   let min_width = thumbnailWidth * 2
   let max_width = thumbnailWidth * 5
   if (props.sub_element) {
      section.elements = props.sub_element
      let subelementsWidth = props.sub_element.length * thumbnailWidth 
      min_width = Math.min(Math.max(subelementsWidth, min_width), max_width)
   }
   if (isMobile) {
      min_width = thumbnailWidth
   }

   const showOverlay = () => {      
      setShow(true)
   }

   const hideOverlay = () => {      
      setShow(false)
   }

   return (
      <OverlayTrigger
         trigger={["hover", "focus"]} //Realmente no sirven
         key={"bottom"}
         placement={"bottom"}         
         show= {show}
         overlay={
            <Popover id={`popover-positioned-bottom`} 
               className="popover-custom"
               style={{minWidth: min_width}}
               onMouseOver={() => showOverlay()} 
               onMouseOut={() => hideOverlay()}
               >
               {/* <Popover.Title as="h3">{`Popover`}</Popover.Title> */}
               <Popover.Content>
                  {
                     renderSection(section)
                  }
               </Popover.Content>
            </Popover>
         }
      >
         <Nav.Link
            as={Link} 
            to={props.link}
            >
            <div onMouseOver={() => showOverlay()} 
               onMouseOut={() => hideOverlay()}>
               {props.link_text}

            </div>
         </Nav.Link>
      </OverlayTrigger>      
   )
}

export default DropdownMenuSection