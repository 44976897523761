import { SwipeableDrawer } from '@material-ui/core';
import React, { Component } from 'react'
import { Modal, Button, Form, Row, Spinner, Col } from 'react-bootstrap';
import FacebookLogin from 'react-facebook-login'

class LoginModal extends Component {

    render() {
        const { show, 
            handleClose, 
            handleOpen,
            facebookAppId,
            login_email,
            login_password,
            processing,
            onSetState,
            onLoginPress,
            onRegisterPress,
            onForgottenPassword,
            responseFacebook
         } = this.props            
        return (
            <SwipeableDrawer
                    anchor="right"
                    open={show}                    
                    onClose={handleClose}
                    onOpen={handleOpen}
                    PaperProps={
                        {style:{minWidth:300, maxWidth:'30%'}}
                    }
                >                    
                        <div className="menu_color_font" >
                            <Row noGutters style={{borderBottom:'1px solid #fafafa'}}>
                                <div style={{fontSize:70}}>
                                    <div className="d-flex" onClick={handleClose}>
                                        <i classaName="pe-7s-close" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <Col className="content-center">                                
                                </Col>
                            </Row>
                        </div>
                        <div className="mx-5 my-4">
                            <span>Se parte de nosotros, obten noticias asi como tambien promociones <span>inica sesión</span> para realizar tu primer compra</span>
                        </div>      

                        <div className="mx-5 mb-5" >
                            <Form onSubmit={(e) => onLoginPress(e)}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label><strong>Email</strong></Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Ingresa tu email"
                                        value={login_email}
                                        onChange={(e) => onSetState({ login_email: e.target.value })}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label><strong>Contraseña</strong></Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Ingresa tu contraseña"
                                        value={login_password}
                                        onChange={(e) => onSetState({ login_password: e.target.value })}
                                    />
                                    {
                                        processing ?
                                            <Button variant='link'>
                                                <Spinner animation="border" size="sm" role="status" />
                                            </Button>
                                            :
                                            null
                                    }
                                </Form.Group>

                                <div className="my-2 content-center-column">
                                    <Button variant="primary" 
                                        type="submit" 
                                        style={{ width:'-webkit-fill-available' }}>
                                        Iniciar sesión
                                    </Button>    

                                    <Button
                                        variant='link'
                                        className="secondary_color_font" 
                                        onClick={(e) => onForgottenPassword(e)}>
                                        Olvidé mi contraseña
                                    </Button>                                
                                </div>
                                
                                <div className="mt-5 mb-3">
                                    {
                                        facebookAppId && (
                                            <FacebookLogin
                                                appId={facebookAppId}
                                                autoLoad={false}
                                                fields="name,email,picture"
                                                scope="public_profile,user_friends"
                                                size="small"
                                                textButton="   Inicia con Facebook"
                                                callback={(response) => responseFacebook(response)}
                                                cssclassaName="btn btn-login-facebook fill-available"
                                                icon="fa-facebook"
                                            />
                                        )
                                    }
                                </div>
                            </Form>
                            <div className="content-center-column">                                
                                <Button 
                                    variant="link" 
                                    className="secondary_color_font" 
                                    onClick={() => onRegisterPress()}>
                                    <strong>Registrarme con mi correo</strong>
                                </Button>
                            </div>
                        </div>                                  
                </SwipeableDrawer>
            
        );    
    }
}


export default LoginModal;