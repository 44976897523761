import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Row, Col } from 'react-bootstrap'
import { sanitizeImageURL } from '../../api/api'
import Rating from '../../components/shared/Rating'
import Currency from '../../components/shared/Currency'
import { Link } from 'react-router-dom'
import { isMobile } from '../../utils/Utils'
import ProductWithOldPrice from './product_renders/ProductWithOldPrice'
import ProductInline from './product_renders/ProductInline'
import ProductWithDiscount from './product_renders/ProductWithDiscount'

class ProductCard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isMobile: isMobile()
        }
    }

    render() {
        const { product, layout } = this.props
        const { isMobile } = this.state

        let pathname = `/shop/product/${product.slug ? product.slug: product.id}`
        let priceComponent= null
        let currentPrice = product.price
        let oldPrice = null
        if (product.price_min > 0) {
            currentPrice = product.price_min
            if (product.price_market && product.price_market > currentPrice) {
                oldPrice = product.price_market
            }
        }

        if (product.price_discount > -1) {
            currentPrice = product.price_discount
            if (product.price > currentPrice) {
                oldPrice = product.price
            }
        }

        if (currentPrice && oldPrice) {
            priceComponent = (
                <div className="product-card__prices">
                    <span className="product-card__new-price"><Currency value={currentPrice} /></span>
                    {' '}
                    <span className="product-card__old-price"><Currency value={oldPrice} /></span>
                </div>
            );
        } else {
            if (currentPrice > 0) {
                priceComponent = (
                    <div className="product-card__prices">
                        <span className="product-card__new-price"><Currency value={currentPrice} /></span>
                    </div>
                );
            } else {
                priceComponent = (
                    <div className="product-card__prices">
                        Consultar
                    </div>
                );
            }
        }

        if (layout === 'list'){
            return ( <ProductInline product={product}/>)
        } else {
                return (
                    <ProductWithDiscount product={product}/>
                    // <ProductWithOldPrice product={product}/>
                )

        }

    }
}


export default connect()(ProductCard)