import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import DesignerStyles from '../components/features/DesignerStyles';
import Header from "../components/header";
import HeaderMobile from "../components/header/indexMobile";
import Footer from "../components/footer";
import {HeaderKenworth} from "../pages/CustomClients/Header/HeaderKenworth";
import { getSectionsToMenu} from "../api/api";
import DefaultDesigner from "./DefaultDesigner.json"
import { getFullTreePagesDesign } from "../components/shared/DesignerUtils";
import { isMobile as functionIsMobile } from "../utils/Utils";

const Layout = ({
  children  
}) => {

  let isMobile = functionIsMobile()
  const [customSections, setCustomSections] = useState([])
  const [footerDesigner, setFooterDesigner] = useState(DefaultDesigner.footer)
  const [headerDesigner, setHeaderDesigner] = useState(isMobile ? null: DefaultDesigner.header)
  const [masterDesigner, setMasterDesigner] = useState(null)
  const pathname = window.location.pathname;

  useEffect(() => {
    getSectionsToMenu().then(result => {
      if (result.status === 200) {
        const customSections = result.data.filter(it => it.menu)
        setCustomSections(customSections)
      }
    }).catch(error => console.error('Error', error))
  }, [])

  useEffect(() => {

    getFullTreePagesDesign('master', isMobile).then(result => {      
      let masterDesign = result.find(it => it.active && it.otype.slug === 'master')
          if (masterDesign) {
            setMasterDesigner(masterDesign)  
            
            if (isMobile && masterDesign.mobile) {
              setHeaderDesigner(masterDesign)
            } else {
              let headerDesign = masterDesign.sections.filter(it => it.otype.slug === "top-bar" || it.otype.slug === "header")          
              if (headerDesign && headerDesign.length > 0) {
                setHeaderDesigner({sections: headerDesign})
              }
            }
  
            let footerDesign = masterDesign.sections.find(it => it.otype.slug === 'footer')
            if (footerDesign && footerDesign.sections) {
              footerDesign.sections.forEach(it => {
                it.isInFooter = true
                it.elements.forEach(element => element.isInFooter = true)
              })
              setFooterDesigner(footerDesign)              
            }
          }        
    })
  }, [])

  return (
    <Fragment>
      <DesignerStyles designer={masterDesigner}/>
      {
        isMobile ?        
        <HeaderMobile 
          designer={headerDesigner} />
        :
        <Header designer={headerDesigner}/> 
      }
      <div style={{flex:1}}>
        {children}
      </div>
      <Footer designer={footerDesigner}/>
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any  
};

export default Layout;
