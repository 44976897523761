import {
    SET_ADDRESSES,
    SET_LOADING,
    SET_ERROR
} from '../types'

import { getUserAddresses } from '../../../api/api';

export const setAddresses = (addresses) => dispatch => 
    dispatch({ type: SET_ADDRESSES, payload: addresses })


export const getAddresses = (userSession) => dispatch => {
    dispatch({ type: SET_LOADING, payload: true })

    getUserAddresses(userSession).then(result => {
        if (result.status == 200) {
            dispatch({ type: SET_LOADING, payload: false })
            dispatch({ type: SET_ADDRESSES, payload: result.data })
        }
    }).catch(error => {
        dispatch({ type: SET_LOADING, payload: false })
        dispatch({ type: SET_ERROR, payload: error })
    })
}