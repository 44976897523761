import React, { Component } from "react";
import { Container, Row, Card, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getColumnClasses } from "../../utils/Utils";

export default class ImageSimple extends Component {

    render(){        
        const {element} = this.props
        let renderElements = null
        if(element) {
            renderElements = (
                <Col key={element.id}                       
                    className={`card-thumbnail card-thumbnail-${element.id} ${getColumnClasses(element)}`} >
                        {
                            element.image && (
                                <Card.Img variant="top" src={element.image} />
                            )
                        }
                </Col>
            )
        }
        return (renderElements)
    }
}