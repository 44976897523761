import React, { Component } from 'react'
import { Modal, Button, Form, Row, Spinner, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom'

import { doLogin, doLoginFB } from '../../../../store/user/userActions'
import { sendRecoveryPassword } from '../../../../api/api'
import LoginModal from './LoginModal';
import LoginSlider from './LoginSlider';
import LoginSubmenu from './LoginSubmenu';

class LoginController extends Component {

    constructor(props) {
        super(props)
        this.state = {
            login_email: '',
            login_password: '',
        }
        this.responseFacebook.bind(this)
    }

    onSetState(entry) {
        this.setState({
            ...entry
        })
    }

    render() {
        const { show, handleClose, asRightSlide, asModal } = this.props
        const {processing, login_email, login_password} = this.state
        const facebookAppId = this.findFacebookAppId()
    
        if (asRightSlide) {
            return (
                <LoginSlider 
                    show={show} 
                    handleClose={handleClose}
                    facebookAppId = {facebookAppId}
                    processing={processing}
                    login_email={login_email}
                    login_password={login_password}
                    onSetState={(entry) => this.onSetState(entry)}
                    onLoginPress={(e) => this.onLoginPress(e)}
                    onForgottenPassword={(e) => this.onForgottenPassword(e)}
                    onRegisterPress={() => this.onRegisterPress()}
                    responseFacebook={(response) => this.responseFacebook(response)}
                />            
            );
        } else if (asModal) {
            return (
                <LoginModal 
                    show={show} 
                    handleClose={handleClose}
                    facebookAppId = {facebookAppId}
                    processing={processing}
                    login_email={login_email}
                    login_password={login_password}
                    onSetState={(entry) => this.onSetState(entry)}
                    onLoginPress={(e) => this.onLoginPress(e)}
                    onForgottenPassword={(e) => this.onForgottenPassword(e)}
                    onRegisterPress={() => this.onRegisterPress()}
                    responseFacebook={(response) => this.responseFacebook(response)}
                />            
            );
        } else {
            return (
                <LoginSubmenu 
                    show={show} 
                    handleClose={handleClose}
                    facebookAppId = {facebookAppId}
                    processing={processing}
                    login_email={login_email}
                    login_password={login_password}
                    onSetState={(entry) => this.onSetState(entry)}
                    onLoginPress={(e) => this.onLoginPress(e)}
                    onForgottenPassword={(e) => this.onForgottenPassword(e)}
                    onRegisterPress={() => this.onRegisterPress()}
                    responseFacebook={(response) => this.responseFacebook(response)}
                />            
            );
        }
    
    }

    onLoginPress(event) {
        if (event)
            event.preventDefault()
        const { login_email, login_password } = this.state
        if (login_email && login_password) {
            this.props.doLogin(login_email, login_password)
            this.props.handleClose()
        } else {
            toast.warn('Ingrese email y contraseña')
        }
    }

    responseFacebook(response) {
        if (response.accessToken) {
            this.props.doLoginFB(response.accessToken);
        } else {
            console.info('No se recibio accesstoken', response)
        }
        this.props.handleClose()
    }

    findFacebookAppId() {
        const { master_configs } = this.props
        if (master_configs && master_configs.socialnetwork_app_set[0]) {
            let facebookConfigs = master_configs.socialnetwork_app_set.find(it => it.provider === 'facebook')
            if (facebookConfigs && facebookConfigs.active) {
                return facebookConfigs.client_id
            }
        }
        return null
    }

    onForgottenPassword(e) {
        e.preventDefault()
        const { login_email } = this.state
        if (login_email) {
            this.setState({processing: true})
            sendRecoveryPassword(login_email).then(result => {
                if (result.status === 200) {
                    toast.success('Se ha enviado un correo de recuperación de contraseña al email indicado.')
                } else {
                    if (result.data && result.data.message) {
                        toast.error(result.data.message)
                    } else {
                        toast.error('No se pudo reenviar el correo de recuperación de contraseña, intente más tarde.')
                    }
                }
                this.setState({processing: false})
            }).catch(error => console.warn('Error sendRecoveryPassword', error))
        } else {
            toast.warn('Ingrese el email de su cuenta.')
        }
    }

    onRegisterPress() {
        this.props.history.push('/register')
        this.props.handleClose()
    }

}

const mapStateToProps = (state) => ({
    master_configs: state.master.master_configs
});

export default withRouter(connect(mapStateToProps, { doLogin, doLoginFB })(LoginController));