import React, { useEffect, useState } from 'react'
import { ButtonGroup, Col, Container, Dropdown, Nav, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import { renderSection } from '../shared/DesignerUtils'
import {isMobile as functionIsMobile} from '../../utils/Utils'
import { Link } from "react-router-dom";


const DropdownMenuCategory = (props) => {   
   const [show, setShow] = useState(false)

   const showOverlay = () => {      
      setShow(true)
   }

   const hideOverlay = () => {      
      setShow(false)
   }

   const {sub_cats} = props
   const categories = sub_cats.map(cat => {
         let sub_categories = []
         if(cat.subCats){
            sub_categories = cat.subCats.map(subCat =>
               (<li classaName="list-group-item">
                  <Link
                  className="text-dark"
                  style={{fontSize:"16px"}}
                  to={`/shop/category/${subCat.slug}/`}
                  >{subCat.name}</Link>
               </li>)
            )
         }
         return  (
                  <li classaName="list-group-item ">
                     <Link
                     className="font-weight-bold"
                     style={{fontSize:"18px"}}
                     to={`/shop/category/${cat.slug}/`}
                     >{cat.name}</Link>
                     <ul classaName="list-group d-flex flex-column flex-wrap">
                        {sub_categories}
                     </ul>
                  </li>
               )
         }
      )

   return (
      <OverlayTrigger
         trigger={["hover", "focus"]} //Realmente no sirven
         key={"bottom"}
         placement={"bottom"}         
         show= {show}
         overlay={
            <Popover id={`popover-positioned-bottom`} 
               className="popover-custom px-5 py-1"
               style={{minWidth: "100%", maxHeight: "500px"}}
               onMouseOver={() => showOverlay()} 
               onMouseOut={() => hideOverlay()}
               >
               <Popover.Content>
                  <ul className="d-flex flex-column flex-wrap" style={{maxHeight:"500px"}}>
                     {categories}
                  </ul>
               </Popover.Content>
            </Popover>
         }
      >
         <Nav.Item
            className="col align-self-center text-center text-light"            
            style={{fontSize:"16px"}}
            as={Link} 
            to={props.link}
            >
            <div onMouseOver={() => showOverlay()} 
               onMouseOut={() => hideOverlay()}>
               {props.link_text}

            </div>
         </Nav.Item>
      </OverlayTrigger>      
   )
}

export default DropdownMenuCategory