// react
import React, { useState, Fragment } from 'react';
import {useSelector} from 'react-redux'
import { ListGroup, ListGroupItem, Card, Form, Button } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { createSubscription } from '../../api/api';
import { toast } from 'react-toastify';

export default function FooterSubscriber() {
    const general = useSelector(state => state.general.general)
    const master = useSelector(state => state.master.master)
    const recaptchaRef = React.useRef();
    const [email, setEmail] = useState("");
    const [resetCaptcha, setResetCaptcha] = useState(false);

    const onSubmitWithReCAPTCHA = (e) => {        
        e.preventDefault()
        const token = recaptchaRef.current.getValue();             
        if (token && email) {
            createSubscription({
                email,
                captation_id:11, //Hardcode
                'g-recaptcha-response': token
            }).then(result => {
                if (result.status === 200) {
                    toast.success(result.data.message)
                    setEmail("")
                    setResetCaptcha(false)
                    recaptchaRef.current.reset()
                } else {
                    let error = "No hay descripción del error"
                    if (result.status === 400) {
                        if (result.data && result.data.non_field_errors) {
                            error = result.data.non_field_errors.join("\n")
                        }
                        toast.warn(error)
                    } else {
                        if (result.data && result.data.non_field_errors) {
                            error = result.data.non_field_errors.join("\n")
                        }
                        toast.error(error)
                    }
                    setResetCaptcha(true)
                }
            })
        } else {
            if (!email) {
                toast.warn('Falta el correo para suscribir.')
            }
            if (!token) {
                toast.warn('Falta marcar la casilla de verificación.')
            }
        }
      }

    if (general && master) {
        const recaptcha_key = master.crm_config ? master.crm_config.recaptcha_key : null
        return (            
            <Card className='' style={{border:'none'}}>
                <Card.Body style={{padding:0}}>                                    
                    <Card.Text style={{fontWeight:'300', color: 'gray', textAlign:'left', fontSize: '1.6rem', lineHeight: '2rem'}}>Suscríbete para recibir nuestro boletín de noticias y promociones</Card.Text>                                        
                    <br />
                </Card.Body>
                <Card.Footer bsPrefix='custom'>
                    <Form onSubmit={onSubmitWithReCAPTCHA}>
                        <Form.Group controlId="formBasicEmail">                            
                            <Form.Control type="email" 
                                style={{border:'1px solid red', borderRadius:0, }} 
                                className='kw-input-subscribe'
                                placeholder="tu correo aquí" 
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                /> 
                        </Form.Group>
                        
                        <div>
                            {
                                recaptcha_key ?
                                    <Fragment>
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            sitekey={recaptcha_key}
                                            onChange={value => console.log('reCaptcha', value)}
                                        />
                                        {
                                           resetCaptcha && (
                                                <div>
                                                    <Button variant='link' onClick={() => recaptchaRef.current.reset()} >
                                                        <i className="fa fa-refresh" aria-hidden="true"></i>
                                                    </Button>
                                                </div>
                                           ) 
                                        }


                                    </Fragment>
                                    : null
                            }
                            <Button 
                                className="button-container btn-primary" 
                                style={{ float: 'right' }}
                                type="submit">
                                SUSCRIBIRSE
                            </Button>
                        </div>
                    </Form>
                </Card.Footer>
            </Card>
        )   
    } else {
        return null
    }
}
