import { toast } from 'react-toastify';
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_UPDATE_QUANTITIES, CART_ADD_EXTRA_LINEAS, CART_CLEAR } from './cartActionTypes';
import {addToShopCart, removeItemShopCart, getShopCartItems, getProductById, login} from '../../api/api'
import {checkContactarVendedor} from '../../utils/Utils'


export function cartAddItemSuccess(product, options = [], quantity = 1, silent, custom_item) {
    if (!silent)
        toast.success(`El producto "${product.name}" se agregó al carrito de compra!`);

    return {
        type: CART_ADD_ITEM,
        product,
        options,
        quantity,
        custom_item_set: custom_item
    };
}

export function cartRemoveItemSuccess(itemId) {
    return {
        type: CART_REMOVE_ITEM,
        itemId,
    };
}

export function clearShopCart() {
    return {
        type: CART_CLEAR        
    };
}

export function cartUpdateQuantitiesSuccess(quantities) {
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
    };
}

export function cartAddItem(product, options = [], quantity = 1, loginDetails, silent=false) {
    // sending request to server, timeout is used as a stub
    return (dispatch, getState) => (
        new Promise(async (resolve) => {   
            const shop_configs = getState().shop.shop_configs
            const noUserOrder = shop_configs ? shop_configs.no_user_order : false            
            if (true || (loginDetails && loginDetails.key) || noUserOrder) {
                const resulGetProductById = await getProductById(product.id)
                let canAddToCart = true
                let productToStore = product
                let custom_item_set = product.custom_item_set
                if (resulGetProductById.status === 200) {
                    productToStore = resulGetProductById.data   
                    const productInCart = getState().cart.items.find(it => it.product.id === productToStore.id)                 
                    let currentQuantity = quantity + (productInCart ? productInCart.quantity : 0)
                    canAddToCart = !checkContactarVendedor(productToStore, currentQuantity, getState().master.master_configs, shop_configs)                    
                }
                if (!canAddToCart) {
                    toast.info('Se requiere que contacte a un vendedor, para hacer esta compra.')
                    if (window.Tawk_API){
                        window.Tawk_API.maximize();
                    }                    
                    resolve()
                } else {
                    //if (loginDetails && loginDetails.key) {
                        let productData = {
                            product_id: product.id,
                            quantity
                        }
                        if (product.custom_item_set) {
                            let custom_item_set = product.custom_item_set
                            custom_item_set.additional = JSON.stringify(custom_item_set.additional)
                            productData = {
                                ...productData,
                                custom_item_set
                            }
                        }
                        addToShopCart(productData, loginDetails).then(result => {
                            if (result.status == 200) {
                                productToStore.cart_id = result.data.id                                
                                productToStore.cart = result.data                                
                                dispatch(cartAddItemSuccess(productToStore, options, quantity, silent, custom_item_set));
                            } else {
                                toast.error(`Error al agregar al carrito de compra!`);
                            }
                            resolve()
                        }).catch(error => console.warn('error addToShopCart', error))
                    // } else {
                    //     //Compra sin sesión
                    //     dispatch(cartAddItemSuccess(productToStore, options, quantity, false, custom_item_set));
                    //     resolve()
                    // }
                }
            } else {
                toast.warn(`Inice sesión para agregar productos al carrito de compra!`);
                resolve()
            }
        })
    );
}

export function cartRemoveItem(item, loginDetails) {
    // sending request to server, timeout is used as a stub    
    if (true || loginDetails && loginDetails.key && item.product.cart_id) {        
        return (dispatch) => (
            new Promise((resolve) => {                   
                    removeItemShopCart(item.product.cart_id, loginDetails).then(result => {
                        if (result.status == 204) {
                            dispatch(cartRemoveItemSuccess(item.id));
                        } else {
                            if (loginDetails && loginDetails.id) {
                                toast.error(`No se pudo sincronizar su eliminación con el servidor.`);
                            }
                            dispatch(cartRemoveItemSuccess(item.id));
                        }
                        resolve()
                    }).catch(error => {
                        console.warn('error addToShopCart', error)
                        resolve()
                    })                
            })
        );
    } else {
        return (dispatch) => (
            new Promise((resolve) => {
                setTimeout(() => {
                    dispatch(cartRemoveItemSuccess(item.id));
                    resolve();
                }, 2000);
            })
        );
    }

}

export function cartUpdateQuantities(quantities) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitiesSuccess(quantities));
                resolve();
            }, 200);
        })
    );
}

export const cartAddExtraLine = (extraLine) => (dispatch) => {    
    dispatch({
        type: CART_ADD_EXTRA_LINEAS,
        extraLine,
    })
}

export function synsShopCart(loginDetails) {
    console.log('###### Sync shop cart')
    return (dispatch, getState) => (
        new Promise((resolve) => {   
                const cart = getState().cart
                let cartItem = cart.items.find(it => it.product.cart && it.product.cart.cart_id)
                dispatch(clearShopCart())            
                getShopCartItems(loginDetails, cartItem ? cartItem.product.cart.cart_id : null).then(result => {
                    if (result.status == 200) {
                        //dispatch(cartRemoveItemSuccess(item.id));
                        const remoteShopCart = result.data
                        for (let i = 0; i < remoteShopCart.length; i++) {
                            const element = remoteShopCart[i];
                            const product = element.product
                            product.cart_id = element.id
                            product.cart = {
                                id: element.id,
                                cart: element.cart
                            }
                            dispatch(cartAddItemSuccess(product, [], element.quantity, true, element.custom_item));
                        }
                    } else {
                        console.warn(`Error al sincronizar su carrito de compra!`);
                    }
                    resolve()
                }).catch(error => {
                    console.warn('error synsShopCart', error)
                    resolve()
                })                
        })
    );
}