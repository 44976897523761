import { create } from "apisauce";

export const baseURL = process.env.REACT_APP_BACKEND_URL;
// export const baseURL = 'https://www.herosuite.io'; 
export const MARKETPLACE_URL = `${window.location.protocol}//${window.location.hostname}`;

export const URL_TESTER = /(http|https):\/\/.*/;

// console.log('MARKETPLACE_URL', MARKETPLACE_URL)
// console.log('baseURL', baseURL)

export function createHeaders(loginDetails) {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Token " + loginDetails.key,
    "wf-authorization":
      "sessionid=" +
      loginDetails.sessionid +
      "; csrftoken=" +
      loginDetails.csrftoken,
    "X-CSRFToken": loginDetails.csrftoken,
  };
}

export const sanitizeImageURL = (imageUrl) => {
  if (imageUrl) {
    if (URL_TESTER.test(imageUrl)) return imageUrl;
    else return baseURL + imageUrl;
  }
  return null;
};
/* Este es el parametro que determina el cliente que se renderiza 951 abrele*/
let wf_site_id = 951;

function createBaseHeaders() {
  try {
    var request = new XMLHttpRequest();
    if (process.env.NODE_ENV === "development") {
      //request.open('GET', `${baseURL}/commons/api/master-vars/?site_url=${'stg.saleganado.com'}`, false);  // `false` makes the request synchronous
      // request.open('GET', `${baseURL}/commons/api/master-vars/?site_url=${'stg.brunadesignstore.com'}`, false);  // `false` makes the request synchronous
      //request.open('GET', `${baseURL}/commons/api/master-vars/?site_url=${'stg.ridgidsio.com'}`, false);  // `false` makes the request synchronous
      //request.open('GET', `${baseURL}/commons/api/master-vars/?site_url=${'anicetos.herosuite.io'}`, false);  // `false` makes the request synchronous
      //request.open('GET', `${baseURL}/commons/api/master-vars/?site_url=${'stg.invitashopping.com'}`, false);  // `false` makes the request synchronous
    } else {
      request.open(
        "GET",
        `${baseURL}/commons/api/master-vars/?site_url=${window.location.hostname}`,
        false
      ); // `false` makes the request synchronous
    }
    request.send(null);

    if (request.status === 200) {
      const result = JSON.parse(request.responseText);
      if (result[0]) {
        wf_site_id = result[0].id;
        console.log("WF-SITE-ID: ", wf_site_id);
      } else {
        console.warn(
          "No hay configuración para el site:",
          window.location.hostname
        );
      }
    } else {
      console.error(
        "ATENCION: No se pudo recuperar el site.",
        request.responseText
      );
    }
  } catch (error) {
    console.warn("No se pudo obtner el site_id:", error);
  }
  const headers = {
    "Cache-Control": "no-cache",
    ...(wf_site_id ? { "wf-site-id": wf_site_id } : {}),
  };
  return headers;
}

const api = create({
  baseURL,
  // here are some default headers
  headers: createBaseHeaders(),
  //withCredentials: true
});

if (process.env.NODE_ENV === "development") {
  const naviMonitor = (response) => {
    const url = response.config.url;
    // console.log(`listen! ${response.config.method.toUpperCase()} ${url}`, response)
  };
  api.addMonitor(naviMonitor);
}

export const login = (email, password) =>
  api.post(
    "web/api/login/",
    {
      email,
      password,
    },
    {
      withCredentials: true,
    }
  );

export const register = (data) =>
  api.post("web/api/registration/", data, {
    withCredentials: true,
  });

export const createMasterForClient = (data) =>
  api.post("web/api/master-client/", data, {
    withCredentials: true,
  });

export const updateMasterClient = (id, data) =>
  api.patch(`web/api/master-client/${id}/`, data, {
    withCredentials: true,
  });

export const updateUser = (loginDetails, id, name, last_name, tel_mobile) =>
  api.patch(
    `commons/api/clients/${id}/`,
    {
      name,
      last_name,
      tel_mobile,
    },
    {
      headers: createHeaders(loginDetails),
    },
    {
      withCredentials: true,
    }
  );

export const updateUserUsingFormData = (
  id,
  formData,
  loginDetails,
  moreConfigs
) =>
  api.patch(`commons/api/clients/${id}/`, formData, {
    headers: createHeaders(loginDetails),
    ...moreConfigs,
  });

export const addAdress = (loginDetails, addressData) =>
  api.post(
    "web/api/address/",
    addressData,
    {
      headers: createHeaders(loginDetails),
    },
    {
      withCredentials: true,
      crossDomain: true,
      dataType: "json",
    }
  );

export const getFacebookLogin = (token) =>
  api.post("web/api/facebook/", {
    access_token: token,
    code: "",
  });

export const logout = (loginDetails) =>
  api.post("web/api/logout/", null, {
    headers: createHeaders(loginDetails),
    withCredentials: true,
  });

/**
   * 
   * @param {*} data :
   *                name
                    email
                    subject
                    phone
                    message
                    user_id
                    appId
   * @param {*} loginDetails 
   */
export const sendContact = (data) =>
  api.post("web/api/contact/", data, {
    // headers: createHeaders(loginDetails)
  });

export const sendSellerContact = (data, loginDetails) =>
  api.post("shop/contact/sales/", data, {
    headers: createHeaders(loginDetails),
  });

export const getUserData = (loginDetails) =>
  api.get("web/api/clients/", null, {
    headers: createHeaders(loginDetails),
  });

export const getCollaborator = (loginDetails) =>
  api.get("commons/api/collaborators/profile/0/", null, {
    headers: createHeaders(loginDetails),
  });

export const getMasterVars = () => api.get("/commons/api/master-vars/");

export const getMasterConfigs = () => api.get("/commons/api/master/");

export const getMarketplaceConfig = () => api.get("/marketplaces/api/config/");

// export const getSlides = (siteId) => api.get(
//     `/commons/api/app-slides/?site_id=${wf_site_id}`
//   )

export const getSlides = () => api.get(`web/api/slides/`);

export const getSlidesBySite = (siteId) =>
  api.get(`web/api/slides/?site_id=${siteId}`);

export const getCategoriasMenu = () =>
  api.get(`shop/api/categories/?parents=true&is_featured=true`);

export const getAllCategories = (queryParams) =>
  api.get(`shop/api/categories/${queryParams ? `?${queryParams}` : ""}`);

export const getCategoryByIdOrSlug = (id) =>
  api.get(`shop/api/categories/${id}/`);

export const getSubCategorias = (categoryId) =>
  api.get(`shop/api/categories/?parent_id=${categoryId}`);

export const getFeatureProducts = () =>
  api.get(
    `/shop/api/products/all/0/?4me=1&opt=1&order_by=-product_activity_totals__wishlist`
  );

export const getBestsellerProducts = () =>
  api.get(
    `/shop/api/products/all/0/?4me=1&opt=1&order_by=-product_activity_totals__wishlist`
  );

export const getProductsByService = (apiURL) => api.get(apiURL);

export const getProductById = (id) => api.get(`shop/api/products/${id}/`);

export const getProducts = (urlPagination, queryParams) =>
  api.get(
    urlPagination
      ? urlPagination
      : `shop/api/products/${queryParams ? `?${queryParams}` : ""}`
  );

export const getRankingsProduct = (id, urlPagination) =>
  api.get(
    urlPagination ? urlPagination : `commons/api/ranking/shop/product/${id}/`
  );

export const getDownloadsProduct = (id) =>
  api.get(`commons/api/file-upload/?owner=product&owner_id=${id}`);

export const getPlansProduct = (id) =>
  api.get(`shop/api/product-plans/?product_id=${id}`);

export const getProductsByCategory = (categoryId, urlPagination) =>
  api.get(
    urlPagination
      ? urlPagination
      : `shop/api/products/category/${categoryId}/?products=all&opt=1`
  );

export const getCategoryFeaturesProducts = (categoryId, urlPagination) =>
  api.get(
    urlPagination
      ? urlPagination
      : `shop/api/products/category/${categoryId}/?products=all&opt=1&is_featured=1`
  );

export const searchProducts = (urlPagination, filterText) =>
  api.get(
    urlPagination
      ? urlPagination
      : `shop/api/product-search?t4srch=${filterText}`
  );

export const searchProductsPaginated = (urlPagination, filterText, pageSize) =>
  api.get(
    urlPagination
      ? urlPagination
      : `shop/api/product-search?pagination=1&t4srch=${filterText}${
          pageSize ? `&page_size=${pageSize}` : ""
        }`
  );

export const getApiGeneral = () => api.get(`web/api/general/`);

/**
 *
 * @param {*} itemData = {
 *     quantity: order,
 *     product_id: product.id
 * }
 * @param {*} loginDetails
 */
export const addToShopCart = (itemData, loginDetails) =>
  api.post(
    `shop/api/cart-items/`,
    itemData,
    loginDetails
      ? {
          headers: createHeaders(loginDetails),
        }
      : null
  );

export const removeItemShopCart = (id, loginDetails) =>
  api.delete(
    `shop/api/cart-items/${id}/?non-session=1`,
    null,
    loginDetails
      ? {
          headers: createHeaders(loginDetails),
        }
      : null
  );

export const getShopCartItems = (loginDetails, cartIdWithoutSesion) =>
  api.get(
    `shop/api/cart-items/?non-session=1${
      cartIdWithoutSesion
        ? `&cart_id=${encodeURIComponent(cartIdWithoutSesion)}`
        : ""
    }`,
    null,
    {
      headers: createHeaders(loginDetails),
    }
  );

/**
 * 
 * @param {*} itemData = = {
                "quantity": quantity, // obligatorio, de preferencia 1
                "product_id": product.id, // obligatorio, id del producto a agregar
                "wishlist_id": null // Puede ser null, id del wishlist donde se agrega el producto
            } 
 * @param {*} loginDetails 
 */
export const addToWhisList = (itemData, loginDetails) =>
  api.post(
    `shop/api/wishlist-item/`,
    itemData,
    loginDetails
      ? {
          headers: createHeaders(loginDetails),
        }
      : {}
  );

export const getWishLists = (loginDetails) =>
  api.get(`shop/api/wishlists/`, null, {
    headers: createHeaders(loginDetails),
  });

export const removeFromWishlist = (productId, wishlistId, loginDetails) =>
  api.delete(
    `shop/api/wishlist-item/${productId}/?wishlist_id=${wishlistId}`,
    null,
    {
      headers: createHeaders(loginDetails),
    }
  );

export const getUserAddresses = (loginDetails) =>
  api.get("web/api/address/", null, {
    headers: createHeaders(loginDetails),
  });

export const getDeliveryRulesFor = (total) =>
  api.get(
    `shop/api/client-delivery-cost/?order_total=${total}&delivery_type=delivery`
  );

export const createBuyOrder = (orderData, loginDetails) => {
  const loginkeys = loginDetails
    ? loginDetails
    : { key: null, sessionid: null, csrftoken: null };
  return api.post(`shop/api/orders/?v1=1&api=web`, orderData, {
    headers: createHeaders(loginkeys),
    timeout: 1200000,
  });
};



export const createOrderPlanHired = (orderData, loginDetails) => {
  const loginkeys = loginDetails
    ? loginDetails
    : { key: null, sessionid: null, csrftoken: null };
  return api.post(`shop/api/order-plans-hired/`, orderData, {
    headers: createHeaders(loginkeys),
    timeout: 1200000,
  });
};

export const sendRecoveryPassword = (email) =>
  api.post("web/api/recovery-request/", {
    email,
  });

export const getOrderHistory = (urlPagination, clientId, loginDetails) =>
  api.get(
    urlPagination ? urlPagination : `shop/api/orders/?v1=1&cover=1`,
    null,
    {
      headers: createHeaders(loginDetails),
    }
  );

export const getOrderById = (orderId) =>
  api.get(`shop/api/orders/?filter=all&filter_id=${orderId}&v1=1`);

// Descuentos **********************************
export const getDiscounts = () => api.get(`shop/api/discounts/`);

export const getDiscountById = (id) => api.get(`shop/api/discounts/${id}/`);

export const getDiscountsLimitedTime = () =>
  api.get(`shop/api/discounts/?type=limited-time`);

export const getDiscountSamePrice = () =>
  api.get(`shop/api/discounts/?type=same-price`);

export const getProductDiscount = (discountId, urlPagination) =>
  api.get(
    urlPagination
      ? urlPagination
      : `shop/api/discount-products/?discount_id=${discountId}`
  );

export const getProductDiscountByCategory = (
  discountId,
  categoryId,
  urlPagination
) =>
  api.get(
    urlPagination
      ? urlPagination
      : `shop/api/discount-products/?discount_id=${discountId}&category_id=${categoryId}`
  );

// Descuentos **********************************

// Bargains ************************************

export const getBargainProducts = (urlPagination) =>
  api.get(
    urlPagination ? urlPagination : `shop/api/products/?bargains=1&opt=1`
  );

export const createBargain = (productId, loginDetails) =>
  api.post(
    `shop/api/bargains/`,
    {
      product_id: productId,
    },
    {
      headers: createHeaders(loginDetails),
    }
  );

export const getBargainById = (bargainId, loginDetails) =>
  api.get(`shop/api/bargains/${bargainId}/`, null, {
    headers: createHeaders(loginDetails),
  });

export const getMyBargains = (urlPagination, loginDetails) =>
  api.get(
    urlPagination ? urlPagination : `shop/api/bargains/?my-bargains=True`,
    null,
    {
      headers: createHeaders(loginDetails),
    }
  );

export const addToBargainsSharedWithMe = (productId, bargainId, loginDetails) =>
  api.post(
    `shop/api/bargains-registry/`,
    {
      product: productId,
      bargain_id: bargainId,
    },
    {
      headers: createHeaders(loginDetails),
    }
  );

export const getBargainsSharedWithMe = (urlPagination, loginDetails) =>
  api.get(
    urlPagination
      ? urlPagination
      : `shop/api/bargains-registry/?filter=my-registry`,
    null,
    {
      headers: createHeaders(loginDetails),
    }
  );

const getBargainRegistryByBargainId = (
  urlPagination,
  bargainId,
  loginDetails
) =>
  api.get(
    urlPagination
      ? urlPagination
      : `shop/api/bargains-registry/?bargain_id=${bargainId}`,
    null,
    {
      headers: createHeaders(loginDetails),
    }
  );
// *********************************************

// Compras compartidas ************************************
export const getMySharedShoppings = (urlPagination, loginDetails) =>
  api.get(
    urlPagination ? urlPagination : `shop/api/orders/?filter=leader&v1=1`,
    null,
    {
      headers: createHeaders(loginDetails),
    }
  );

export const getJoinedShoppings = (urlPagination, loginDetails) =>
  api.get(
    urlPagination ? urlPagination : `shop/api/orders/?filter=follower&v1=1`,
    null,
    {
      headers: createHeaders(loginDetails),
    }
  );
// *********************************************

//************************* */

export const getActivities = () => api.get(`commons/api/activities/`);

export const getCountries = () => api.get(`commons/api/countries/`);

export const getStatesByCountry = (countryId) =>
  api.get(`commons/api/states/country/${countryId}/`);

export const getCitiesByState = (stateId) =>
  api.get(`commons/api/cities/state/${stateId}/`);

//******************************************** */
/**
 * "name": "Baltazar Porras",
    "company": "Balt Azar C.A",
    "contact_phone": "+583408907667",
    "email": "balt-azar@gmail.com",
    "description": "---",
    "url": "balt-azar.com", // Opcional
    "activity_id": 12, // Opcional
    "country_id": 137, // Opcional
    "state_id": 586, // Opcional
    "city_id": 514 // Opcional
 * @param {*} data 
 */
export const registerProvider = (data) =>
  api.post(`web/api/register-provider/`, data);

export const getSectionsToMenu = () => api.get(`web/api/sections/?v1=1`);

export const getSectionsDetails = (sectionSlug) =>
  api.get(`web/api/sections/${sectionSlug}/`);

export const getShopConfigs = (siteId) => api.get(`shop/api/app/${siteId}/`);

export const getOffices = () => api.get(`commons/api/offices/all/public/`);

export const getAboutUs = () => api.get(`web/api/about-us/`);

// Blogs

export const getBlogs = (nextPage, categoryId, t4srch, is_featured) =>
  api.get(
    nextPage
      ? nextPage
      : `web/api/blog/all/?pagination=1
    ${categoryId ? `&cat_id=${categoryId}` : ""}
    ${t4srch ? `&t4srch=${t4srch}` : ""}
    ${is_featured ? `&is_featured=${is_featured}` : ""}`
  );
export const getBlogByIdOrSlug = (id) => api.get(`web/api/blog/all/${id}/`);

export const getBlogCategories = (id) => api.get(`web/api/blog-categories/`);

export const getBlogCategoryByIdOrSlug = (id) =>
  api.get(`web/api/blog-categories/${id}`);

//Personalización de Páginas

export const getPagesDesign = (type, mobile) =>
  api.get(
    `designer/api/pages/?${type ? `otype=${type}&` : ""}${
      mobile ? "resolution=mobile" : ""
    }`
  );

export const getPageDesignById = (id, mobile) =>
  api.get(`designer/api/pages/${id}/?${mobile ? "resolution=mobile" : ""}`);

export const getSectionsDesign = (type, withSubsections) =>
  api.get(
    `designer/api/sections/?${type ? `otype=${type}` : ""}${
      withSubsections ? "&subsections=1" : ""
    }`
  );

export const getSectionDesignById = (id, withSubsections) =>
  api.get(
    `designer/api/sections/${id}/?${withSubsections ? "&subsections=1" : ""}`
  );

export const getSocialNetworks = () => api.get(`web/api/social/`);

export const getTerms = () => api.get(`web/api/terms/`);

export const getContactSection = () => api.get(`web/api/contact-section/`);

export const getPrivacies = () => api.get(`web/api/privacies/`);

export const createSubscription = (data) =>
  api.post(`web/api/subscriptions/`, data);

export const getListOfProductVariants = (queryParams) =>
  api.get(`commons/api/variants/${queryParams ? `?${queryParams}` : ""}`);

/* Publicar Anuncions*/

export const getProductTypes = () => api.get(`/shop/api/product-types/`);

export const getProductTransactionTypes = () =>
  api.get(`/shop/api/product-transaction-types/`);

export const getCategoryTags = () => api.get(`/commons/api/category-tags/`);

export const getTags = (categoryIds) =>
  api.get(
    `/commons/api/tags/${
      categoryIds ? `?cat_ids=${categoryIds.join("&cat_ids=")}` : ""
    }`
  );

export const getMyProducts = (nextUrl, masterId, loginDetails, queryParams) =>
  api.get(
    nextUrl
      ? nextUrl
      : `/shop/api/products/?master_id=${masterId}&${
          queryParams ? queryParams : ""
        }`,
    null,
    {
      headers: createHeaders(loginDetails),
    }
  );

export const getMasterList = (nextUrl, queryParamsAttr) =>
  api.get(
    nextUrl
      ? nextUrl
      : `/commons/api/master/?providers=1&opt=1&${queryParamsAttr}`
  );

export const getMasterByUser = (userId) =>
  api.get(`/commons/api/master/?user_id=${userId}&providers=1&opt=1`);

export const createProduct = (data, loginDetails) =>
  api.post(`/shop/api/products/`, data, {
    headers: createHeaders(loginDetails),
  });

export const updateProduct = (id, data, loginDetails) =>
  api.patch(`/shop/api/products/${id}/`, data, {
    headers: createHeaders(loginDetails),
  });

export const deleteImageGallery = (
  ownerId,
  ownerModel,
  site_id,
  imageId,
  loginDetails
) =>
  api.delete(
    `/commons/api/gallery-image/${imageId}/?owner_model_name=${ownerModel}&owner_object_id=${ownerId}&site_id=${site_id}`,
    null,
    {
      headers: createHeaders(loginDetails),
    }
  );

export const getShopCartById = (cartId, loginDetails) =>
  api.get(
    // `/shop/api/carts/`
    `/shop/api/carts/${encodeURIComponent(cartId)}/`,
    null,
    {
      headers: createHeaders(loginDetails),
    }
  );

export const getUserPasswordRecoveryInfo = (guid) =>
  api.get(`/web/api/password-recovery/?guid=${guid}&sid=${wf_site_id}`, null);

export const restetUserPassword = (data) =>
  api.post(`/web/api/password-recovery/`, {
    ...data,
    sid: wf_site_id,
  });

export const validateAccount = (data) =>
  api.post(`/web/api/validate/`, {
    ...data,
  });

export const getGenericList = (nextUrl, queryParamsAttr) =>
  api.get(queryParamsAttr ? `${nextUrl}&${queryParamsAttr}` : nextUrl);

export const searchItemsPaginated = (
  url,
  search_term,
  term,
  pageSize,
  contcat
) =>
  api.get(
    `${url}${contcat}${search_term}=${term}${
      pageSize ? `&page_size=${pageSize}` : ""
    }`
  );

export const getProviders = (queryparams, urlPagination) =>
  api.get(
    urlPagination
      ? urlPagination
      : `/commons/api/providers/?${queryparams ? queryparams : ""}`
  );

export const getProvider = (slug) => api.get(`/commons/api/providers/${slug}/`);

export const getPlans = () => api.get(`shop/api/plans/`);

export const getPlanHired = (loginDetails) =>
  api.get(`shop/api/plan-hired/`, null, {
    headers: createHeaders(loginDetails),
  });

export const getSocialNetworksBySite = (siteId) =>
  api.get(`web/api/social/?site_id=${siteId}`);

export const getBrands = () => api.get(`shop/api/brands/`);

export const getOfficesBySite = (siteId) => {
  return api.get(`commons/api/offices/0/0/?site_id=${siteId}`);
};

export const listProductActivitiesTotals = (productId) =>
  api.get(`shop/api/product-activity-totals/list/?product_id=${productId}`);

export const getProductFromWhislist = (productId, loginDetails) =>
  api.get(`shop/api/wishlist-item/${productId}/`, null, {
    headers: createHeaders(loginDetails),
  });



  //form de contacto
export const createSendForm = (dataform, api_url = null) => {
  if(api_url){
    return api.post(api_url, dataform, {
    });
  }
  else{
    return api.post(`/web/api/form/dynamic/`, dataform, {
    });
  }
};

//suscripciones
export const createSuscriptionUser = (datasucripcion) => {
  return api.post(`/web/api/subscriptions/`, datasucripcion, {
  });
};
