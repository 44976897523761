
export const BCIcons = {
    Usuario: '/assets/icons/bc/Icono-Usuario.png',
    Servicios: '/assets/icons/bc/Icono-Servicios.png',
    Servicios_Registro: '/assets/icons/bc/Icono-registro-servicios.png',
    Productos: '/assets/icons/bc/Icono-Productos.png',
    Productos_Registro: '/assets/icons/bc/Icono-registro-productos.png',
    Ofertas: '/assets/icons/bc/Icono-Ofertas.png',
    Notificaciones: '/assets/icons/bc/Icono-Notificaciones.png',
    NegocioOficial: '/assets/icons/bc/Icono-Negocio-Oficial.png',
    Menu: '/assets/icons/bc/Icono-Menu.png',
    Envios: '/assets/icons/bc/Icono-Envios.png',
    Favoritos: '/assets/icons/bc/Icono-Favoritos.png',
    Categorias: '/assets/icons/bc/Icono-Categorias.png',
    Ubicacion: '/assets/icons/bc/Icono-Ubicacion.png',
    PerfilNegocioFacebook: '/assets/icons/bc/perfil_negocio/facebook-negocio.png',
    PerfilNegocioInstagram: '/assets/icons/bc/perfil_negocio/instagram-negocio.png',
    PerfilNegocioTelefono: '/assets/icons/bc/perfil_negocio/telefono-negocio.png',
    PerfilNegocioWeb: '/assets/icons/bc/perfil_negocio/web-negocio.png',
    PerfilNegocioUbicacion: '/assets/icons/bc/perfil_negocio/ubicacion-negocio.png',
    LogoBolsaRoja: '/assets/icons/bc/logo-bolsa-roja.png',
    LogoBolsaBlanca: '/assets/icons/bc/logo-bolsa-blanca.png',
    PerfilProductoFacebook: '/assets/icons/bc/perfil-producto-facebook.png',
    PerfilProductoInstagram: '/assets/icons/bc/perfil-producto-instagram.png',
    PerfilProductoTelefono: '/assets/icons/bc/perfil-producto-telefono.png',
    PerfilProductoWeb: '/assets/icons/bc/perfil-producto-web.png',
    PerfilProductoWhatsapp: '/assets/icons/bc/perfil-producto-whatsapp.png',
    PerfilProductoNegocioOficial: '/assets/icons/bc/perfil-producto-negocio-oficial.png',
    EstrellaFavoritos: '/assets/icons/bc/Estrella-favoritos.png',
}

export const StoresImages = {
    Appstore: '/assets/img/icon-img/appstore.png',
    Playstore: '/assets/img/icon-img/playstore.png',
    share: '/assets/icons/share.png',
    mp: '/assets/img/mp.png',
    cards: '/assets/img/cards.png',
    next: '/assets/img/next.png',
} 