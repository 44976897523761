import { SwipeableDrawer } from '@material-ui/core';
import React, { Component } from 'react'
import { Modal, Button, Form, Row, Spinner, Col, Dropdown } from 'react-bootstrap';
import FacebookLogin from 'react-facebook-login'

class LoginModal extends Component {

    render() {
        const { show, 
            handleClose, 
            handleOpen,
            facebookAppId,
            login_email,
            login_password,
            processing,
            onSetState,
            onLoginPress,
            onRegisterPress,
            onForgottenPassword,
            responseFacebook
         } = this.props            
        return (
            <Dropdown.ItemText className="login-dropdown">                                  
                        <div className="mx-3 my-4 d-flex justify-content-center">
                            <strong>Ingresar</strong>
                        </div>      

                        <div className="mx-3 mb-5" >
                            <Form onSubmit={(e) => onLoginPress(e)}>
                                <Form.Group controlId="formBasicEmail">                                    
                                    <Form.Control
                                        type="email"
                                        placeholder="E-mail"
                                        value={login_email}
                                        onChange={(e) => onSetState({ login_email: e.target.value })}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">                                    
                                    <Form.Control
                                        type="password"
                                        placeholder="Contraseña"
                                        value={login_password}
                                        onChange={(e) => onSetState({ login_password: e.target.value })}
                                    />
                                    <span className={['flaticon-eye'].join(' ')} onClick={() => this.setState({})}></span>
                                    {
                                        processing ?
                                            <Button variant='link'>
                                                <Spinner animation="border" size="sm" role="status" />
                                            </Button>
                                            :
                                            null
                                    }
                                </Form.Group>

                                <div className="d-flex justify-content-end">
                                    <Button
                                        variant='link'
                                        style={{padding:0}}
                                        className="secondary_color_font" 
                                        onClick={(e) => onForgottenPassword(e)}>
                                        ¿Olvidaste tu contraseña?
                                    </Button> 
                                </div>

                                <div className="my-4 content-center-column">
                                    <Button 
                                        className="login-button"
                                        variant="primary" 
                                        type="submit">                                         
                                        Iniciar sesión
                                    </Button>    

                                    
                                </div>
                                
                                <div className="mt-5 mb-3">
                                    {
                                        facebookAppId && (
                                            <FacebookLogin
                                                appId={facebookAppId}
                                                autoLoad={false}
                                                fields="name,email,picture"
                                                scope="public_profile,user_friends"
                                                size="small"
                                                textButton="   Inicia sesion con Facebook"
                                                callback={(response) => responseFacebook(response)}
                                                cssclassaName="btn btn-login-facebook fill-available"
                                                icon="fa-facebook"
                                            />
                                        )
                                    }
                                </div>
                            </Form>
                            <div className="content-center-column">   
                                <sapn>¿Eres nuevo?</sapn>                             
                                <Button 
                                    variant="link" 
                                    className="secondary_color_font" 
                                    onClick={() => onRegisterPress()}>
                                    <strong>Registrate ahora</strong>
                                </Button>
                            </div>
                        </div>                                  
            </Dropdown.ItemText>
            
        );    
    }
}


export default LoginModal;