import React, { Component, Fragment } from "react";
import { getFeatureProducts, getProductsByService } from "../../api/api";
import { renderSection } from "../shared/DesignerUtils";
import SectionProducts from "./SectionProducts";

export default class SectionListProducts extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pages: []
        }
    }

    componentDidMount() {
        const {section} = this.props
        //console.log('SectionListProducts section', section)
        if (section.api_url) {
            let url = section.api_url
            if (section.additional_filters){
                url = url +`&${section.additional_filters}`
            }
            getProductsByService(url).then(result => {
                if(result.status === 200) {
                    let pages = []
                    pages[0] = {
                        ...result.data,
                        products: result.data.results.slice(0,12)
                    }
                    this.setState({
                        pages                    
                    })
                }
            })
        }
    }

    render(){
        const {section} = this.props
        const {pages} = this.state                                       
        return (
            <Fragment>
                {
                    (section.api_url) && (
                        <SectionProducts section={section} pages={pages}/>
                    )
                }
                {
                    section.sections && 
                        section.sections.map(section => renderSection(section))
                }
            </Fragment>
        )
    }
}