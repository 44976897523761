import { CARGAR_GENERAL, CARGAR_CATEGORIAS, CARGANDO, ERROR } from './shopActionTypes';

const INITIAL_STATE = {
	shop_configs: null,
	categorias: [],
	cargando: false,
	error: ''
};

export default (state = INITIAL_STATE, action) => {	
	switch (action.type) {
		case CARGAR_GENERAL:
			return { 
				...state,
				shop_configs: action.payload,				
				cargando: false,
				error: ''
			};
		case CARGAR_CATEGORIAS:
			return { 
				...state,
				categorias: action.payload,								
			};

		case CARGANDO:
			return { ...state, cargando: true };

		case ERROR:
			return { ...state, error: action.payload, cargando: false };

		default: return state;
	};
};