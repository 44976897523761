import React, { Component, Fragment } from "react";
import { Container, Row, Card, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import FeatureCard from './FeatureCard'
import FeatureIcon from './FeatureIcon'
import ListView from '../shared/ListView'
import { Helmet } from "react-helmet-async";
import { getGenericList } from "../../api/api";
import { isMobile } from "../../utils/Utils";

export default class SectionGeneralList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pages: []
        }
    }

    componentDidMount() {
        const {section} = this.props
        //console.log('SectionGeneralList section', section)
        if (section.api_url) {
            let url = section.api_url
            if (section.additional_filters){
                url = url +`&${section.additional_filters}`
            }
            getGenericList(url).then(result => {
                if(result.status === 200) {
                    let pages = []
                    pages[0] = {
                        ...result.data,
                        items: result.data.results
                    }
                    this.setState({
                        pages                    
                    })
                }
            })
        }
    }

    render(){
        const {
            section
        } = this.props
        let key=`${(new Date().getTime())}`
        let elementsStyles= ""
        let sectionClassName = `seccion-${key}`
        let pageOne = this.state.pages[0]
        console.log('PAGEONE', pageOne)
        if (section) {
            sectionClassName = `seccion-${section.id}`
        }        
        
        return (
            <Fragment>
                <Helmet>
                    {section && (
                        <style type="text/css">{`

                        .${sectionClassName} {
                            ${section.html_attrs}
                            ${isMobile() ? section.html_attrs_mobile: ''}
                        }

                        ${elementsStyles}

                    `}</style>
                    )}
                </Helmet>

                <section className={`designer-sections xtv ${sectionClassName} ${section.css_class}`} >
                    {
                        section && pageOne && (
                            <Fragment>
                                <ListView itemPage={pageOne} layout='grid-5' />
                            </Fragment>
                        )
                    }
                </section>
            </Fragment>
        )
    }
}