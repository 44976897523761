import React, { Component } from "react";
import { Container, Row, Card, Col } from "react-bootstrap";
import {getColumnClasses} from '../../utils/Utils'

export default class VideoElement extends Component {

    render(){
        const {element} = this.props
        let renderCard = null
        if (element && element.src && element.type) {
            renderCard = (
                <Col bsPrefix="column"  
                    className={`videoElement videoElement-${element.id} ${getColumnClasses(element)}`}>
                    <Card style={{ padding: 0, border: 'none' }}>
                        <video classaName="trash-ticker__bg-img " width="100%" height="100%" autoPlay={true} loop={true}>
                            <source src={element.src} type={element.type} />                            
                                Your browser does not support HTML5 video.
                        </video>
                    </Card>
                </Col>
            )
        }
        
        return (renderCard)
    }

}