import React, { Component, Fragment } from "react";
import { Container, Row, Card, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { getFeatureProducts, getBlogs } from "../../api/api";
import ProductsView from "../../pages/catalog/ProductsView";
import { Title } from "../shared/Title";
import RoundedButton from "../buttons/RoundedButton";
import { isMobile } from "../../utils/Utils";

export default class SectionNews extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        getBlogs(null, null, null, 1).then(result => {
            if (result.status === 200) {
                this.setState({
                    articles: result.data.results
                })
            }
        })
    }

    render(){
        const {section} = this.props
        const {articles} = this.state                
        let key=`${(new Date().getTime())}`        
        let sectionClassName = `seccion-${key}`
        if (section) {
            sectionClassName = `seccion-${section.id}`            
        }                
        return (
            <Fragment>
                <Helmet>
                    {section && (
                        <style type="text/css">{`

                        .${sectionClassName} {
                            ${section.html_attrs}
                            ${isMobile() ? section.html_attrs_mobile: ''}
                        }

                    `}</style>
                    )}
                </Helmet>

                <section className={`${sectionClassName} ${section.css_class}`} >
                    {
                        section.title && (
                            <Title title={section.title} />
                        )
                    }
                    {
                        section && (section.background_image || section.description) && (
                            <Fragment>
                                {
                                    section.background_image && (
                                        <Image src={section.background_image} 
                                            style={{width:'100%', height:'auto'}}  alt={section.background_image_alt}  />
                                    )
                                } 
                                {
                                    section.description && (
                                        <div dangerouslySetInnerHTML={{__html:section.description}}>
                                        </div>
                                    )
                                }
                            </Fragment>
                        )
                    }
                    
                    <Row className="justify-content-md-center">
                        {
                            articles && articles.map(it => (
                                <Col key={it.id} md={3} 
                                    className="article"
                                    style={{ marginBottom: 20 }}>
                                    <Card className='h-100'>
                                        {
                                            it.image && (
                                                <Link to={`/blog/post/${it.slug}`}>
                                                    <Card.Img variant="top" className="img-article-list" src={it.image} />
                                                </Link>
                                            )
                                        }
                                        <Card.Body>
                                            <Link to={`/blog/post/${it.slug}`}>
                                                <Card.Title>{it.name}</Card.Title>
                                            </Link>
                                            <Card.Text>{it.headline}</Card.Text>

                                            {/* <Card.Subtitle>{it.headline}</Card.Subtitle>
                                                <div style={{marginTop:20}} dangerouslySetInnerHTML={{__html:it.description}}></div>                                                                                         */}
                                        </Card.Body>
                                        <Card.Footer className='text-center' style={{ fontSize: 'x-small' }}>
                                            <Row>                                                
                                                <div style={{display: 'flex', flexGrow: 1, alignItems:'center'}}>
                                                    Publicado
                                                </div>    

                                                <Link className="modern-link" to={`/blog/post/${it.slug}`}>
                                                    <RoundedButton style={{width:40, height:40, fontSize:50}}>
                                                        <i classaName="pe-7s-angle-right" aria-hidden="true"></i>
                                                    </RoundedButton>
                                                </Link>                                  
                                            </Row>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                    
                </section>
            </Fragment>
        )
    }
}