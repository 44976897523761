import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Row, Col } from 'react-bootstrap'
import { sanitizeImageURL } from '../../../api/api'
import Rating from '../../../components/shared/Rating'
import Currency from '../../../components/shared/Currency'
import { Link } from 'react-router-dom'
import { isMobile } from '../../../utils/Utils'

class ProductWithOldPrice extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isMobile: isMobile()
        }
    }

    render() {
        const { product, layout } = this.props
        const { isMobile } = this.state

        let pathname = `/shop/product/${product.slug ? product.slug : product.id}`
        let priceComponent = null
        let currentPrice = product.price
        let oldPrice = null
        if (product.price_min > 0) {
            currentPrice = product.price_min
            if (product.price_market && product.price_market > currentPrice) {
                oldPrice = product.price_market
            }
        }

        if (product.price_discount > -1) {
            currentPrice = product.price_discount
            if (product.price > currentPrice) {
                oldPrice = product.price
            }
        }

        if (currentPrice && oldPrice) {
            priceComponent = (
                <div className="product-card__prices">
                    <span className="product-card__new-price"><Currency value={currentPrice} /></span>
                    {' '}
                    <span className="product-card__old-price"><Currency value={oldPrice} /></span>
                </div>
            );
        } else {
            if (currentPrice > 0) {
                priceComponent = (
                    <div className="product-card__prices">
                        <span className="product-card__new-price"><Currency value={currentPrice} /></span>
                    </div>
                );
            } else {
                priceComponent = (
                    <div className="product-card__prices">
                        Consultar
                    </div>
                );
            }
        }

        return (
            <Card
                bsPrefix="product-card"
                className="text-center"
                style={{ marginBottom: '20px' }}>
                <Card.Body className="padding-off card-portrait">
                    <Link to={{
                        pathname,
                        state: { product: product }
                    }}>
                        <Card.Img variant="top"
                            src={sanitizeImageURL(product.thumbnail_get)}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectPosition: 'center',
                                objectFit: 'contain'
                            }} />
                    </Link>
                </Card.Body>
                <Card.Body className="padding-1 card-description">
                    <Link to={{
                        pathname,
                        state: { product: product }
                    }}>
                        <Card.Text style={{ color: 'black', fontSize: 12, fontWeight: 900 }}>{product.name.substr(0, 44)}</Card.Text>
                    </Link>
                    {/* <Rating value={product.ranking} /> */}
                    {priceComponent}
                </Card.Body>
            </Card>
        )



    }
}


export default connect()(ProductWithOldPrice)