import React, { Component, Fragment } from 'react'
import { Container, Carousel, Row } from 'react-bootstrap'
import { getSlides } from '../../api/api';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { isMobile } from '../../utils/Utils';
import { renderSection } from '../shared/DesignerUtils';

class SlideSection extends Component {

    constructor(props){
        super(props);
        this.state={           
        }
    }

    render() {        
        const {section} = this.props
        let carrouselItems 

        let key=`${(new Date().getTime())}`
        if (section) {            
            key=section.id
        }        
        let sectionClassName = `seccion-${key}`

        let slides = section.sections
        if (slides && slides.length > 0) {
            carrouselItems = slides.map(slide => {
                if (slide.link != '') {
                    return (
                        <Carousel.Item key={slide.id}>
                            <a href={slide.link} target="_blank">
                                {
                                    renderSection(slide)
                                }   
                            </a>                  
                        </Carousel.Item>
                    )
                }
                else return (
                    <Carousel.Item key={slide.id}>
                        {
                            renderSection(slide)
                        }                  
                    </Carousel.Item>
                )
            })
            return (
                <Fragment>
                    <Helmet>
                        {section && (
                            <style type="text/css">{`

                            .${sectionClassName} {
                                ${section.html_attrs}
                                ${isMobile() ? section.html_attrs_mobile: ''}
                            }

                        `}</style>
                        )}
                    </Helmet>
                    <Container fluid className={sectionClassName}>
                        <Row style={{display:'block'}}>
                            <Carousel>
                                {carrouselItems}
                            </Carousel>
                        </Row>
                    </Container>
                </Fragment>
            )
        } else {
            return null
        }
    }
    
}

const mapStateToProps = (state) => ({
    locale: state.locale,
    master: state.master
});

export default connect(mapStateToProps)(SlideSection)