import { GET_USER, CARGANDO, ERROR, DO_LOGIN, DO_LOGOUT, RESET_USER, UPDATE_USER, GET_MASTER_USER } from "./userActionTypes";
import {CART_CLEAR} from '../cart/cartActionTypes'
import {WISHLIST_CLEAR} from '../wishlist/wishlistActionTypes'
import { getMasterByUser, getUserData, register } from "../../api/api";
import { toast } from 'react-toastify';
import { login, logout, updateUser, getFacebookLogin } from "../../api/api";

import {synsShopCart} from '../cart/cartActions'
import {synsWishlist} from '../wishlist/wishlistActions'

export const getUser = (loginDetails) => async (dispatch) => {
    dispatch({
        type: CARGANDO,
    });

    getUserData(loginDetails).then((result) => {
        if (result.status == 200) {
            const userData = result.data[0]
            if (userData) {
                dispatch({
                    type: GET_USER,
                    payload: userData
                });     
                
                dispatch(doGetUserMaster(userData))
            }
        } else {
            //Show message
            console.log(result.problem);
            toast.error('No se pudo recuperar la información del usuario.')
            dispatch({
                type: ERROR,
                payload: "Algo salió mal, intente más tarde.",
            });
        }
    });

    
};

export const doLogin = (email, password) => async (dispatch) => {
    dispatch({
        type: CARGANDO,
    });

    login(email, password).then((result) => {
        if (result.status == 200) {
            const sessionData = getSessionData(result.data)
            dispatch({
                type: DO_LOGIN,
                payload: sessionData,
            });

            dispatch(getUser(sessionData));
            dispatch(synsShopCart(sessionData));
            dispatch(synsWishlist(sessionData));
        } else {
            let message = `Login error`
            if (result.data.non_field_errors) {
                message = result.data.non_field_errors[0]
            }
            toast.error(message);
            dispatch({
                type: ERROR,
                payload: message,
            });
        }
    });
};

export const doLoginFB = (token) => async (dispatch) => {
    dispatch({
        type: CARGANDO,
    });

    getFacebookLogin(token).then((result) => {
        if (result.status == 200) {
            const sessionData = getSessionData(result.data)
            dispatch({
                type: DO_LOGIN,
                payload: sessionData,
            });

            dispatch(getUser(sessionData));
            dispatch(synsShopCart(sessionData));
        } else {
            let message = `Login FB error`
            if (result.data.non_field_errors) {
                message = result.data.non_field_errors[0]
            }
            toast.error(message);
            dispatch({
                type: ERROR,
                payload: message,
            });
        }
    });
};

export const doLogout = (loginDetails) => async (dispatch) => {
    console.log('doLogout Actiosn')

    logout(loginDetails).then((result) => {
        console.log('lOGOUT Result', result)
        if (result.status == 200) {
            dispatch({
                type: DO_LOGOUT,
                payload: {},
            });
            dispatch({
                type: RESET_USER,
            });

            dispatch ({type: CART_CLEAR})
            dispatch ({type: WISHLIST_CLEAR})
        } else {
            console.log(result.problem);
            dispatch({
                type: ERROR,
                payload: "Algo salió mal, intente más tarde.",
            });
        }
    });
};

export const doRegister = (email, first_name, last_name, tel_mobile, password1, password2) => async (dispatch, getState) => {
    let validation_required = false;
    const master = getState().master
    if (master && master.master && master.master.crm_config) {
        validation_required = master.master.crm_config.validation_required
    }

    dispatch({
        type: CARGANDO,
    });

    register({ email, first_name, last_name, tel_mobile, password1, password2 }).then((result) => {
        if (result.status == 201) {
            if (validation_required) {                
                dispatch({
                    type: DO_LOGIN                    
                });
                toast.success('Registro creado.')
                toast.success('Valide su cuenta por email o código.', {                    
                    autoClose: 20000,
                })
            } else {
                const sessionData = getSessionData(result.data)
                dispatch({
                    type: DO_LOGIN,
                    payload: sessionData,
                });
                dispatch(getUser(sessionData));
                toast.success('Registro creado.')
            }
        } else {
            let message = `Register error`
            if (result.data && result.data.non_field_errors) {
                message = result.data.non_field_errors[0]
            } else if (result.data) {
                message = JSON.stringify(result.data)
            }
            toast.error(message);
            dispatch({
                type: ERROR,
                payload: message,
            });
        }
    });
};

export const doRegisterWithMaster = (data) => async (dispatch) => {
    dispatch({
        type: CARGANDO,
    });
    register(data).then((result) => {
        if (result.status == 201) {
                const sessionData = getSessionData(result.data)
                dispatch({
                    type: DO_LOGIN,
                    payload: sessionData,
                });
                dispatch(getUser(sessionData));
                toast.success('Registro creado.')
        } else {
            let message = `Register error`
            if (result.data && result.data.non_field_errors) {
                message = result.data.non_field_errors[0]
            } else if (result.data ) {
                message = JSON.stringify(result.data)
            }
            toast.error(message);
            dispatch({
                type: ERROR,
                payload: message,
            });
        }
    });
};

export const doUpdateUser = (loginDetails, id, name, lastname, phone) => async (dispatch) => {
    dispatch({
        type: CARGANDO,
    });

    updateUser(loginDetails, id, name, lastname, phone).then((result) => {
        if (result.status == 200) {
            dispatch(getUser(loginDetails));
            toast.success('Usuario actualizado')
        } else {
            let message = `Update error`
            if (result.data.non_field_errors) {
                message = result.data.non_field_errors[0]
            }
            toast.error(message);
            dispatch({
                type: ERROR,
                payload: message,
            });
        }
    });
};


export const doGetUserMaster = (userData) => async (dispatch) => {
    dispatch({
        type: CARGANDO,
    });

    getMasterByUser(userData.user.id).then(result => {
        if (result.status === 200) {
            let master_user = result.data.results[0]
            dispatch({
                type: GET_MASTER_USER,
                payload: {
                    master_user
                },
            });
        }
    })

};



function getSessionData(data) {
    let sessionid = ''
    let csrftoken = ''
    let key = data.key
    if (data && data.data) {
        const sessionParams = data.data.split('/')
        sessionid = sessionParams[0]
        csrftoken = sessionParams[2]
    }
    return { key, sessionid, csrftoken };
}