import React, { Component, Fragment } from "react";
import { Container, Row, Card, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { getFeatureProducts } from "../../api/api";
import ProductsView from "../../pages/catalog/ProductsView";
import { Title } from "../shared/Title";
import { TitleMobile } from "../shared/TitleMobile";
import { isMobile, getElementStyle } from "../../utils/Utils";
import { renderSection } from "../shared/DesignerUtils";

export default class SectionProducts extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isMobile: isMobile()
        }
    }

    render(){
        const {section, pages} = this.props        
        const {isMobile} = this.state
        let pageOne = pages[0]               
        let key=`${(new Date().getTime())}`        
        let sectionClassName = `seccion-${key}`
        if (section) {
            sectionClassName = `seccion-${section.id}`
        }                
        return (
            <Fragment>
                <Helmet>
                    {section && (
                        <style type="text/css">{`

                        .${sectionClassName} {
                            ${section.html_attrs}
                            ${isMobile ? section.html_attrs_mobile: ''}
                        }


                    `}</style>
                    )}
                </Helmet>

                <section className={`${sectionClassName} ${section.css_class}`} >
                    {
                        section.title ? 
                            isMobile ?
                                <TitleMobile title={section.title} />
                            :
                                <Title title={section.title} 
                                    rightComponent = {
                                        <Link to={section.link} style={{fontSize:'14px', fontWeight:'200'}} className="link-product-list">Ver más</Link>
                                    }
                                />
                        : null
                    }
                    {
                        section && (section.background_image || section.description) && (
                            <Fragment>
                                <Image src={section.background_image} 
                                    style={{width:'100%', height:'auto'}} alt={section.background_image_alt} />
                                <div dangerouslySetInnerHTML={{__html:section.description}}>
                                </div>
                            </Fragment>
                        )
                    }
                    
                    <ProductsView productPage={pageOne} layout='grid-2' />                    
                </section>
            </Fragment>
        )
    }
}