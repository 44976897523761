import { CARGAR_GENERAL, CARGANDO, ERROR } from "./generalActionTypes";
import { getApiGeneral } from "../../api/api";

export const setGeneral = (generalData) => async (dispatch) => {   
            dispatch({
                type: CARGAR_GENERAL,
                payload: generalData
            });

};


export const getGeneral = () => async (dispatch) => {
    dispatch({
        type: CARGANDO,
    });

    getApiGeneral().then((result) => {
        if (result.status == 200) {
            const generalData = result.data[0]
            dispatch({
                type: CARGAR_GENERAL,
                payload: generalData
            });
        } else {
            console.log(result.problem);
            dispatch({
                type: ERROR,
                payload: "No se pudieron cargar las configuraciones generales, intente más tarde.",
            });
        }
    });
};
