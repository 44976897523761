import React, { Component, Fragment } from "react";
import { Container, Row, Card, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import FeatureCard from './FeatureCard'
import FeatureIcon from './FeatureIcon'
import { Helmet } from "react-helmet-async";
import SuscriberCard from "./SuscriberCard";
import SectionMenuItem from "./SectionMenuItem";
import FooterSubscriber from "../footer/FooterSubscriber";
import VideoElement from "./VideoElement";
import { renderSection } from "../shared/DesignerUtils";
import { getElementStyle, isMobile } from "../../utils/Utils";
import CardThumbnail from "./CardThumbnail";
import ThumbnailHoverover  from './ThumbnailHoverover';
import TopMenuItemAccount from "../header/TopMenuItemAccount";
import TopMenuItemWishlist from "../header/TopMenuItemWishlist";
import TopMenuItemCart from "../header/TopMenuItemCart";
import FormElement from "./DynamicForm";
import ImageSimple from "./ImageSimple";
import CustomSlider from "./CustomSlider";

export default class Section extends Component {

    render(){
        const {
            section, 
            children,
            noElements,
            noSubSections
        } = this.props        

        let renderCards = []        
        let renderSections = []        
        let key=`${(new Date().getTime())}`
        let elementsStyles= ""
        let sectionClassName = `seccion-${key}`
        if (section && section.name === 'slider kw') {
            renderSections = [<CustomSlider section={section} />];
        } 
        else if (section) {
            sectionClassName = `seccion-${section.id}`
            if (section.elements && !noElements) {
                let activeElements = section.elements.filter(it => !it.deleted)
                renderCards = activeElements.map((element, index) => {
                    switch (element.otype.slug) {
                        case 'card':
                            elementsStyles = elementsStyles.concat(getElementStyle(element, 'featureCard', sectionClassName))
                            return (<FeatureCard key={element.id} card={element}/>)
                        case 'card-hover':
                            elementsStyles = elementsStyles.concat(getElementStyle(element, 'featureCard', sectionClassName))
                            return (<FeatureCard key={element.id} card={element}/>)
                        case "thumbnail":
                            elementsStyles = elementsStyles.concat(getElementStyle(element, 'featureIcon', sectionClassName))
                            return (<FeatureIcon key={element.id} feature={element}/>)                        
                        case "subscription":
                            elementsStyles = elementsStyles.concat(getElementStyle(element, 'subscriptionCard', sectionClassName))
                            if (element.isInFooter) {
                                return (<FooterSubscriber key={element.id} card={element}/>)                        
                            }
                            return (<SuscriberCard key={element.id} card={element}/>)                        
                        case "menu-item":
                            elementsStyles = elementsStyles.concat(getElementStyle(element, 'menuItem', sectionClassName))
                            return (<SectionMenuItem key={element.id} element={element}/>)                        
                        case "video":
                            elementsStyles = elementsStyles.concat(getElementStyle(element, 'videoElement', sectionClassName))
                            return (<VideoElement key={element.id} element={element}/>)                        
                        case 'button':
                            elementsStyles = elementsStyles.concat(getElementStyle(element, 'button-container', sectionClassName))
                                if(element && element.link.includes("http") || element.link.includes("mailto")){
                                    return (
                                        <a target="blank" href={element.link}
                                            className={`button-container button-container-${element.id} btn-primary`} 
                                        >
                                            {element.link_text}
                                        </a>      )}
                                else {
                                    return(
                                        <Button as={Link} to={element.link}
                                        className={`button-container button-container-${element.id}`} 
                                    >
                                        {element.link_text}
                                    </Button>
                                    )  }
                        case "card-thumbnail":
                            elementsStyles = elementsStyles.concat(getElementStyle(element, "card-thumbnail", sectionClassName))
                            return (element.image.includes('kenworth') ? <ThumbnailHoverover key={element.id} element={element} /> : <CardThumbnail key={element.id} element={element}/>)
                        case "image":
                            elementsStyles = elementsStyles.concat(getElementStyle(element, "image", sectionClassName))
                            return (<ImageSimple key={element.id} element={element}/>)
                        case "navbar-menu-account":
                            elementsStyles = elementsStyles.concat(getElementStyle(element, "navbar-menu-account", sectionClassName))
                            return (<TopMenuItemAccount key={element.id} element={element}/>)                        
                        case "wishlist":
                            elementsStyles = elementsStyles.concat(getElementStyle(element, "navbar-menu-wishlist", sectionClassName))
                            return (<TopMenuItemWishlist key={element.id} element={element}/>)                        
                        case "cart":
                            elementsStyles = elementsStyles.concat(getElementStyle(element, "navbar-menu-cart", sectionClassName))
                            return (<TopMenuItemCart key={element.id} element={element}/>)
                        case "form":
                            elementsStyles = elementsStyles.concat(getElementStyle(element, "navbar-menu-cart", sectionClassName))
                            return (<FormElement key={element.id} element={element}/>)
                        default:
                            return null;
                    }                
                })  
            }

            if (section?.sections && !noSubSections) {
                renderSections = section?.sections.map(section => renderSection(section))
            }
        }        
        
        return (
            <Fragment>
                <Helmet>
                    {section && (
                        <style type="text/css">{`

                        .${sectionClassName} {
                            ${section?.html_attrs}
                            ${isMobile() ? section?.html_attrs_mobile: ''}
                        }

                        ${elementsStyles}

                    `}</style>
                    )}
                </Helmet>

                <section className={`designer-sections ${sectionClassName} ${section?.css_class}`} >
                    {
                        section && section.title && (
                            <div className="section-title">
                                <span>{section.title}</span>
                            </div>
                        )
                    }
                    {
                        section && (
                            <Fragment>
                                {
                                    section.background_image && (
                                        <Image src={section.background_image} 
                                            style={{width:'100%', height:'auto'}} alt={section.background_image_alt} />
                                    )
                                }
                                <div dangerouslySetInnerHTML={{__html:section.description}}>
                                </div>
                            </Fragment>
                        )
                    }
                    <Row>
                        {
                            renderCards
                        }
                        {
                            renderSections
                        }
                    </Row>
                    {
                        children
                    }
                </section>
            </Fragment>
        )
    }
}